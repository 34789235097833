import React, {
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import {
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons'
import { useQuery } from '@tanstack/react-query'
import { cloneDeep } from 'lodash'
import { useHistory } from 'react-router-dom'

import { getLocation } from '../../api/api-lib'
import {
  adjudicateClaimManually,
  deleteClaim,
  postCancelClaim,
  submitClaim,
  submitClaimManually,
  updateClaim,
} from '../../api/insuranceClaims'
import { BareBillingCode } from '../../hooks/queries/useBillingCodes'
import { useTransactions } from '../../hooks/useBillingInfo'
import { useBillingCodeDxMapping } from '../../hooks/useBillingInfo/useBillingCodeDxMapping'
import { useDiagnosisSnapshot } from '../../hooks/useDiagnosisHistory'
import { useGetClaimsByPatientId } from '../../hooks/useInsuranceClaims'
import {
  normalizePatientDxs,
  usePatientDiagnoses,
} from '../../hooks/usePatientDiagnoses'
import {
  useClaimInfo,
  usePaymentsInfoForClaim,
  useTeammatePersonalSettings,
} from '../../hooks/usePatientProfile'
import {
  AppointmentInfo,
  BillingInfo,
  ClaimDetails,
  InsuranceHolder,
  PatientConditionRelation,
  PatientInfoType,
  PatientInsurance,
  PracticeDataType,
  ReferringProviderType,
  RenderingProviderType,
  SupervisingProviderType,
} from '../../hooks/usePatientProfile/shared-types'
import useQueryString from '../../hooks/useQueryString'
import {
  getBillingCodeDisplayName,
  markDxAsDeletedAndUpdatePointers,
} from '../../libs/billing'
import { useFeatureFlags } from '../../libs/featureFlags'
import { logErrorAndNotify, notification } from '../../libs/notificationLib'
import { formatDate } from '../../libs/utils'
import {
  BillingTemplate,
  CLAIM_STATUS,
  InsuranceClaim,
  LineItem,
  Location,
  Teammate,
} from '../../shared-types'
import { Button, InfoPage, Skeleton } from '../../stories/BaseComponents'
import { StandardSkeletonRows } from '../../stories/BaseComponents/Skeleton'
import Text from '../../stories/BaseComponents/Text'
import { BillableEntityPayments } from '../../stories/Invoice/BillableEntityPayments'
import { Diagnoses, Diagnosis } from '../../stories/Invoice/Diagnoses'
import { LineItemUpdate } from '../../stories/Invoice/InvoiceLineItems'
import { useNoteDetails } from '../../v2/notes/hooks/useNoteDetails'
import AppointmentInformation from './Claims/AppointmentInformation'
import BillingClaim from './Claims/BillingClaim'
import ClaimHeader from './Claims/ClaimHeader'
import { LineItemsContainer } from './Claims/ClaimLineItemsContainer'
import ClaimMemo from './Claims/ClaimMemo'
import ClaimSignature from './Claims/ClaimSignature'
import ClaimsNavigationGuardModal from './Claims/ClaimsNavigationGuardClause'
import CompanyToSubmit from './Claims/CompanyToSubmit'
import {
  constructPOSTBody,
  isActive,
  normalizeClaimDraftData,
  validateClaimSubmit,
} from './Claims/InsuranceClaim-helper'
import NationalDrugCode from './Claims/NationalDrugCode'
import PatientConditionRelationWithClaim from './Claims/PatientConditionRelation'
import PatientInformation from './Claims/PatientInformation'
import Payments from './Claims/Payment'
import PrimaryInsuranceClaim from './Claims/PrimaryInsuranceClaim'
import ProceduresContainer from './Claims/ProceduresContainer'
import ReferringProviderSection from './Claims/ReferringProviderComponent'
import RenderingProvider from './Claims/RenderingProvider'
import SecondaryInsuranceClaim from './Claims/SecondaryInsuranceClaim'
import SupervisingProvider from './Claims/SupervisingProvider'
import {
  appointmentInfoEmptyObject,
  billingInfoEmptyObject,
  claimDetailsEmptyObject,
  claimInsuranceEmptyObject,
  patientConditionCauses,
  patientInfoEmptyObject,
  referringProviderEmptyObject,
  renderingProviderEmptyObject,
  supervisingProviderEmptyObject,
} from './Claims/constants'
import { ClaimStatusDetails } from './ClaimsV2/sections'
import { NdcItem } from './ClaimsV2/types'
import { PatientHeader } from './PatientHeader'

import stylesScroll from '../_shared.module.scss'
import styles from './ClaimCreation.module.scss'

const ErrorView = (
  <InfoPage
    status="warning"
    title="Sorry, there was a problem loading this page"
    details="Oops, something went wrong. Please contact your Osmind representative if this issue persists."
    redirectButtonText="Return to Patient List"
    redirectLink="/"
  />
)

const GATEWAY_TIMEOUT_STATUS = 504
const GATEWAY_TIMEOUT_PROBLEM_DETAILS = {
  title: 'Claim submission update',
  detail:
    'Looks like we hit a snag and lost connection before confirming your submission. Please refresh the page to see the current status.',
}
const REFRESH_REQUIRED_ERROR_TYPE = 'tag:osmind.org,2024:error/refreshRequired'
export default function ClaimCreation({
  healthGorillaUserName,
  practiceData,
}: Readonly<{
  healthGorillaUserName: string
  practiceData: PracticeDataType
}>) {
  const { hasEnabledCustomNotes } = useFeatureFlags()
  const query = useQueryString()
  const history = useHistory()
  const patientId = query.get('patientId') ?? ''
  const providerId = query.get('providerId') ?? ''
  const [claimId, setClaimId] = useState<string>(query.get('claimId') ?? '')
  const containerRef: RefObject<HTMLDivElement> = useRef(null)
  const [claimStatus, setClaimStatus] = useState<CLAIM_STATUS>(
    CLAIM_STATUS.DRAFT
  )
  const [disableFields, setDisableFields] = useState<boolean>(false)

  // CARE-1155: the current behavior of disableFields turns off input fields as well as the submit and save as draft
  // buttons. However, it leaves the delete buttons enabled. It is unknown whether this behavior was intended,
  // and changing this behavior is not in scope for CARE-1155, so we are adding disableDeletion to cover the use case
  // of refreshRequired errors, which are expected to disable the delete buttons. All other callers of setDisableFields
  // will continue to leave the delete button enabled.
  const [disableDeletion, setDisableDeletion] = useState<boolean>(false)
  const [claimMemoInitialValue, setClaimMemoInitialValue] = useState<string>('')
  const [claimMemoValue, setClaimMemoValue] = useState<string>('')
  const [unsavedDraft, setUnsavedDraft] = useState<boolean>(false)
  const [editPayments, setEditPayments] = useState<boolean>(false)
  const [hasFetched, setHasFetched] = useState<boolean>(false)
  const [noteId, setNoteId] = useState<string>(query.get('noteId') ?? '')
  const [authNumber, setAuthNumber] = useState<string | undefined>()
  const [saveLoading, setSaveLoading] = useState<boolean>(false)
  const [saveSubmitLoading, setSaveSubmitLoading] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [claimSubmitError, setClaimSubmitError] = useState<{
    isError: boolean
    message: { message: string }[] | null
  }>({ isError: true, message: null })
  const [claimFormRefreshError, setClaimFormRefreshError] = useState<{
    title?: string
    detail?: string
  }>({})
  const [primaryInsurance, setPrimaryInsurance] = useState<
    null | PatientInsurance | undefined
  >(claimInsuranceEmptyObject)
  const [primaryHolder, setPrimaryHolder] = useState<
    null | InsuranceHolder | undefined
  >(null)
  const [secondaryInsurance, setSecondaryInsurance] = useState<
    null | PatientInsurance | undefined
  >(claimInsuranceEmptyObject)
  const [secondaryHolder, setSecondaryHolder] = useState<
    null | InsuranceHolder | undefined
  >(null)
  const [includeSecondary, setIncludeSecondary] = useState<boolean>(false)
  const [patientConditionRelation, setPatientConditionRelation] =
    useState<PatientConditionRelation>(patientConditionCauses)
  const [patientInfo, setPatientInfo] = useState<PatientInfoType>(
    patientInfoEmptyObject
  )
  const [referringProviderInfo, setReferringProviderInfo] =
    useState<ReferringProviderType>(referringProviderEmptyObject)
  const [includeReferringProvider, setIncludeReferringProvider] =
    useState<boolean>(false)
  const [renderingProviderInfo, setRenderingProviderInfo] =
    useState<RenderingProviderType>(renderingProviderEmptyObject)
  const [supervisingProvider, setSupervisingProvider] =
    useState<SupervisingProviderType>(supervisingProviderEmptyObject)
  const [billingInfo, setBillingInfo] = useState<BillingInfo>(
    billingInfoEmptyObject
  )
  const [appointmentInfo, setAppointmentInfo] = useState<AppointmentInfo>(
    appointmentInfoEmptyObject
  )
  const [includeSupervisingProvider, setIncludeSupervisingProvider] =
    useState<boolean>(false)
  const [companyToSubmitTo, setCompanyToSubmitTo] = useState<string | null>(
    null
  )
  const [signingProvider, setSigningProvider] = useState<string | null>(null)
  const [claimDetails, setClaimDetails] = useState<ClaimDetails>(
    claimDetailsEmptyObject
  )

  const [lineItems, setLineItems] = useState<LineItem[]>([])
  const [diagnoses, setDiagnoses] = useState<Diagnosis[]>([])
  const [activeDiagnoses, setActiveDiagnoses] = useState<Diagnosis[]>([])

  const [ndcItems, setNdcItems] = useState<NdcItem[]>([])

  const { data: patientDiagnoses, isLoading: arePatientDxsLoading } =
    usePatientDiagnoses(patientId)

  const { refetch: refetchClaimsTableInfo } = useGetClaimsByPatientId(
    patientId,
    true
  )

  const {
    data: diagnosisSnapshot,
    isLoading: areNoteDxsLoading,
    refetch: dxRefetch,
  } = useDiagnosisSnapshot(
    appointmentInfo?.isSigned ? patientId : '',
    new Date(formatDate({ value: appointmentInfo?.startDate ?? '' })),
    new Date(appointmentInfo?.signedDate ?? '')
  )

  const { data: teammates, isLoading: teamLoading } =
    useTeammatePersonalSettings({ providerId })

  const { isInitialLoading: notesLoading, data: notes } =
    useNoteDetails(patientId)
  const availableNotes = useMemo(() => {
    if (!notes?.data) {
      return []
    }

    // if notes v2 enabled, every note is allowed
    if (hasEnabledCustomNotes) {
      return notes.data
    }
    return notes.data.filter(
      (detail) =>
        detail.version === '1' || detail.noteId === appointmentInfo.noteIdV2
    )
  }, [notes, appointmentInfo, hasEnabledCustomNotes])

  const { data: locations, isLoading: loadingLocations } = useQuery<Location[]>(
    ['locations'],
    getLocation
  )

  let transactionsIsLoading = false
  let transactionsData: any = {}
  const transactionsResponse = useTransactions({
    patientId,
    insuranceClaimUuid: claimId,
  })
  transactionsData = transactionsResponse.data
  transactionsIsLoading = transactionsResponse.isLoading

  // TODO: this endpoint should not exist. Instead, we should select the fields from the claim and/or related objects that we need
  // Also, this endpoint's naming is confusing. This does not fetch all the payments data for a claim, it fetches the payment fields that are set via Change API response or manually
  const {
    data: paymentInfo,
    isFetching: fetchingPayments,
    refetch: refetchPayments,
  } = usePaymentsInfoForClaim({
    claimId,
    patientId,
    isEnabled:
      claimStatus &&
      claimStatus !== CLAIM_STATUS.DRAFT &&
      claimStatus !== CLAIM_STATUS.CHANGE_ERROR,
  })

  const navigate = (path: string | undefined, search = '') => {
    if (path) {
      history.push(`${path}${search}`)
    }
  }

  const {
    data,
    isFetching,
    isError,
    refetch: refetchClaim,
  } = useClaimInfo({
    patientId,
    noteId,
    claimId,
  })

  const {
    mapping: billingCodeToDxMapping,
    isInitialLoading: isBillingCodeDxMappingLoading,
  } = useBillingCodeDxMapping(patientId)

  useEffect(() => {
    const activeDiagnoses = diagnoses.reduce((acc: Diagnosis[], dx) => {
      if (isActive(dx)) {
        acc.push({
          ...dx,
          order: acc.length,
        })
      }
      return acc
    }, [])
    setActiveDiagnoses(activeDiagnoses)
  }, [diagnoses])
  const handleDxDelete = (dxCode: string) => {
    const { newDiagnoses, newLineItems } = markDxAsDeletedAndUpdatePointers(
      diagnoses,
      lineItems,
      dxCode
    )
    setDiagnoses(newDiagnoses)
    setLineItems(newLineItems)
  }
  function navigateToClaimsTable() {
    history.push(
      `/patient/billing?patientId=${patientId}&providerId=${providerId}&tab=claims`
    )
  }

  const findDefaultDxPointers = useCallback(
    (dxList: Diagnosis[], billingCodeId?: number): number[] => {
      if (isBillingCodeDxMappingLoading) return []
      const activeDxs = dxList.filter((dx) => !dx.isDeleted)

      if (!billingCodeId) {
        return activeDxs.length === 0 ? [] : [0]
      }

      const recentPointers = billingCodeToDxMapping
      const defaultDxPtrs = (recentPointers[billingCodeId]?.latestDxCodes || [])
        .map((dxCode) => {
          return activeDxs.find((dx) => dx.code === dxCode)?.order
        })
        .filter((x) => x !== undefined)

      if (defaultDxPtrs.length === 0 && activeDxs.length) {
        return [0]
      }

      return defaultDxPtrs as number[]
    },
    [billingCodeToDxMapping]
  )

  const resetDiagnoses = async () => {
    await dxRefetch()
    const dxs =
      appointmentInfo.isSigned &&
      (diagnosisSnapshot?.activeDiagnoses?.length ?? 0) > 0
        ? diagnosisSnapshot?.activeDiagnoses
        : patientDiagnoses
    const normalizedDxs = normalizePatientDxs(dxs ?? [], true) as Diagnosis[]

    const orderedList = normalizedDxs.sort((dxA, dxB) => dxA.order - dxB.order)

    setDiagnoses(orderedList)

    if (lineItems.length) {
      const newLineItems = Array.from(lineItems).map((lineItem) => ({
        ...lineItem,
        dxPointers: findDefaultDxPointers(orderedList, lineItem.billingCode.id),
      }))
      setLineItems(newLineItems)
    }
  }
  const requireModal = useMemo(() => {
    if (!data) return false
    const prev = normalizeClaimDraftData(data)

    const newData = [
      primaryInsurance,
      primaryHolder,
      includeSecondary,
      secondaryInsurance,
      secondaryHolder,
      patientInfo,
      { ...claimDetails, created: '' },
      renderingProviderInfo,
      appointmentInfo,
      referringProviderInfo,
      includeReferringProvider,
      includeSupervisingProvider,
      supervisingProvider,
      billingInfo,
      patientConditionRelation,
      signingProvider,
      lineItems,
      companyToSubmitTo,
      authNumber,
      claimStatus,
      claimMemoValue,
    ]

    const prevData = [
      prev.primaryInsurance,
      prev.primaryHolder,
      prev.shouldIncludeSecondaryInsurance,
      prev.secondaryInsurance,
      prev.secondaryHolder,
      prev.patientInfoInitial,
      { ...prev.claimHeaderDetails, created: '' },
      prev.renderingProviderData,
      prev.appointmentInfoData,
      prev.referringProviderData,
      prev.shouldIncludeReferring,
      prev.shouldIncludeSupervising,
      prev.supervisingProviderData,
      prev.billingData,
      prev.diagnosisRelationInfo,
      prev.signatureProvider,
      prev.billableEntityInfo,
      prev.selectedInsuranceName || primaryInsurance?.insuranceName,
      prev.autorizationNumber,
      prev.fetchedClaimStatus,
      claimMemoInitialValue,
    ]

    const isAllInfoEqual = JSON.stringify(prevData) === JSON.stringify(newData)

    return !isAllInfoEqual
  }, [
    data,
    primaryInsurance,
    primaryHolder,
    includeSecondary,
    secondaryInsurance,
    secondaryHolder,
    patientInfo,
    claimDetails,
    renderingProviderInfo,
    appointmentInfo,
    referringProviderInfo,
    includeReferringProvider,
    includeSupervisingProvider,
    supervisingProvider,
    billingInfo,
    patientConditionRelation,
    signingProvider,
    lineItems,
    companyToSubmitTo,
    authNumber,
    claimStatus,
    claimMemoValue,
    claimMemoInitialValue,
  ])

  async function handleClaimDraftData() {
    if (!data) return setLoading(false)

    // we want to make sure we save the claimId in case we are creating a new claim
    if (data?.claimId) {
      setClaimId(data.claimId)
    }

    const {
      primaryInsurance,
      primaryHolder,
      shouldIncludeSecondaryInsurance,
      secondaryInsurance,
      secondaryHolder,
      patientInfoInitial,
      claimHeaderDetails,
      renderingProviderData,
      appointmentInfoData,
      referringProviderData,
      shouldIncludeReferring,
      supervisingProviderData,
      shouldIncludeSupervising,
      billingData,
      diagnosisRelationInfo,
      signatureProvider,
      billableEntityInfo,
      diagnosisInfo,
      selectedInsuranceName,
      autorizationNumber,
      fetchedClaimStatus,
      unsavedDraft,
      claimMemo,
      ndcs,
    } = normalizeClaimDraftData(data)

    const shouldDisableField = !(
      fetchedClaimStatus === CLAIM_STATUS.DRAFT ||
      fetchedClaimStatus === CLAIM_STATUS.CHANGE_ERROR
    )
    setClaimMemoInitialValue(claimMemo)
    setClaimMemoValue(claimMemo)
    setUnsavedDraft(unsavedDraft)
    setClaimStatus(fetchedClaimStatus || CLAIM_STATUS.DRAFT)
    setDisableFields(shouldDisableField)
    setPrimaryInsurance(primaryInsurance)
    setPrimaryHolder(primaryHolder)
    setIncludeSecondary(shouldIncludeSecondaryInsurance)
    setSecondaryInsurance(secondaryInsurance)
    setSecondaryHolder(secondaryHolder)
    setPatientInfo(patientInfoInitial)
    setClaimDetails(claimHeaderDetails)
    setRenderingProviderInfo(renderingProviderData)
    setAppointmentInfo(appointmentInfoData)
    setReferringProviderInfo(referringProviderData)
    setIncludeReferringProvider(shouldIncludeReferring)
    setIncludeSupervisingProvider(shouldIncludeSupervising)
    setSupervisingProvider(supervisingProviderData)
    setBillingInfo(billingData)
    setPatientConditionRelation(diagnosisRelationInfo)
    setSigningProvider(signatureProvider)
    setLineItems(billableEntityInfo)
    setNdcItems(ndcs)
    setCompanyToSubmitTo(
      selectedInsuranceName || primaryInsurance?.insuranceName
    )
    if (claimId) setDiagnoses(diagnosisInfo)
    setAuthNumber(autorizationNumber)
    setClaimStatus(fetchedClaimStatus)
    setLoading(false)
  }

  useEffect(() => {
    // Check if diagnosisSnapshot is available and not in a loading state for new claim note diagnoses.
    if (
      !claimId &&
      diagnosisSnapshot &&
      diagnosisSnapshot.activeDiagnoses.length > 0 &&
      !areNoteDxsLoading
    ) {
      setDiagnoses(normalizePatientDxs(diagnosisSnapshot.activeDiagnoses, true))
      return
    }

    // If patientDiagnoses is available and not in a loading state.
    if (!claimId && !arePatientDxsLoading && patientDiagnoses) {
      setDiagnoses(normalizePatientDxs(patientDiagnoses, true))
    }
  }, [
    patientDiagnoses,
    diagnosisSnapshot,
    areNoteDxsLoading,
    arePatientDxsLoading,
  ])

  useEffect(() => {
    if (data && !isFetching && !hasFetched) {
      handleClaimDraftData()
      setHasFetched(true)
    } else if (isError) {
      setLoading(false)
    }
  }, [data, isFetching])

  if (!patientId || !providerId) {
    return ErrorView
  }

  const handleLineItemUpdate = (data: LineItemUpdate) => {
    const newItems = Array.from(lineItems)
    const index = newItems.findIndex(
      (item) => item.billingCode.id === data.lineItem.billingCode.id
    )

    if (index >= 0) {
      newItems[index] = {
        ...newItems[index],
        ...data.newData,
      }
    }

    setLineItems(newItems)
  }

  const handleLineItemDelete = (lineItem: LineItem) => {
    const newLineItems = [] as LineItem[]
    lineItems.forEach((item: LineItem) => {
      if (
        getBillingCodeDisplayName(item.billingCode) !==
        getBillingCodeDisplayName(lineItem.billingCode)
      ) {
        newLineItems.push(item)
      }
    })
    setLineItems(newLineItems)
  }

  const handleBillingTemplateSelect = (billingTemplate?: BillingTemplate) => {
    const newLineItems = [] as LineItem[]
    billingTemplate?.billingTemplateCodes?.forEach((templateCode: any) => {
      const lineItem: LineItem = {
        units: templateCode.unit,
        unitChargeAmountCents: templateCode.unitChargeAmountCents,
        totalAdjustmentAmountCents: templateCode.discountAmountCents,
        billingCode: {
          code: templateCode.billingCode.code,
          version: templateCode.billingCode.version,
          ownerId: templateCode.billingCode.ownerId,
          description: templateCode.billingCode.shortDescription,
          id: templateCode.billingCode.id,
        },
        billingTemplateId: billingTemplate.id,
        modifiers: templateCode.modifiers,
        billingTemplateCodeId: templateCode.id,
        dxPointers: findDefaultDxPointers(
          diagnoses,
          templateCode.billingCode.id
        ),
      }
      newLineItems.push(lineItem)
    })

    lineItems.forEach((lineItem: LineItem) => {
      if (!lineItem.billingTemplateId) {
        newLineItems.push(lineItem)
      }
    })

    setLineItems(newLineItems)
  }

  // TODO: this was copied and pasted from the invoice form code. this should be refactored into a helper function
  const addNewLineItem = ({
    code,
    version,
    ownerId,
    shortDescription,
    id,
  }: BareBillingCode) => {
    const isAlreadyOnBill = lineItems?.find(
      (item) => item?.billingCode?.code === code
    )

    if (isAlreadyOnBill) {
      notification(
        'That code is already on the claim. Try increasing the unit count instead.'
      )
    } else {
      const newLineItems = Array.from(lineItems)
      newLineItems.push({
        billingCode: {
          code: code,
          version: version,
          ownerId: ownerId,
          description: shortDescription ?? '',
          id: id,
        },
        units: 1,
        unitChargeAmountCents: 0,
        totalAdjustmentAmountCents: 0,
        dxPointers: findDefaultDxPointers(diagnoses, id),
      })

      setLineItems(newLineItems)
    }
  }

  function constructData() {
    return {
      primaryInsurance,
      primaryHolder,
      secondaryInsurance,
      secondaryHolder,
      includeSecondary,
      patientConditionRelation,
      patientInfo,
      referringProviderInfo,
      includeReferringProvider,
      renderingProviderInfo,
      appointmentInfo,
      includeSupervisingProvider,
      supervisingProvider,
      billingInfo,
      diagnoses: diagnoses.filter((d) => isActive(d)),
      lineItems,
      companyToSubmitTo,
      signingProvider,
      authNumber,
      patientId,
      claimMemoContent: claimMemoValue,
      claimId,
      ndcs: ndcItems,
    }
  }

  async function handleNoteChange(
    noteId: string,
    renderingProviderId: string,
    newAppointmentInfo: AppointmentInfo
  ) {
    if (noteId) setNoteId(noteId)
    if (teammates) {
      const renderingProv: Teammate = teammates.find(
        (t: Teammate) => t.cognitoId === renderingProviderId
      )
      const renderingInfoCopy = cloneDeep(renderingProviderInfo)
      renderingInfoCopy.providerId = renderingProv?.cognitoId || null
      renderingInfoCopy.providerNpi = renderingProv?.billingNPI || null
      setRenderingProviderInfo(renderingInfoCopy)
      setAppointmentInfo(newAppointmentInfo)
      await dxRefetch()
    }
  }

  async function handleDiscard(navActive = true) {
    if (unsavedDraft && (data.claimId || claimId)) {
      await deleteClaim({ patientId, claimId: data.claimId || claimId })
      refetchClaimsTableInfo()
    }
    if (navActive) navigateToClaimsTable()
  }

  async function handleDelete() {
    if (data.claimId || claimId) {
      await deleteClaim({ patientId, claimId: data.claimId || claimId })
      refetchClaimsTableInfo()
    }
    navigateToClaimsTable()
  }

  function extractChangeErrors(error: any) {
    const errorObject: { message: string }[] = []
    let parsedJson
    try {
      parsedJson = JSON.parse(error)
    } catch {
      return null
    }
    parsedJson?.errors.map((err: any) => {
      // Remove everything after LOOP including LOOP
      const message = err?.description?.split('LOOP')[0].trim()
      return errorObject.push({ message: message })
    })
    return errorObject
  }

  const scrollToTop = () => {
    try {
      containerRef?.current?.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    } catch {
      return
    }
  }

  type RefreshErrorDetails = {
    title: string
    detail: string
  }

  function displayRefreshError(problemDetails: RefreshErrorDetails) {
    setClaimFormRefreshError(problemDetails)
    setDisableFields(true)
    setDisableDeletion(true)

    // Due to a known bug in React: https://github.com/facebook/react/issues/20770
    // disabling the same button that triggers a scroll action with "smooth" animation behavior
    // causes the scroll action to halt as the browser attempts to focus on the disabled element
    // to work around this, we delay the scroll action until the next event loop
    requestAnimationFrame(() => {
      scrollToTop()
    })
  }

  function handleSaveAsDraftError(e: any) {
    logErrorAndNotify('There was an error saving this draft', e)
    const error = e.response?.data?.error
    if (error?.info?.type === REFRESH_REQUIRED_ERROR_TYPE) {
      displayRefreshError(error.info)
      return
    }
    scrollToTop()
  }

  async function handleSaveAsDraft() {
    setSaveLoading(true)
    const body = constructPOSTBody(constructData())
    try {
      if (!body.claimId && !data.claimId) {
        throw new Error('Claim ID is missing.')
      } else {
        const claimId =
          body.claimId && body.claimId !== '' ? body.claimId : data.claimId
        await updateClaim(patientId, claimId, body)
      }
      notification('The draft has been saved correctly.', 'success')
      navigateToClaimsTable()
    } catch (e) {
      handleSaveAsDraftError(e)
    } finally {
      setSaveLoading(false)
    }
  }

  async function handleUpdateStatus(toStatus: CLAIM_STATUS) {
    setSaveLoading(true)
    if (toStatus === CLAIM_STATUS.MANUALLY_ADJUDICATED) {
      try {
        await adjudicateClaimManually({ patientId, claimId })
        notification('The claim has been manually adjudicated.', 'success')
      } catch (e) {
        logErrorAndNotify('Failed to manually adjudicate claim', e)
        throw e
      } finally {
        setSaveLoading(false)
      }
      return
    }

    const body = constructPOSTBody(constructData())
    try {
      if (!body.claimId && !data.claimId) {
        throw new Error('Claim ID is missing.')
      }
      const claimId =
        body.claimId && body.claimId !== '' ? body.claimId : data.claimId
      await submitClaimManually({ claimId, patientId, body })
      notification('The claim status has been manually updated', 'success')
      navigateToClaimsTable()
    } catch (e) {
      handleSaveAsDraftError(e)
    } finally {
      setSaveLoading(false)
    }
  }

  async function handleCancelClaim() {
    setSaveLoading(true)
    const body = {
      patientControlNumber: claimDetails.patientControlNumber,
    }
    try {
      await postCancelClaim(claimId, patientId, body)
      notification('The claim has been canceled correctly.', 'success')
      navigateToClaimsTable()
    } catch (e) {
      logErrorAndNotify('There was an error canceling this claim.', e)
    } finally {
      setSaveLoading(false)
    }
  }

  const handleSaveClaimMemo = (val: string) => {
    setUnsavedDraft(false)
    setClaimMemoInitialValue(val)
  }
  /**
   * Legacy method for handling errors during claim submission network requests
   * to be replaced with new error handling and rendering logic once those are ready
   * @param error
   */
  function handleLegacySubmitClaimError(error: any) {
    let messages: { message: string }[] | null
    if (error.details) {
      // this means that the error object is a response from joi validation
      messages = error.details
    } else {
      // in this case, treat the error as a stringified Change API response
      messages = extractChangeErrors(error)
    }
    setClaimSubmitError({
      isError: true,
      message: messages,
    })
  }

  /**
   * handler for errors raised during claim submission network requests
   * @param e
   */
  function handleSubmitClaimError(e: any) {
    logErrorAndNotify('There was an error submitting this draft.', e)

    const error = e.response?.data?.error
    const isTimeoutError = e.response?.status === GATEWAY_TIMEOUT_STATUS
    const requiresRefresh =
      isTimeoutError || error?.info?.type === REFRESH_REQUIRED_ERROR_TYPE

    if (requiresRefresh) {
      const problemDetails = isTimeoutError
        ? GATEWAY_TIMEOUT_PROBLEM_DETAILS
        : error?.info
      displayRefreshError(problemDetails)
      return
    }

    scrollToTop()
    handleLegacySubmitClaimError(error)
  }

  async function handleSubmitClaim() {
    setSaveSubmitLoading(true)
    // Draft is saved even if an error occurs.
    setUnsavedDraft(false)
    const constructedData = constructData()
    if (!validateClaimSubmit(constructedData)) {
      setSaveSubmitLoading(false)
      return
    }
    try {
      const body = constructPOSTBody(constructedData)
      body.claimId =
        body.claimId && body.claimId !== '' ? body.claimId : data.claimId
      setClaimSubmitError({ isError: false, message: null })
      await submitClaim(patientId, body)
      notification('The claim has been submitted.', 'success')
      navigateToClaimsTable()
    } catch (e) {
      handleSubmitClaimError(e)
    } finally {
      setSaveSubmitLoading(false)
    }
  }

  const refreshPage = () => {
    window.location.reload()
  }

  const claimFormRefreshErrorMessage = useMemo(() => {
    if (!claimFormRefreshError?.title) {
      return null
    }
    return (
      <div
        className={styles.alertSubmitClaimContainer}
        data-testid="refresh-required-alert"
      >
        <ExclamationCircleOutlined
          style={{ color: '#FAAD14', height: 24, width: 24, marginTop: 5 }}
        />
        <div
          className={styles.alertSubmitClaimSubContainer}
          data-testid="refresh-required-message"
        >
          <Text className={styles.errorTextTitle}>
            {claimFormRefreshError.title}
          </Text>
          <Text className={styles.errorTextDescription}>
            {claimFormRefreshError.detail}
          </Text>
        </div>
        <div className={styles.alertSubmitClaimSubContainer}>
          <Button
            type="primary"
            onClick={refreshPage}
            testId="error-refresh-button"
          >
            Refresh
          </Button>
        </div>
      </div>
    )
  }, [claimFormRefreshError])

  const renderErrorMessage = useMemo(() => {
    if (!(claimSubmitError?.isError && claimSubmitError.message?.length)) {
      return null
    }
    return (
      <div
        className={styles.errorSubmitClaimContainer}
        data-testid="submit-claim-error"
      >
        <CloseCircleOutlined
          style={{ color: '#F5222D', height: 24, width: 24, marginTop: 5 }}
        />
        <div
          className={styles.errorSubmitClaimSubContainer}
          data-testid="error-messages"
        >
          <Text className={styles.errorTextTitle}>
            Before submitting there are few errors to check
          </Text>
          {claimSubmitError.message.map(({ message }: { message: string }) => {
            return (
              <Text className={styles.errorTextDescription}>• {message}</Text>
            )
          })}
        </div>
      </div>
    )
  }, [claimSubmitError])

  return (
    <div className={stylesScroll.scroll} ref={containerRef}>
      <PatientHeader
        providerId={providerId}
        patientId={patientId}
        healthGorillaUserName={healthGorillaUserName}
      />

      <ClaimsNavigationGuardModal
        when={requireModal}
        navigation={navigate}
        unsavedDraft={unsavedDraft}
        handleDiscard={handleDiscard}
        saveLoading={saveLoading}
        saveSubmitLoading={saveSubmitLoading}
      />
      {loading ? (
        <Skeleton paragraph={{ rows: StandardSkeletonRows.fullPage }} />
      ) : (
        <div className={styles.container}>
          {claimFormRefreshErrorMessage}
          <div className={styles.claimHeader}>
            <ClaimHeader
              handleDiscard={handleDiscard}
              handleDelete={handleDelete}
              handleSaveAsDraft={handleSaveAsDraft}
              handleCancelClaim={handleCancelClaim}
              handleUpdateStatus={handleUpdateStatus}
              unsavedDraft={unsavedDraft}
              claimDetails={claimDetails}
              saveLoading={saveLoading}
              claimStatus={claimStatus}
              editPayments={editPayments || !disableFields}
              requireModal={requireModal}
              setEditPayments={setEditPayments}
              saveSubmitLoading={saveSubmitLoading}
              disabled={disableFields}
              deletionDisabled={disableDeletion}
              patientId={patientId}
              claimId={claimId}
            />
            {renderErrorMessage}
          </div>
          <ClaimStatusDetails
            claimStatusUpdate={claimDetails.claimStatusUpdate}
          />
          {paymentInfo && transactionsData && data && (
            <div>
              <div className={styles.secondaryInsuranceBox}>
                <Payments
                  _testId="submitted-claim-payments"
                  claim={data}
                  paymentInfo={paymentInfo}
                  patientId={patientId}
                  claimId={claimId}
                  refetch={refetchPayments}
                  refetchClaim={refetchClaim}
                  isLoading={fetchingPayments || isFetching}
                  companyToSubmitTo={companyToSubmitTo}
                  claimStatus={claimStatus}
                  editPayments={editPayments || !disableFields}
                  setEditPayments={setEditPayments}
                  payments={transactionsData.payments}
                  refunds={transactionsData.refunds}
                />
              </div>
            </div>
          )}
          {claimStatus !== CLAIM_STATUS.DRAFT &&
          transactionsData?.payments?.length ? (
            <div className={styles.bePaymentsContainer}>
              <BillableEntityPayments
                isLoading={transactionsIsLoading}
                payments={transactionsData.payments}
                insuranceClaim={
                  {
                    uuid: claimId,
                    patientControlNumber: data?.patientControlNumber,
                    billableEntity: data?.billableEntity,
                  } as InsuranceClaim
                }
                insuranceClaimUuid={claimId}
                patientId={patientId}
                patientControlNumber={claimDetails.patientControlNumber}
              />
            </div>
          ) : null}
          <ClaimMemo
            initialValue={claimMemoInitialValue}
            value={claimMemoValue}
            onChange={setClaimMemoValue}
            onSave={handleSaveClaimMemo}
            disabled={saveLoading || saveSubmitLoading}
            claimId={claimId}
            patientId={patientId}
          />
          <div className={styles.secondaryInsuranceBox}>
            <div className={styles.formMaxWidth}>
              <PatientInformation
                testId="create-claim-patient-information"
                patientInfo={patientInfo}
                disabled={disableFields}
                setPatientInfo={setPatientInfo}
              />
            </div>
          </div>
          <div className={styles.insuranceBox}>
            <div className={styles.formMaxWidth}>
              <PrimaryInsuranceClaim
                testId="create-claim-primary-insurance"
                loading={loading}
                disabled={disableFields}
                primaryInsurance={primaryInsurance}
                setPrimaryInsurance={setPrimaryInsurance}
                primaryHolder={primaryHolder}
                setPrimaryHolder={setPrimaryHolder}
              />
            </div>
          </div>
          <div className={styles.claimToSubmitTo}>
            <div className={styles.extraMarginLeft}>
              <div>
                <Text className={styles.boldSubTitle}>
                  Primary insurance - Select which insurance company to submit
                  this claim to
                </Text>
              </div>
              <div className={styles.tooltipContainer}>
                <InfoCircleOutlined
                  style={{ color: '#1890FF', height: 21, width: 21 }}
                />
                <Text className={styles.tooltipText}>
                  Submitting to the wrong insurance company is one of the most
                  common claim rejections. Check the patient’s insurance card
                  for any instructions on who to submit to, as well as to find
                  the insurance company ID. You can search the insurance company
                  dropdown below by company ID.
                </Text>
              </div>
            </div>
            <div className={styles.formMaxWidth}>
              <CompanyToSubmit
                testId="create-claim-primary-insurance"
                loading={loading}
                companyToSubmitTo={companyToSubmitTo}
                setCompanyToSubmitTo={setCompanyToSubmitTo}
                disabled={disableFields}
              />
            </div>
          </div>
          <div className={styles.secondaryInsuranceBox}>
            <div className={styles.formMaxWidth}>
              <SecondaryInsuranceClaim
                testId="create-claim-secondary-insurance"
                loading={loading}
                secondaryInsurance={secondaryInsurance}
                setSecondaryInsurance={setSecondaryInsurance}
                secondaryHolder={secondaryHolder}
                setSecondaryHolder={setSecondaryHolder}
                includeSecondary={includeSecondary}
                setIncludeSecondary={setIncludeSecondary}
                disabled={disableFields}
                claimStatus={claimStatus}
              />
            </div>
          </div>
          <div className={styles.secondaryInsuranceBox}>
            <div className={styles.formMaxWidth}>
              <ReferringProviderSection
                testId="create-claim-referring-provider"
                referringProviderInfo={referringProviderInfo}
                setReferringProviderInfo={setReferringProviderInfo}
                includeReferringProvider={includeReferringProvider}
                setIncludeReferringProvider={setIncludeReferringProvider}
                disabled={disableFields}
              />
            </div>
          </div>
          <div className={styles.secondaryInsuranceBox}>
            <div
              className={styles.formMaxWidth}
              data-testid="appointment-info-section"
            >
              <AppointmentInformation
                testId="create-claim-appointment-info"
                appointmentInfo={appointmentInfo}
                setAppointmentInfo={setAppointmentInfo}
                notes={availableNotes}
                loading={notesLoading || loadingLocations}
                locations={locations}
                handleNoteChange={handleNoteChange}
                disabled={disableFields}
              />
            </div>
          </div>
          <div className={styles.secondaryInsuranceBox}>
            <div className={styles.formMaxWidth}>
              <RenderingProvider
                testId="create-claim-rendering-provider"
                renderingProviderInfo={renderingProviderInfo}
                setRenderingProviderInfo={setRenderingProviderInfo}
                teammates={teammates}
                teamLoading={teamLoading}
                disabled={disableFields}
              />
            </div>
          </div>
          <div className={styles.secondaryInsuranceBox}>
            <div className={styles.formMaxWidth}>
              <SupervisingProvider
                testId="create-claim-supervising-provider"
                supervisingProvider={supervisingProvider}
                setSupervisingProvider={setSupervisingProvider}
                teammates={teammates}
                teamLoading={teamLoading}
                includeSupervisingProvider={includeSupervisingProvider}
                setIncludeSupervisingProvider={setIncludeSupervisingProvider}
                disabled={disableFields}
              />
            </div>
          </div>
          <div className={styles.secondaryInsuranceBox}>
            <div className={styles.formMaxWidth}>
              <BillingClaim
                testId="create-claim-billing-info"
                billingInfo={billingInfo}
                setBillingInfo={setBillingInfo}
                locations={locations}
                teammates={teammates}
                practiceData={practiceData}
                loading={loadingLocations || teamLoading}
                renderingProviderId={renderingProviderInfo.providerId}
                disabled={disableFields}
              />
            </div>
          </div>
          <div className={styles.diagnosisSection}>
            <Diagnoses
              isLoading={false}
              isEditing={true}
              isSuperbillEnabled={true}
              isClaim={true}
              diagnoses={activeDiagnoses}
              onDelete={handleDxDelete}
              onReset={resetDiagnoses}
              disabled={disableFields}
            />
            <div className={styles.smallExtraMarginLeft}>
              <PatientConditionRelationWithClaim
                testId="create-claim-patient-condition-relation"
                patientConditionRelation={patientConditionRelation}
                setPatientConditionRelation={setPatientConditionRelation}
                disabled={disableFields}
              />
            </div>
          </div>
          <div className={styles.diagnosisSection}>
            <ProceduresContainer isLoading={loading}>
              <LineItemsContainer
                isEditing={!disableFields}
                isLoading={loading}
                lineItems={lineItems}
                diagnoses={activeDiagnoses}
                onLineItemUpdate={handleLineItemUpdate}
                onLineItemDelete={handleLineItemDelete}
                selectedTemplateId={undefined} // this should be the Template Id selected for the claim, only used for already placed claims (at least Draft state saved)
                onTemplateChange={(billingTemplate?: BillingTemplate) =>
                  handleBillingTemplateSelect(billingTemplate)
                }
                onBillingCodeAdd={(newCode: BareBillingCode) => {
                  addNewLineItem(newCode)
                }}
                setAuthNumber={setAuthNumber}
                authNumber={authNumber}
              />
              <NationalDrugCode
                lineItems={lineItems}
                ndcItems={ndcItems}
                onNdcItemsChange={setNdcItems}
                isEditingDisabled={
                  disableFields || saveLoading || saveSubmitLoading
                }
              />
            </ProceduresContainer>
          </div>
          <div className={styles.signatureSection}>
            <ClaimSignature
              teammates={teammates}
              signingProvider={signingProvider}
              setSigningProvider={setSigningProvider}
              teamLoading={teamLoading}
              handleDiscard={handleDiscard}
              handleSaveAsDraft={handleSaveAsDraft}
              handleSubmitClaim={handleSubmitClaim}
              saveLoading={saveLoading}
              claimStatus={claimStatus}
              disabled={disableFields}
              deletionDisabled={disableDeletion}
              saveSubmitLoading={saveSubmitLoading}
            />
          </div>
        </div>
      )}
    </div>
  )
}
