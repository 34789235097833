import React, { useEffect } from 'react'

import { useFormikContext } from 'formik'

import { ChangePayersList } from '../../../../api/intakeForms'
import { Card } from '../../../../stories/BaseComponents'
import InlineField from '../InlineField'
import { PRIMARY_INSURANCE } from '../field-constants'
import { BoolOptionValue, ClaimForm, Option } from '../types'
import { getClaimFilingCodeForPayer, isSubscriberSelf } from '../utils'

import styles from './shared.module.scss'

const { INSURANCE, SUBSCRIBER, SUBSCRIBER_ADDRESS } = PRIMARY_INSURANCE
const isAddressSame = (
  subscriberAddressSameAsPatient: BoolOptionValue | null
) => subscriberAddressSameAsPatient === 'yes'

export const testIds = {
  container: 'claim-primary-insurance',
}

export type PrimaryInsuranceProps = {
  payers: {
    payerOptions: Option[]
    payersByName: { [index: string]: ChangePayersList }
  }
}

const PrimaryInsurance: React.FC<PrimaryInsuranceProps> = ({
  payers: { payerOptions, payersByName },
}) => {
  // current form values and touched status
  const { values, touched, setFieldValue, setValues } =
    useFormikContext<ClaimForm>()
  const {
    primaryInsurance: {
      name,
      subscriberRelationship,
      subscriberAddressSameAsPatient,
      subscriberAddress,
    },
  } = values
  const nameTouched = !!touched.primaryInsurance?.name
  const subscriberRelationshipTouched =
    !!touched.primaryInsurance?.subscriberRelationship
  const subscriberAddressSameAsPatientTouched =
    !!touched.primaryInsurance?.subscriberAddressSameAsPatient
  const subscriberAddressTouched = !!touched.primaryInsurance?.subscriberAddress

  // dependent fields logic
  // auto fill claimFilingCode when primary insurance changes OR when a newly created claim loads
  useEffect(() => {
    if (!nameTouched) {
      return
    }
    const newClaimFilingCode =
      name && payersByName
        ? getClaimFilingCodeForPayer(payersByName, name)
        : null
    setFieldValue('primaryInsurance.claimFilingCode', newClaimFilingCode)
  }, [name, nameTouched, payersByName])
  // clear subscriber fields if patient is the subscriber
  useEffect(() => {
    if (!subscriberRelationshipTouched) {
      return
    }
    if (isSubscriberSelf(subscriberRelationship)) {
      // user setFieldValue if only one field needs to be updated
      // use setValues if more than one field need to be updated
      const newValues: ClaimForm = {
        ...values,
        primaryInsurance: {
          ...values.primaryInsurance,
          subscriberFirstName: null,
          subscriberLastName: null,
          subscriberDateOfBirth: null,
          subscriberLegalSex: null,
          subscriberEmail: null,
          subscriberPhoneNumber: null,
          subscriberAddressSameAsPatient: 'yes',
          subscriberAddress: null,
          subscriberAddress2: null,
        },
      }
      setValues(newValues)
    }
  }, [subscriberRelationship, subscriberRelationshipTouched])
  // clear subscriber address fields if subscribe has same address as patients
  useEffect(() => {
    if (!subscriberAddressSameAsPatientTouched) {
      return
    }
    if (isAddressSame(subscriberAddressSameAsPatient)) {
      const newValues: ClaimForm = {
        ...values,
        primaryInsurance: {
          ...values.primaryInsurance,
          subscriberAddress: null,
          subscriberAddress2: null,
        },
      }
      setValues(newValues)
    }
  }, [subscriberAddressSameAsPatient, subscriberAddressSameAsPatientTouched])
  // sync address2 with selected address
  useEffect(() => {
    if (!subscriberAddressTouched) {
      return
    }
    const newSubscriberAddress2 = subscriberAddress?.Address2 ?? null
    setFieldValue('primaryInsurance.subscriberAddress2', newSubscriberAddress2)
  }, [subscriberAddress, subscriberAddressTouched])

  // rendering functions
  const renderInsuranceFields = () =>
    INSURANCE.map((item) => {
      if (item.name === 'primaryInsurance.name') {
        return <InlineField {...item} key={item.name} options={payerOptions} />
      }
      return <InlineField {...item} key={item.name} />
    })

  const renderSubscriberFields = () =>
    SUBSCRIBER.map((item) => <InlineField {...item} key={item.name} />)

  const renderAddressFields = () =>
    SUBSCRIBER_ADDRESS.map((item) => <InlineField {...item} key={item.name} />)

  // show/hide logic
  const isShowSubscriberSection =
    subscriberRelationship && !isSubscriberSelf(subscriberRelationship)
  const isShowAddressFields =
    subscriberAddressSameAsPatient &&
    !isAddressSame(subscriberAddressSameAsPatient)

  return (
    <Card
      testId={testIds.container}
      style={{ marginTop: 24 }}
      bodyStyle={{ paddingBottom: 16 }}
    >
      <div className={styles.section}>
        <div className={styles.header}>
          <span className={styles.title}>{'Primary insurance'}</span>
        </div>
        <div>{renderInsuranceFields()}</div>
      </div>
      {isShowSubscriberSection && (
        <div className={styles.section}>
          <div className={styles.header}>
            <span className={styles.title}>
              {'Primary insurance subscriber info'}
            </span>
          </div>
          <div>
            {renderSubscriberFields()}
            {isShowAddressFields && renderAddressFields()}
          </div>
        </div>
      )}
    </Card>
  )
}

export default PrimaryInsurance
