import React from 'react'

import { useField } from 'formik'

import {
  InlineEditDate,
  InlineEditSelect,
  InlineEditText,
  generateNotification,
} from '../../../stories/BaseComponents'
import InlineEditAddress, {
  AddressAPIVendors,
} from '../../../stories/BaseComponents/InlineEditFields/InlineEditAddress'
import { NotificationType } from '../../../stories/BaseComponents/Notification'
import { InlineFieldProps } from './types'
import { getAddressInputDisplay } from './utils'

import styles from './InlineField.module.scss'

const InlineField: React.FC<InlineFieldProps> = ({
  name,
  type,
  label,
  placeholder,
  options,
  disabled,
  isRequired,
  validator,
  formatter,
  isFetching,
}) => {
  const [{ value }, { error, touched }, { setValue, setTouched }] = useField({
    name,
  })

  const handleChange = (newVal: any) => {
    setTouched(true)
    if (validator && newVal) {
      const errorMessage = validator(newVal)
      if (errorMessage) {
        generateNotification(errorMessage, NotificationType.ERROR)
        return
      }
    }
    setValue(newVal ?? null)
  }

  const renderInput = () => {
    switch (type) {
      case 'text':
        return (
          <InlineEditText
            testId={name}
            value={value}
            isWrongValue={!!error}
            onSave={handleChange}
            placeholder={placeholder}
            disabled={disabled}
            padded={true}
            requireConfirmation={false}
            scrollToField={false}
            displayValue={formatter && value ? formatter(value) : undefined}
          />
        )
      case 'date':
        return (
          <InlineEditDate
            testId={name}
            value={value}
            onSave={handleChange}
            placeholder={placeholder}
            disabled={disabled}
            allowClear={true}
            padded={true}
            requireConfirmation={false}
            scrollToField={false}
          />
        )
      case 'select':
        return (
          <InlineEditSelect
            testId={name}
            value={value}
            isWrongValue={!!error}
            onSave={handleChange}
            options={options || []}
            placeholder={placeholder}
            disabled={disabled}
            allowClear={true}
            padded={true}
            requireConfirmation={false}
            scrollToField={false}
            isFetching={isFetching}
          />
        )
      case 'address':
        return (
          <InlineEditAddress
            testId={name}
            value={value ? getAddressInputDisplay(value) : ''}
            onSave={handleChange}
            placeholder={placeholder}
            disabled={disabled}
            vendor={AddressAPIVendors.SmartyStreets}
            padded={true}
            requireConfirmation={false}
            scrollToField={false}
          />
        )
    }
  }

  return (
    <div className={styles.fieldContainer}>
      <div className={styles.row}>
        <div className={styles.label}>
          <span className={styles.labelText}>{label}</span>
          <span className={styles.labelRequired}>{isRequired && '*'}</span>
        </div>
        {renderInput()}
      </div>
      <div className={styles.row}>
        <div className={styles.errorContainer}>
          {error && touched ? (
            <span className={styles.errorMessage}>{error}</span>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default InlineField
