import React from 'react'

import { useFormikContext } from 'formik'

import { Card } from '../../../../stories/BaseComponents'
import Provider from '../Provider'
import SectionToggle from '../SectionToggle'
import { ClaimForm, SectionToggleProps } from '../types'

import styles from './shared.module.scss'

export const testIds = {
  container: 'claim-supervising-provider',
}
const TOGGLE: SectionToggleProps = {
  name: 'supervisingProvider.isIncluded',
  label: `This visit had a supervising provider`,
}

const SupervisingProvider: React.FC = () => {
  const {
    values: {
      supervisingProvider: { isIncluded },
    },
  } = useFormikContext<ClaimForm>()
  return (
    <Card
      testId={testIds.container}
      style={{ marginTop: 24 }}
      bodyStyle={{ paddingBottom: 16 }}
    >
      <div className={styles.section}>
        <div className={styles.header}>
          <span className={styles.title}>{'Supervising provider'}</span>
        </div>
        <div>
          <SectionToggle {...TOGGLE} />
          {isIncluded && <Provider providerType="supervisingProvider" />}
        </div>
      </div>
    </Card>
  )
}

export default SupervisingProvider
