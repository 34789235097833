import {
  BillableEntity,
  ClaimStatusUpdate,
  Teammate,
} from '../../../shared-types'

// FE data model
export type NdcItem = {
  key: string
  uuid?: string
  billingCodeId: number | null
  idQualifier: NdcIdQualifier | null
  ndc: string | null
  ndcLabel: string | null
  numberOfUnits: number | null
  unitType: UnitMeasurementType | null
  prescriptionNumber: string | null
}

// BE data model
export type InsuranceClaimNdc = {
  uuid?: string
  billingCodeId: number | null
  ndcIdQualifier: NdcIdQualifier | null
  ndcEntered: string | null
  ndcLabel: string | null
  numberOfUnits: number | null
  unitMeasurementType: UnitMeasurementType | null
  prescriptionNumber: string | null
}

export enum NdcIdQualifier {
  NDC_IN_5_4_2_FORMAT = 'NDC_IN_5_4_2_FORMAT',
  EAN_UCC_13 = 'EAN_UCC_13',
  EAN_UCC_8 = 'EAN_UCC_8',
  HEALTH_INDUSTRY_BAR_CODE_SUPPLIER_LABELING = 'HEALTH_INDUSTRY_BAR_CODE_SUPPLIER_LABELING',
  CUSTOMER_ORDER_NUMBER = 'CUSTOMER_ORDER_NUMBER',
  GTIN_14 = 'GTIN_14',
  UCC_12 = 'UCC_12',
}

export enum UnitMeasurementType {
  INTERNATIONAL_UNIT = 'INTERNATIONAL_UNIT',
  GRAM = 'GRAM',
  MILLIGRAM = 'MILLIGRAM',
  MILLILITER = 'MILLILITER',
  UNIT = 'UNIT',
}

// TODO: split and update various claim data type (#CARE-2254)
export type ClaimData = {
  patient?: Patient
  payerControlNumber?: string
  submittedInfo?: Submitted
  primaryInsurance?: PatientInsurance
  secondaryInsurance?: PatientInsurance
  selectedInsuranceName?: string | null
  referringProvider?: Provider
  renderingProvider?: Provider
  supervisingProvider?: Provider
  claimStatus?: ClaimStatus
  patientControlNumber: string // required
  claimMemo?: string | null
  payerName?: string
  note?: Note
  signature?: Signature
  transactionSetNumber?: number
  claimFrequencyCode?: string
  diagnosesRelation?: DiagnosesRelation
  diagnoses?: Diagnosis[]
  lineItems?: LineItems[]
  ndcs?: InsuranceClaimNdc[]
  billingType?: 'Practice' | 'Provider' | 'practice' | 'provider'
  billing?: Billing
  patientConditionRelation?: PatientConditionRelation
  companyToSubmitTo?: string
  claimId?: string // only exist in newly created claim
  controlNumber?: string
  authNumber?: string
  canceledAt?: string
  createdAt?: string
  unsavedDraft?: boolean // only exist in newly created claim
  billableEntity?: BillableEntity
  patientPaidAmountCents?: number
  patientResponsibilityAmountCents?: number
  insurancePaidAmountCents?: number
  writeOffAmountCents?: number
  isMissingSubmission?: boolean
  claimStatusUpdate: ClaimStatusUpdate
}

export type Patient = {
  id?: string | null
  firstName?: string | null
  lastName?: string | null
  dateOfBirth?: string | null
  address?: AddressUpperCase | null
  legalSex?: Sex | null
}

export type Submitted = {
  submittedDate?: string | null
  controlNumber?: string | null
}

export type PatientInsurance = {
  isIncluded?: boolean | null
  patientInsuranceId?: number | null
  isInformationReleaseAuthorized?: boolean | null
  isPaymentAuthorized?: boolean | null
  subscriberAddress?: Address | null
  subscriberDateOfBirth?: string | null
  subscriberEmail?: string | null
  subscriberFirstName?: string | null
  subscriberMiddleName?: string | null
  subscriberLastName?: string | null
  subscriberPhoneNumber?: string | null
  subscriberRelationship?: string | null
  subscriberLegalSex?: Sex | null
  subscriberId?: string | null
  isSubscriber?: boolean | null
  name?: string | null
  memberId?: string | null
  groupId?: string | null
  companyTradingPartnerServiceId?: string | null
  claimFilingCode?: string | null
  insuranceTypeCode?: string | null
}

export type Provider = {
  providerId?: string | null
  providerNpi?: string | null
  providerTaxonomyCode?: string | null
  providerFirstName?: string | null
  providerLastName?: string | null
  providerEin?: string | null
  providerSsn?: string | null
  encryptedFirstFiveSSN?: string | null
  ssnLastFour?: string | null
  providerSSNfirstFive?: string | null
  providerSSNlastFour?: string | null
  taxType?: TAX_TYPE | null
  isIncluded?: boolean | null
}

export type Note = {
  noteId?: string
  locationId?: string
  locationName?: string
  title?: string
  posCode?: string
  posVersion?: string
  practiceId?: number
  practiceName?: string
  practiceNpi?: string
  endDate?: string | null
  Signatures?: NoteSignature[]
  signed?: boolean
  startDate?: string | null
  address?: Address
}

export type NoteSignature = {
  CreatedAt: string
  SignedBy: string
  SignedByProviderId?: string
}

export type DiagnosesRelation = {
  carAccident?: boolean
  carAccidentState?: string
  workAccident?: boolean
  otherAccident?: boolean
}

export type Diagnosis = {
  id?: number
  code: string
  description?: string
  order: number
  isDeleted: boolean
  diagnosisId: string
}

export type LineItems = {
  billingCode: {
    code: string
    version: BillingCodeType
    description: string
    ownerId: string
    id: number
  }
  modifiers: string[]
  units: number
  unitChargeAmountCents: number
  totalAdjustmentAmountCents: number
  dxPointers: number[]
  uuid?: string
}

export type Billing = {
  name?: string
  firstName?: string
  locationName?: string
  lastName?: string
  ein?: string
  npi?: string
  providerId?: string
  taxType?: TAX_TYPE
  locationId?: string
  ssn?: string
  phoneNumber?: string
  encryptedFirstFiveSSN?: string
  ssnLastFour?: string
  ssnFirstFive?: string
  address?: Address
  type: string
  email?: string
  taxonomyCode?: string
}

export type Signature = {
  providerId?: string
  date?: string | null
  providerFirstName?: string
  providerLastName?: string
  title?: string
  providerEmail?: string
  providerNpi?: string
  providerTaxonomyCode?: string
  providerEin?: string
  providerSsn?: string
  phoneNumber?: string
}

export type PatientConditionRelation = {
  autoAccident?: boolean
  otherAccident?: boolean
  accidentState?: string
  employmentAccident?: boolean
}

export type Address = {
  city?: string | null
  state?: string | null
  zipCode?: string | null
  addressLine2?: string | null
  addressLine1?: string | null
  country?: string | null
}

export type AddressUpperCase = {
  City?: string | null
  State?: string | null
  ZipCode?: string | null
  AddressLine2?: string | null
  AddressLine1?: string | null
  Country?: string | null
}

export enum Sex {
  F = 'F',
  M = 'M',
  X = 'X',
  Unknown = 'Unknown',
}

export enum TAX_TYPE {
  SSN = 'SSN',
  EIN = 'EIN',
}

export enum BillingCodeType {
  CPT_V2020 = 'CPT_V2020',
  CPT_V2021 = 'CPT_V2021',
  CPT_V2022 = 'CPT_V2022',
  CPT_V2023 = 'CPT_V2023',
  CUSTOM = 'CUSTOM',
  HCPCS_V2020 = 'HCPCS_V2020',
}

export enum ClaimStatus {
  DRAFT = 'DRAFT',
  SUBMITTED = 'SUBMITTED',
  REJECTED = 'REJECTED',
  ACCEPTED = 'ACCEPTED',
  PAID = 'PAID',
  INGESTION_ERROR = 'INGESTION_ERROR',
  CHANGE_ERROR = 'CHANGE_ERROR',
  PAYMENT_PROCESSED = 'PAYMENT_PROCESSED',
  DB_DELETED = 'DB_DELETED',
  CANCELED = 'CANCELED',
  ADJUDICATED = 'ADJUDICATED',
}

// form
export type AddressInput = {
  Address1: string | null
  Address2: string | null
  City: string | null
  State: string | null
  Zipcode: string | null
}

export type BoolOptionValue = 'yes' | 'no'

export type ClaimFormProvider = {
  isIncluded?: boolean | null
  providerId: string | null
  taxType: TAX_TYPE | null
  providerEin: string | null
  providerSsn: string | null
  providerSsnFirstFive?: string | null
  providerSsnLastFour?: string | null
  providerNpi: string | null
  providerTaxonomyCode: string | null
}

export type ClaimForm = {
  claimMemo: string | null
  patient: {
    firstName: string | null
    lastName: string | null
    dateOfBirth: string | null
    address: AddressInput | null
    address2: string | null
    legalSex: Sex | null
  }
  primaryInsurance: {
    name: string | null
    claimFilingCode: string | null
    memberId: string | null
    groupId: string | null
    informationReleaseAuthorized: BoolOptionValue | null
    paymentAuthorized: BoolOptionValue | null
    subscriberRelationship: string | null
    subscriberFirstName: string | null
    subscriberLastName: string | null
    subscriberDateOfBirth: string | null
    subscriberLegalSex: Sex | null
    subscriberEmail: string | null
    subscriberPhoneNumber: string | null
    subscriberAddressSameAsPatient: BoolOptionValue | null
    subscriberAddress: AddressInput | null
    subscriberAddress2: string | null
  }
  selectedInsuranceName: string | null
  secondaryInsurance: {
    isIncluded: boolean | null
    name: string | null
    claimFilingCode: string | null
    insuranceTypeCode: string | null
    memberId: string | null
    groupId: string | null
    informationReleaseAuthorized: BoolOptionValue | null
    paymentAuthorized: BoolOptionValue | null
    subscriberRelationship: string | null
    subscriberFirstName: string | null
    subscriberLastName: string | null
    subscriberDateOfBirth: string | null
  }
  referringProvider: {
    isIncluded: boolean | null
    providerFirstName: string | null
    providerLastName: string | null
    providerNpi: string | null
    providerEin: string | null
  }
  renderingProvider: ClaimFormProvider
  supervisingProvider: ClaimFormProvider
}

export type ClaimFormStatus =
  | null
  | 'INITIAL_LOADING'
  | 'SAVING_CLAIM'
  | 'DELETING_CLAIM'
  | 'CANCELLING_CLAIM'
  | 'SAVING_CLAIM_MEMO'

export interface ClaimProvider extends Teammate {
  useEINorSSN?: TAX_TYPE
}

export type Option = {
  label: string
  value: string
}

export type InlineFieldProps = {
  name: string
  type: string
  label?: string
  placeholder?: string
  options?: { value: string; label: string }[]
  disabled?: boolean
  isRequired?: boolean
  /**
   * Returns truthy (error message) if there is an error, otherwise falsy
   */
  validator?: (val: any) => string | undefined
  formatter?: (val: string) => string
  isFetching?: boolean // fetching remote data in select component
}

export type SectionToggleProps = {
  name: string
  label: string
}
