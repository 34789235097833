import { useEffect, useMemo, useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import { useHistory, useParams } from 'react-router-dom'

import { getLocation } from '../../../../api/api-lib'
import { getClinicalNotesUrl } from '../../../../containers/Patient/ClinicalNotes/utils'
import { useTeammateData } from '../../../../hooks/queries/useTeammateData'
import { useInvoice } from '../../../../hooks/useBillingInfo'
import { useProfileUrlParams } from '../../../../hooks/useProfileUrlParams'
import { useProviderDetails } from '../../../../hooks/useProviderInfo'
import { s3DownloadPracticeLogos } from '../../../../libs/awsLib'
import { useFeatureFlags } from '../../../../libs/featureFlags'
import { Location } from '../../../../shared-types'
import { Spinner } from '../../../../stories/BaseComponents'
import { RichTextEditor } from '../../Components'
import { useCreateNote, useNote } from '../../hooks'
import { ErrorView } from './ErrorView'

type EditorProps = { healthGorillaUserName: string }

export const Editor = ({ healthGorillaUserName }: EditorProps) => {
  const { claims } = useFeatureFlags()
  const history = useHistory()
  const { urlParams } = useProfileUrlParams()

  const { hasEnabledCustomNotes } = useFeatureFlags()
  const { data: providerInfo, isLoading: clinicDataIsLoading } =
    useProviderDetails()

  const [clinicLogoUrl, setClinicLogoUrl] = useState<string | undefined>()

  useEffect(() => {
    return history.listen((_loc: string, action: string) => {
      if (action === 'POP') {
        history.push(getClinicalNotesUrl({ urlParams }))
      }
    })
  }, [urlParams])

  const { createNewFromId } = useCreateNote({
    onSuccess: (note, creationOptions) => {
      history.push(
        getClinicalNotesUrl({
          isV2: true,
          noteId: note.uuid,
          urlParams,
          saltAlert: creationOptions.fromLast || !!creationOptions.saltNoteId,
        })
      )
    },
  })

  const { noteId } = useParams() as { noteId: string }

  const {
    note,
    isLoading: isNoteLoading,
    isSaving,
    isError,
    onChange,
    onDelete,
    saveError,
    pendingChanges,
    onSign,
    onAddAddendum,
    forceSave,
  } = useNote(noteId, {
    getNoteQueryParams: { includeInsuranceClaims: claims },
  })

  const { data: invoiceData, isLoading: isLoadingInvoice } = useInvoice({
    invoiceUuid: note?.invoiceId ?? undefined,
    v2NotesEnabled: hasEnabledCustomNotes,
  })

  const { data: teammates, isLoading: isTeamLoading } = useTeammateData()

  const teamOptions = useMemo(() => {
    return (
      teammates?.filter(
        (team) =>
          team.roleTitles?.some((role) => role.label === 'Provider') &&
          (team.cognitoId === note?.renderingProviderId ||
            !team.isDeactivated) &&
          team.lastActive !== 'never_logged_in'
      ) ?? []
    )
  }, [teammates])

  const { data: locations, isLoading: isLocationsLoading } = useQuery(
    ['locations'],
    getLocation
  )

  const locationOptions =
    (locations as Array<Location>)?.filter(
      (location) =>
        !location.Deleted && location.LocationId && location.LocationName
    ) ?? []

  useEffect(() => {
    if (!urlParams) return

    // if the flag has been turned off, redirect to the old notes page
    if (!hasEnabledCustomNotes) {
      history.push(getClinicalNotesUrl({ urlParams }))
      return
    }
  }, [urlParams, hasEnabledCustomNotes])

  useEffect(() => {
    if (!providerInfo?.providerId) {
      return
    }

    s3DownloadPracticeLogos(
      `${providerInfo?.providerId}/practiceUncroppedLogo`
    ).then((uncroppedUrl) => {
      setClinicLogoUrl(uncroppedUrl ?? '')
    })
  }, [providerInfo?.providerId])

  const handleNavigateAway = () => {
    forceSave()
  }

  const isAnyLoading =
    !noteId ||
    isNoteLoading ||
    isLocationsLoading ||
    isTeamLoading ||
    clinicDataIsLoading ||
    (!!note?.invoiceId && isLoadingInvoice)

  if (note) {
    return (
      <RichTextEditor
        healthGorillaUserName={healthGorillaUserName}
        note={note}
        invoice={invoiceData?.invoice}
        isLoading={isAnyLoading}
        clinicData={providerInfo?.clinicData}
        clinicLogoUrl={clinicLogoUrl}
        isSaving={isSaving}
        onDelete={onDelete}
        onCopyToNewNote={createNewFromId}
        onSign={onSign}
        onAddAddendum={onAddAddendum}
        onChange={onChange}
        pendingChanges={pendingChanges}
        saveError={saveError}
        teamOptions={teamOptions}
        locationOptions={locationOptions}
        onNavigateAway={handleNavigateAway}
      />
    )
  }

  // check noteId to avoid flashing error on new note create
  if (isError && noteId) {
    return (
      <ErrorView
        title={'Unable to load note data'}
        actionText={'Back to Note List'}
        onClick={() => {
          history.push(getClinicalNotesUrl({ urlParams }))
        }}
      />
    )
  }

  return (
    <div
      className="loading-content d-flex justify-content-center"
      data-testid="note-loading-spinner"
    >
      <Spinner />
    </div>
  )
}
