import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { datadogRum } from '@datadog/browser-rum'
import { SendBirdProvider } from '@sendbird/uikit-react'
import { Layout } from 'antd'
import { Auth } from 'aws-amplify'
import debounce from 'lodash/debounce'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'
import { useHistory, useLocation } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import Routes from './Routes'
import {
  getErxNotificationCount,
  getTeammateData,
  loadProviderInfo,
} from './api/api-lib'
import ErrorBoundary from './components/Error/ErrorBoundary'
import { defaultConfig, globalConfig } from './config/config'
import { useAppVersionCheck } from './hooks/useAppVersionCheck'
import { AppContext } from './libs/contextLib'
import { onError } from './libs/errorLib'
import {
  FeatureFlagConsumer,
  identifyFlagUser,
  useFeatureFlags,
} from './libs/featureFlags'
import { setFreshpaintUserAndGroupPropertiesWrapper } from './libs/freshpaint'
import {
  ViewportEvents,
  trackViewportEvent,
} from './libs/freshpaint/viewportEvents'
import { getSendbirdProviderEventHandlers } from './libs/sendbird/sendbird'
import Sentry from './libs/sentry'
import getCognitoUser from './shared/Helpers/getCognitoUser'
import { Spinner } from './stories/BaseComponents'
import NavigationSideBar, {
  RoutesWithSidebar,
} from './stories/Navigation/NavigationSideBar'
import VerticalMainNavigation, {
  RoutesWithoutNavbar,
} from './stories/Navigation/VerticalMainNavigation'
import PatientProfilePanel from './stories/Patients/PatientProfilePanel'
import { SendbirdConnectionHandlerProvider } from './v2/messaging/SendbirdConnectionHandlerProvider'
import { UnreadMessageCountsProvider } from './v2/messaging/UnreadMessageCounts'

// antd (design system) overrides
import styles from './App.module.scss'
import './App.scss'
import './Containers.scss'
import './SelectSearch.scss'
import 'react-toastify/dist/ReactToastify.css'

const { Content } = Layout

function App({ ldClient }) {
  const history = useHistory()
  const location = useLocation()
  const [isAuthenticating, setIsAuthenticating] = useState(true)
  const [userIsIdentified, setUserIsIdentified] = useState(false)
  const [datadogInitializationTimeout, setDatadogInitializationTimeout] =
    useState(null)
  const [isAuthenticated, userHasAuthenticated] = useState(null)
  const [isSideBarVisible, setIsSideBarVisible] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [providerData, setProviderData] = useState({})
  const [teammates, setTeammates] = useState([])
  const [appSettings, setSettingsData] = useState(null)
  const [loggedInUserCognitoId, setLoggedInUserCognitoId] = useState('')
  const [healthGorillaUserName, setHealthGorillaUserName] = useState('')
  const erxNotifications = localStorage.getItem('erxNotificationCount')
  const [erxNotificationCount, setErxNotificationCount] =
    useState(erxNotifications)
  const [datadogIsInitialized, setDatadogIsInitialized] = useState(false)
  const datadogIsInitializedRef = useRef(false)
  const [shouldReload, setShouldReload] = useState(false)
  const {
    maintenanceMode,
    appVersion,
    datadogFrontendMonitoring,
    datadogMonitoringSampleRate,
    datadogMonitoringSessionReplaySampleRate,
    datadogSessionReplays,
    versionReloadEnabled,
  } = useFeatureFlags()
  const config = globalConfig.get()

  // check local storage for these items which will be set in the getErxData use effect
  const erxMessagesCount = localStorage.getItem('erxMessagesCount')
  const erxReportsCount = localStorage.getItem('erxReportsCount')
  const identifyUser = useCallback(
    async (providerData) => {
      try {
        if (isAuthenticated && Object.keys(providerData || {}).length) {
          const { id: userId } = await getCognitoUser()
          identifyFlagUser({
            client: ldClient,
            user: {
              kind: 'user',
              name: providerData.providerName,
              email: providerData.providerEmail,
              userId,
              clinicId: providerData.providerId,
              // Allows "before" / "after" rules targeting new / old clinics
              createdAt: providerData.clinicData?.CreatedAt,
            },
          })
          setUserIsIdentified(true)
        }
      } catch (err) {
        console.error(`Failed to identify flag user due to error: ${err}`)
        Sentry.captureException(err)
      }
    },
    [isAuthenticated]
  )

  const datadogInit = useCallback(() => {
    /*
      We need to use a ref instead of the useState value because of closure
      issues relating to timeouts. Timeouts do not get the latest value of state,
      but will get the latest value of a ref.
    */
    if (datadogIsInitializedRef.current) {
      return
    }

    datadogRum.init({
      applicationId: config.datadog.applicationId,
      clientToken: config.datadog.clientToken,
      site: config.datadog.site,
      service: config.datadog.service,
      allowedTracingOrigins: ['localhost', /https:\/\/.*\.osmind\.org/],
      version: config.version,
      env: config.ENV,
      sessionSampleRate: datadogMonitoringSampleRate,
      sessionReplaySampleRate: datadogMonitoringSessionReplaySampleRate,
      trackInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      trackFrustrations: true,
    })
    setDatadogIsInitialized(true)
    datadogIsInitializedRef.current = true

    if (datadogSessionReplays) {
      datadogRum.startSessionReplayRecording()
    }
  }, [
    datadogMonitoringSessionReplaySampleRate,
    datadogMonitoringSampleRate,
    datadogSessionReplays,
  ])

  useEffect(() => {
    if (
      datadogFrontendMonitoring &&
      datadogMonitoringSampleRate &&
      !isEmpty(providerData) &&
      providerData.providerEmail !== 'lostandfound@osmind.org' &&
      providerData.providerEmail !== 'testing@osmind.org' &&
      isAuthenticated &&
      userIsIdentified
    ) {
      if (datadogInitializationTimeout) {
        clearTimeout(datadogInitializationTimeout)
        datadogInit()
      } else {
        /*
          We have to utilize a timeout because there is an issue with anonymous users in Launch Darkly taking up our
          Monthly active user allowance. Since we set a default user for anonymous users it renders default values for
          Datadog which get initialized before the target values come in for session replay. This stops the initial value
          from being used in Datadog if we are targeting the specific user.
        */
        setDatadogInitializationTimeout(
          setTimeout(() => {
            datadogInit()
          }, 3000)
        )
      }
    }
  }, [
    datadogFrontendMonitoring,
    datadogMonitoringSampleRate,
    isAuthenticated,
    providerData.providerEmail,
    userIsIdentified,
    datadogInit,
  ])

  useEffect(() => {
    /*
      We need to use a ref instead of the useState value because of closure issues relating to timeouts.
      Timeouts do not get the latest value of state, but will get the latest value of a ref.
      We can simplify this once we turn on session replay for everyone
    */
    if (datadogIsInitialized && isAuthenticated && !isEmpty(providerData)) {
      datadogRum.setUser({
        id: providerData.providerId,
        name: providerData.providerName,
        email: providerData.providerEmail,
      })
    }
  }, [isAuthenticated, datadogIsInitialized, providerData])

  useEffect(() => {
    if (maintenanceMode) {
      history.push('/maintenance')
    } else if (location.pathname === '/maintenance') {
      // TODO: When we upgrade to React Router 6 we need to utilize location.key !== 'default'.
      if (location.key) {
        history.goBack()
      } else {
        // If the user comes from outside of providers.osmind.org (in the case they have maintenance mode cached and is their first url when typing in osmind.org) it will redirect them to our main page.
        history.push('/')
      }
    }
  }, [maintenanceMode])

  const { versionChanged } = useAppVersionCheck({
    versionReloadEnabled: versionReloadEnabled,
    latestAppVersion: appVersion,
    currentAppVersion: config.version,
  })

  useEffect(() => {
    if (versionChanged) {
      setShouldReload(true)
    }
  }, [versionChanged])

  /**
   * This useEffect mutates the state that it's watching,
   *   therefore it triggers itself
   * When it runs, it may run an async operation
   *
   * - This is not great for user experience (but probably Ok-enough)
   * - It makes testing difficult
   *
   * Idea: this could be cleaned up by use useReducer to update state
   * - Note: the async fetching would still need to happen in a useEffect
   *   since useReducer doesn't handle async updates
   */
  useEffect(() => {
    async function getAndSetProviderSettingsData() {
      try {
        const [providerData, allTeam] = await Promise.all([
          loadProviderInfo(),
          getTeammateData(),
        ])

        if (!providerData) {
          Sentry.captureException(new Error('Provider info is empty'))
          throw new Error('No provider info available')
        }
        setTeammates(allTeam)
        setFreshpaintUserAndGroupPropertiesWrapper(providerData)
        setProviderData(providerData)
        setLoggedInUserCognitoId(providerData.loggedInProviderId)
        setSettingsData(providerData.appSettings)
        identifyUser(providerData)
        // erxNotications here are from local storage (it's the old count)
        let notificationsCount = erxNotifications
        const isErxEnabled = !!providerData.appSettings.erx
        // if the provider is eRx enabled, get notification count data from Dr First api
        if (isErxEnabled && !providerData.lastErxNotificationCall) {
          // provider is erx enabled but has not yet made their first call to get notification count
          // the getErxNotificationCount function on the backend will add/update the lastErxNotificationCall property in our db
          const { messagesCount, reportsCount, totalCount } =
            await getErxNotificationCount()
          // use local storage the save the notication count numbers so that they appear throughout the application
          // have a total count to show on main homepage and a distributed count between two types of notifications to show on ErxNotifications accordions
          localStorage.setItem('erxNotificationCount', totalCount)
          localStorage.setItem('erxMessagesCount', messagesCount)
          localStorage.setItem('erxReportsCount', reportsCount)

          notificationsCount = totalCount
        } else if (isErxEnabled && providerData.lastErxNotificationCall) {
          // check if it's been at least 30 minutes since last get notification count call, if so make another call which will update providers timestamp
          // Dr First requires 15 mins or more between calls
          const lastNotificationCall = moment.tz(
            providerData.lastErxNotificationCall,
            'MM/DD/YYYY HH:mm:ss',
            'America/New_York'
          )
          const minutesPassed = moment
            .tz('America/New_York')
            .diff(lastNotificationCall, 'minutes')
          if (minutesPassed > 30) {
            const { totalCount, messagesCount, reportsCount } =
              await getErxNotificationCount()
            localStorage.setItem('erxNotificationCount', totalCount)
            localStorage.setItem('erxMessagesCount', messagesCount)
            localStorage.setItem('erxReportsCount', reportsCount)

            notificationsCount = totalCount
          }
        } else if (!isErxEnabled) {
          localStorage.setItem('erxNotificationCount', 0)
          localStorage.setItem('erxMessagesCount', 0)
          localStorage.setItem('erxReportsCount', 0)

          notificationsCount = 0
        }
        setErxNotificationCount(notificationsCount)
        return providerData
      } catch (e) {
        onError(
          e,
          500,
          'There was an internal error processing your request. Please inform your administrator.'
        )
        throw e
      }
    }

    async function onLoad() {
      try {
        setIsLoading(true)
        await Auth.currentSession()
        await getAndSetProviderSettingsData()

        userHasAuthenticated(true)
      } catch (e) {
        if (e !== 'No current user') {
          onError(
            e,
            401,
            'Invalid Authorization. Please reload the page or contact your administrator.'
          )
        } else {
          console.error('error when loading app', e)
        }
      } finally {
        setIsLoading(false)
        setIsAuthenticating(false)
      }
    }

    if (!isAuthenticated || isAuthenticating) {
      onLoad()
    }
  }, [isAuthenticated, isAuthenticating])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      trackViewportEvent(ViewportEvents.VIEWPORT_INITIALIZED, {
        viewportWidth: window.innerWidth,
        viewportHeight: window.innerHeight,
      })

      const debouncedResizeHandler = debounce(() => {
        trackViewportEvent(ViewportEvents.VIEWPORT_RESIZED, {
          viewportWidth: window.innerWidth,
          viewportHeight: window.innerHeight,
        })
      }, 2000)

      window.addEventListener('resize', debouncedResizeHandler)

      return () => {
        window.removeEventListener('resize', debouncedResizeHandler)
        debouncedResizeHandler.cancel()
      }
    }
  }, [])

  async function updateProviderData() {
    const providerData = await loadProviderInfo()
    setProviderData(providerData)
  }

  const fullPath = location.pathname.slice(1)
  const mainPath = fullPath.includes('/')
    ? fullPath.slice(0, fullPath.indexOf('/'))
    : fullPath
  const pathHasSidebar = Object.values(RoutesWithSidebar).some(
    (routesWithSidebar) => mainPath === routesWithSidebar
  )
  const pathDisabledNavbar = Object.values(RoutesWithoutNavbar).some(
    (routeWithoutNavbar) => mainPath === routeWithoutNavbar
  )
  const isPatientIntake = mainPath === RoutesWithoutNavbar.FORM
  const contentId =
    mainPath === 'scheduling' ? 'app-container-scheduling' : undefined

  const onLogoutCallback = () => {
    userHasAuthenticated(false)
    setProviderData({})
  }

  const sendbirdProviderEventHandlers = useMemo(
    () => getSendbirdProviderEventHandlers(providerData?.providerId),
    [providerData.providerId]
  )

  if (isLoading) {
    return (
      <Layout style={{ height: '100%' }}>
        {isAuthenticated && !pathDisabledNavbar && (
          <VerticalMainNavigation
            notificationCount={erxNotificationCount}
            onLogoutCallback={onLogoutCallback}
          />
        )}
        <Content className="loading-content d-flex justify-content-center">
          <Spinner />
        </Content>
      </Layout>
    )
  }

  return (
    !isAuthenticating && (
      <>
        <AppContext.Provider
          value={{
            isAuthenticated,
            userHasAuthenticated,
            setIsAuthenticating,
            appSettings,
            shouldReload,
            clinicId: providerData.providerId ?? '',
            providerId: providerData.loggedInProviderId ?? '',
          }}
        >
          <ErrorBoundary>
            {/*
              Does everyone have secureMessaging turned on?
              If not, we should conditionally render all the Sendbird stuff,
                especially this SendbirdProvider
             */}
            <SendBirdProvider
              appId={defaultConfig.sendbird.APP_ID}
              userId={providerData?.providerId ?? ''}
              eventHandlers={sendbirdProviderEventHandlers}
            >
              <SendbirdConnectionHandlerProvider>
                <UnreadMessageCountsProvider
                  isSecureMessagingEnabled={appSettings?.secureMessaging}
                  /**
                   * MAPPING CLINIC ID TO sendbirdUserId
                   * - note that "Clinic ID" is called `providerId`
                   */
                  sendbirdUserId={providerData.providerId}
                >
                  <Layout id="parent-layout">
                    {isAuthenticated &&
                      !maintenanceMode &&
                      !pathDisabledNavbar && (
                        <VerticalMainNavigation
                          notificationCount={erxNotificationCount}
                          providerName={providerData.providerName}
                          onLogoutCallback={onLogoutCallback}
                        />
                      )}
                    <Layout
                      className={
                        mainPath === 'patient' ? 'patient-identifier' : ''
                      }
                      style={{ backgroundColor: 'white', overflow: 'clip' }}
                    >
                      {pathHasSidebar && (
                        <NavigationSideBar
                          isCollapsed={!isSideBarVisible}
                          toggleCollapse={() =>
                            setIsSideBarVisible(!isSideBarVisible)
                          }
                          title="HOME"
                          path={mainPath}
                          healthGorillaUserName={healthGorillaUserName}
                        />
                      )}
                      {mainPath === 'patient' && (
                        <PatientProfilePanel providerData={providerData} />
                      )}
                      <Content
                        id={contentId}
                        className={[
                          'app-container',
                          pathDisabledNavbar ? styles.fullPageNoNavbar : '',
                          isPatientIntake ? styles.patientIntakePage : '',
                        ]}
                      >
                        <Routes
                          homepageData={providerData}
                          erxMessagesCount={Number(erxMessagesCount)}
                          erxNotificationCount={Number(erxNotificationCount)}
                          erxReportsCount={Number(erxReportsCount)}
                          updateProviderData={updateProviderData}
                          teammates={teammates}
                          loggedInUserCognitoId={loggedInUserCognitoId}
                          setHealthGorillaUserNameApp={setHealthGorillaUserName}
                        />
                      </Content>
                    </Layout>
                  </Layout>
                </UnreadMessageCountsProvider>
              </SendbirdConnectionHandlerProvider>
            </SendBirdProvider>
          </ErrorBoundary>
        </AppContext.Provider>
        <ToastContainer
          position="top-left"
          autoClose={120000}
          closeButton
          limit={3}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </>
    )
  )
}

export default FeatureFlagConsumer(App)
