import { useMemo } from 'react'

import cx from 'classnames'
import moment from 'moment/moment'

import { useFeatureFlags } from '../../../../../libs/featureFlags'
import { Location, Teammate } from '../../../../../shared-types'
import {
  convertTime24to12,
  convertTime24toISO,
  convertTimeISOto24,
  getTimeFromISO,
} from '../../../../../shared/Helpers/utils'
import { InlineEditSelect, Link } from '../../../../../stories/BaseComponents'
import InlineEditDate from '../../../../../stories/BaseComponents/InlineEditFields/InlineEditDate'
import InlineEditTime from '../../../../../stories/BaseComponents/InlineEditFields/InlineEditTime'
import { Note } from '../../../types'
import { CoreData } from './CoreData'

import styles from './NoteCoreData.module.scss'

interface NoteCoreDataProps {
  note: Note
  onChange: (val: Partial<Note>) => void
  teamOptions: Array<Teammate>
  locationOptions: Array<Location>
  isLoading: boolean
  isSigned?: boolean
}

export const NoteCoreData = ({
  note,
  onChange,
  teamOptions,
  locationOptions,
  isLoading,
  isSigned = false,
}: NoteCoreDataProps) => {
  const { claims } = useFeatureFlags()
  const startTimeParts = useMemo(() => {
    return getTimeFromISO(note?.startTime ?? null)
  }, [note?.startTime])

  const generateTimeText = () => {
    const { hours, minutes, modifier } = convertTime24to12(
      convertTimeISOto24(note?.startTime ?? null)
    )
    const timezone = !note?.startTime
      ? ''
      : new Date(note?.startTime)
          .toLocaleString('en', { timeZoneName: 'short' })
          .split(' ')
          .pop()

    return `${hours}:${minutes} ${modifier} ${timezone}`
  }

  return (
    <CoreData
      isLoading={isLoading}
      fields={{
        patient: {
          type: 'readonly',
          readonlyValue: note.patientName,
        },
        dateOfVisit: {
          type: isSigned ? 'readonly' : 'dynamic',
          readonlyValue: moment(note?.appointmentDate).format('MM/DD/YYYY'),
          dynamicValue: (
            <InlineEditDate
              isLocal
              value={moment(note?.appointmentDate).format('MM/DD/YYYY')}
              onSave={(value) => {
                const change: Partial<Note> = {}
                const appointmentDate = new Date(value)
                // cast to noon so that most UTC offsets will be accounted for
                // TODO: save this as a string instead, since it's not meant to change across timezones
                // https://osmind.atlassian.net/browse/CC-2438
                appointmentDate.setHours(12)
                // Also correct the start datetime
                change.appointmentDate = appointmentDate.toISOString()
                if (startTimeParts) {
                  const startDatetime = new Date(appointmentDate)
                  startDatetime.setHours(
                    startTimeParts.hours,
                    startTimeParts.minutes
                  )
                  change.startTime = startDatetime.toISOString()
                }
                onChange(change)
              }}
              className={cx(styles.rightPadding)}
              padded={true}
              after={moment().add(100, 'year')}
            />
          ),
        },
        startTime: {
          type: isSigned ? 'readonly' : 'dynamic',
          readonlyValue: generateTimeText(),
          dynamicValue: (
            <InlineEditTime
              value={note?.startTime}
              onSave={(value: string) => {
                const iso = convertTime24toISO(note?.appointmentDate, value)
                onChange({ startTime: iso })
              }}
              className={cx(styles.rightPadding)}
              padded={true}
              placeholder="Select"
            />
          ),
        },
        location: {
          type: isSigned ? 'readonly' : 'dynamic',
          readonlyValue: note.locationName,
          dynamicValue:
            locationOptions.length === 0 ? (
              <div>
                No locations found •&nbsp;
                <Link to={'/settings/location'} target="_blank">
                  Add one
                </Link>
              </div>
            ) : (
              <InlineEditSelect
                value={note?.locationId ?? undefined}
                options={locationOptions?.map((location: Location) => {
                  return {
                    value: location.Uuid ?? '',
                    label: location.LocationName ?? '',
                  }
                })}
                className={cx(styles.rightPadding)}
                padded={true}
                onSave={(value) => {
                  onChange({
                    locationId: value,
                  })
                }}
                placeholder="Select"
              />
            ),
        },
        renderingProvider: {
          type: isSigned ? 'readonly' : 'dynamic',
          readonlyValue: note.renderingProviderName,
          dynamicValue: (
            <InlineEditSelect
              value={note?.renderingProviderId ?? undefined}
              options={teamOptions?.map((team: any) => {
                if (team.isDeactivated) {
                  return {
                    value: team.cognitoId,
                    label: team.name,
                    disabled: true,
                  }
                } else {
                  return { value: team.cognitoId, label: team.name }
                }
              })}
              onSave={(value) => {
                onChange({
                  renderingProviderId: value,
                })
              }}
              className={cx(styles.rightPadding)}
              padded={true}
              placeholder="Select"
            />
          ),
        },
        ...(claims && {
          billingType: {
            type: isSigned ? 'readonly' : 'dynamic',
            readonlyValue: note.billingType ?? '',
            dynamicValue: (
              <InlineEditSelect
                value={note?.billingType ?? undefined}
                options={[
                  { value: 'Select', label: 'Select' },
                  { value: 'In-network', label: 'In-network' },
                  { value: 'Private pay', label: 'Private pay' },
                ]}
                onSave={(value) => {
                  onChange({
                    billingType: value === 'Select' ? null : value,
                  })
                }}
                className={cx(styles.rightPadding)}
                padded={true}
                placeholder="Select"
              />
            ),
          },
        }),
      }}
    />
  )
}
