import { Sex, TAX_TYPE } from './types'

export const LEGAL_SEX_OPTIONS = [
  {
    value: Sex.F,
    label: 'F',
  },
  {
    value: Sex.M,
    label: 'M',
  },
  {
    value: Sex.X,
    label: 'X',
  },
  {
    value: Sex.Unknown,
    label: 'Unknown',
  },
]

export const CLAIM_FILING_CODES = {
  BLUE_CROSS_BLUE_SHIELD: 'BL',
  DISABILITY: 'DS',
  COMMERCIAL_HMO: 'CI',
  MEDICARE_A: 'MA',
  MEDICARE_B: 'MB',
  MEDICAID: 'MC',
  OTHER_NON_FEDERAL_PROGRAM: '11',
  OTHER_FEDERAL_PROGRAM: 'OF',
  TRICARE_CHAMPUS: 'CH',
  VETERANS_AFFAIRS_PLAN: 'VA',
  WORKERS_COMPENSATION: 'WC',
  MUTUALLY_DEFINED: 'ZZ',
}

// map Payers.claim_insurance_type to a subset of the claimFilingCode
export const CLAIM_INSURANCE_TYPE_TO_FILING_CODE: { [index: string]: string } =
  {
    'Commercial/HMO': CLAIM_FILING_CODES.COMMERCIAL_HMO,
    Medicare: CLAIM_FILING_CODES.MEDICARE_B,
    'Blue Cross/Blue Shield': CLAIM_FILING_CODES.BLUE_CROSS_BLUE_SHIELD,
    'Workers Compensation': CLAIM_FILING_CODES.WORKERS_COMPENSATION,
    Medicaid: CLAIM_FILING_CODES.MEDICAID,
    'TRICARE/CHAMPUS': CLAIM_FILING_CODES.TRICARE_CHAMPUS,
  }

export const CLAIM_FILING_CODE_OPTIONS = [
  {
    label: 'Blue Cross/Blue Shield',
    value: CLAIM_FILING_CODES.BLUE_CROSS_BLUE_SHIELD,
  },
  {
    label: 'Disability',
    value: CLAIM_FILING_CODES.DISABILITY,
  },
  {
    label: 'Commercial/HMO',
    value: CLAIM_FILING_CODES.COMMERCIAL_HMO,
  },
  {
    label: 'Medicare Part A',
    value: CLAIM_FILING_CODES.MEDICARE_A,
  },
  {
    label: 'Medicare Part B',
    value: CLAIM_FILING_CODES.MEDICARE_B,
  },
  {
    label: 'Medicaid',
    value: CLAIM_FILING_CODES.MEDICAID,
  },
  {
    label: 'Other non-federal program',
    value: CLAIM_FILING_CODES.OTHER_NON_FEDERAL_PROGRAM,
  },
  {
    label: 'Other federal program',
    value: CLAIM_FILING_CODES.OTHER_FEDERAL_PROGRAM,
  },
  {
    label: 'Tricare/Champus',
    value: CLAIM_FILING_CODES.TRICARE_CHAMPUS,
  },
  {
    label: 'Veterans Affairs Plan',
    value: CLAIM_FILING_CODES.VETERANS_AFFAIRS_PLAN,
  },
  {
    label: "Worker's Compensation Health Claim",
    value: CLAIM_FILING_CODES.WORKERS_COMPENSATION,
  },
  {
    label: 'Mutually Defined',
    value: CLAIM_FILING_CODES.MUTUALLY_DEFINED,
  },
]

export const MEDICARE_CLAIM_FILING_CODES = [
  CLAIM_FILING_CODES.MEDICARE_A,
  CLAIM_FILING_CODES.MEDICARE_B,
]
// insurance type code is only needed if secondary insurance is medicare
export const DEFAULT_INSURANCE_TYPE_CODE = '12'
export const INSURANCE_TYPE_CODE_OPTIONS = [
  {
    label: `'12' Medicare Secondary Working Aged Beneficiary or Spouse with Employer Group Health Plan`,
    value: '12',
  },
  {
    label: `'13' Medicare Secondary End-Stage Renal Disease Beneficiary in the Mandated Coordination Period`,
    value: '13',
  },
  {
    label: `'14' Medicare Secondary, No-fault Insurance including Auto is Primary`,
    value: '14',
  },
  {
    label: `'15' Medicare Secondary Worker's Compensation`,
    value: '15',
  },
  {
    label: `'16' Medicare Secondary Public Health Service (PHS) or Other Federal Agency`,
    value: '16',
  },
  {
    label: `'41' Medicare Secondary Black Lung`,
    value: '41',
  },
  {
    label: `'42' Medicare Secondary Veteran's Administration`,
    value: '42',
  },
  {
    label: `'43' Medicare Secondary Disabled Beneficiary Under Age 65 with Large Group Health Plan (LGHP)`,
    value: '43',
  },
  {
    label: `'47' Medicare Secondary, Other Liability Insurance is Primary`,
    value: '47',
  },
]

export const BOOL_OPTIONS = [
  {
    value: 'yes',
    label: 'Yes',
  },
  {
    value: 'no',
    label: 'No',
  },
]

export const RELATIONSHIP = {
  SELF: 'Self',
  CHILD: 'Child',
  SPOUSE: 'Spouse',
  PARENT: 'Parent',
  GRANDPARENT: 'Grandparent',
  SIBLING: 'Sibling',
  PARTNER: 'Partner',
  OTHER: 'Other',
}

export const RELATIONSHIP_OPTIONS = [
  {
    value: RELATIONSHIP.SELF,
    label: 'Self',
  },
  {
    value: RELATIONSHIP.CHILD,
    label: 'Child',
  },
  {
    value: RELATIONSHIP.GRANDPARENT,
    label: 'Grandparent',
  },
  {
    value: RELATIONSHIP.PARENT,
    label: 'Parent',
  },
  {
    value: RELATIONSHIP.PARTNER,
    label: 'Partner',
  },
  {
    value: RELATIONSHIP.SIBLING,
    label: 'Sibling',
  },
  {
    value: RELATIONSHIP.SPOUSE,
    label: 'Spouse',
  },
  {
    value: RELATIONSHIP.OTHER,
    label: 'Other',
  },
]

export const TAX_TYPE_OPTIONS = [
  {
    value: TAX_TYPE.EIN,
    label: 'EIN',
  },
  {
    value: TAX_TYPE.SSN,
    label: 'SSN',
  },
]
