import Icon from '@ant-design/icons'
import { Button } from 'antd'

import { generateCoreIdDisplayName } from '../../../../../../../hooks/usePatientProfile/utils'
import { useProfileUrlParams } from '../../../../../../../hooks/useProfileUrlParams'
import { useAppContext } from '../../../../../../../libs/contextLib'
import { ActiveMedicationHistory } from '../../../../../../../shared-types'
import { ExternalLink } from '../../../../Icons/ExternalLink'
import { DynamicBlockRenderOptions } from '../../../Core/Block/DynamicBlockTypes'
import { DynamicBlockHeader } from '../shared/TableHeader'
import { MedicationsBlockTable } from './MedicationsBlockTable'

export const MedicationsBlock = ({
  block,
  note,
  onBlockUpdate,
  isRefreshing,
}: DynamicBlockRenderOptions) => {
  const { appSettings } = useAppContext() as unknown as {
    appSettings?: { erx: boolean }
  }

  const { urlParams } = useProfileUrlParams()
  const options = {
    isDrFirst: !!appSettings?.erx,
    urlParams,
  }

  const handleBlockRefresh = async () => {
    await onBlockUpdate({
      blockConfig: {
        options: {
          limit: Date.now(),
        },
      },
      blockUuid: block.uuid,
      noteUuid: note.uuid,
    })
  }

  const actionButtons = [
    <Button
      key={`${block.uuid}_add`}
      data-testid={`${block.uuid}_add`}
      icon={<Icon component={() => <ExternalLink />} />}
      onClick={() => {
        if (!options || !options.urlParams) return
        window.open(
          `/patient/medication?${options.urlParams}`,
          '_blank',
          'noreferrer'
        )
      }}
    >
      {options.isDrFirst ? 'Edit medications in Dr. First' : 'Edit medications'}
    </Button>,
  ]

  return (
    <>
      <DynamicBlockHeader
        title={'Active Medications'}
        buttons={actionButtons}
        isSigned={!!note?.firstSignedAt}
        blockType="MEDICATIONS"
        blockUuid={block.uuid}
        onBlockRefresh={handleBlockRefresh}
      />
      <div>
        <MedicationsBlockTable
          isSigned={!!note?.firstSignedAt}
          patientName={
            note?.patient && generateCoreIdDisplayName(note?.patient)
          }
          isDrFirst={options.isDrFirst}
          blockData={block.data as ActiveMedicationHistory[]}
          isLoading={isRefreshing}
        />
      </div>
    </>
  )
}
