import { useState } from 'react'

import Icon from '@ant-design/icons'
import { Button } from 'antd'

import { DiagnosisModal } from '../../../../../../../components/Forms/DiagnosisModal'
import { DiagnosisOptions } from '../../../../../../../components/Forms/DiagnosisModal/DiagnosisModal'
import { usePatientDemographics } from '../../../../../../../hooks/usePatientInfo'
import { generateCoreIdDisplayName } from '../../../../../../../hooks/usePatientProfile/utils'
import { DiagnosisHistory } from '../../../../../../../shared-types'
import { useAddDiagnosis } from '../../../../../hooks/blocks/diagnoses/useAddDiagnosis'
import { ExternalLink } from '../../../../Icons/ExternalLink'
import { DynamicBlockRenderOptions } from '../../../Core/Block/DynamicBlockTypes'
import { DynamicBlockHeader } from '../shared/TableHeader'
import { DiagnosesBlockTable } from './DiagnosesBlockTable'
import { EditDiagnosisModal } from './EditDiagnosisModal'

export const DiagnosesBlock = ({
  block,
  note,
  onBlockUpdate,
  isRefreshing,
}: DynamicBlockRenderOptions) => {
  const [showNewDiagnosis, setShowNewDiagnosis] = useState(false)
  const [currentDxId, setCurrentDxId] = useState<string | null>(null)

  const { addDiagnosis } = useAddDiagnosis({
    patientId: note.patientId,
  })

  const { isLoading: patientIsLoading, data: patientData } =
    usePatientDemographics(note.patientId)

  const handleBlockRefresh = async () => {
    await onBlockUpdate({
      blockConfig: {
        options: {
          limit: Date.now() + 2000, // TODO: CC-2755 - remove the 2s pad when history tables inherit updated_at from entity
        },
      },
      blockUuid: block.uuid,
      noteUuid: note.uuid,
    })
  }

  const handleSubmitNewDiagnosis = async (newDiagnosis: DiagnosisOptions) => {
    if (!patientData) return
    await addDiagnosis({
      dx: newDiagnosis,
      rCopiaId: patientData.PatientRCopiaId,
    })
    await handleBlockRefresh()
    setShowNewDiagnosis(false)
  }

  const actionButtons = [
    <Button
      icon={<Icon component={() => <ExternalLink />} />}
      key={`${block.uuid}_add`}
      disabled={patientIsLoading}
      data-testid={`${block.uuid}_add`}
      onClick={() => {
        setShowNewDiagnosis(true)
      }}
    >
      Add active diagnosis
    </Button>,
  ]

  return (
    <>
      <DiagnosisModal
        title="Add new diagnosis"
        isVisible={showNewDiagnosis}
        onCancel={() => setShowNewDiagnosis(false)}
        onAddDiagnosis={handleSubmitNewDiagnosis}
      />
      <EditDiagnosisModal
        patientId={note.patientId}
        diagnosisId={currentDxId ?? null}
        setDiagnosisId={setCurrentDxId}
        onUpdate={handleBlockRefresh}
      />

      <DynamicBlockHeader
        title={'Active Diagnoses'}
        buttons={actionButtons}
        isSigned={!!note?.firstSignedAt}
        blockUuid={block.uuid}
        onBlockRefresh={handleBlockRefresh}
        blockType="DIAGNOSIS"
      />

      <div>
        <DiagnosesBlockTable
          onRowEdit={setCurrentDxId}
          isSigned={!!note?.firstSignedAt}
          blockData={
            block.data ? (block.data as DiagnosisHistory[]) : undefined
          }
          patientName={
            note?.patient && generateCoreIdDisplayName(note?.patient)
          }
          isLoading={isRefreshing}
        />
      </div>
    </>
  )
}
