import React from 'react'

import { Card } from '../../../../stories/BaseComponents'
import Provider from '../Provider'

import styles from './shared.module.scss'

export const testIds = {
  container: 'claim-rendering-provider',
}

const RenderingProvider: React.FC = () => (
  <Card
    testId={testIds.container}
    style={{ marginTop: 24 }}
    bodyStyle={{ paddingBottom: 16 }}
  >
    <div className={styles.section}>
      <div className={styles.header}>
        <span className={styles.title}>{'Rendering provider'}</span>
      </div>
      <Provider providerType="renderingProvider" />
    </div>
  </Card>
)

export default RenderingProvider
