import { ReactElement } from 'react'

export type TiptapExtension = {
  options: {
    removeBlock: (name: string, id: number | string) => void
  }
}

export type TiptapNode = {
  type: { name: string }
  attrs: { id: string | number }
}

export enum BlockPermissions {
  PUBLIC = 'PUBLIC',
  PREPROD = 'PREPROD',
  FEATURE_FLAG = 'FEATURE_FLAG',
}

export type CustomBlockNames =
  | 'ActiveMedications'
  | 'ActiveDiagnoses'
  | 'ActiveAllergies'
  | 'DynamicBlock'
  | 'TestBlock'

export const CUSTOM_BLOCK_NAME_SET: Set<CustomBlockNames> = new Set([
  'ActiveMedications',
  'ActiveDiagnoses',
  'ActiveAllergies',
  'DynamicBlock',
  'TestBlock',
])

export type StructuredBlockProps<T> = {
  options?: T
  isSigned?: boolean
}

// Opportunity here to template this type to allow for more specific block config types
export type BlockConfig = {
  /**
   * same one used in the database if it uses a table
   * 7/9/2024: Unsure if this ^^^ comment is still accurate; Currently, it looks like the 'name' is
   * used as both a 'key' when rendering block types, and the 'type' of the block.
   */
  name: CustomBlockNames
  /**
   * Label to display in the insert dropdown.
   */
  label: string
  /**
   * HTML tag, eg 'test-block' will appear in the DOM as <test-block></test-block>
   */
  tag: string

  /**
   * Icon to display in the block's optional button
   */
  actionIcon?: ReactElement
  /**
   * Label to display in the block's optional button
   * @param options Key/Value pairs used by the block to perform dynamic logic.
   */
  actionLabel?: ({
    options,
  }: {
    options?: Record<string, any>
  }) => string | ReactElement
  /**
   * Function to call when the block's optional button is clicked
   * @param options Key/Value pairs used by the block to perform dynamic logic.
   */
  onActionClick?: ({ options }: { options?: Record<string, any> }) => void

  header: string
  /**
   * The body of the block.
   */
  content: (options?: Record<string, any>, isSigned?: boolean) => ReactElement
  permissions: BlockPermissions
  /**
   * Custom hook to be used in the BlockHeader and BlockContent in order to display relevant info
   */
  useOptions?: () => Record<string, any>
}
