import React, { useState } from 'react'

import { ArrowLeftOutlined, DownOutlined } from '@ant-design/icons'
import { Dropdown, Modal } from 'antd'
import { format } from 'date-fns'

import { ClaimDetails } from '../../../hooks/usePatientProfile/shared-types'
import { CLAIM_STATUS } from '../../../shared-types'
import {
  Button,
  Menu,
  Dropdown as OsmoDropdown,
} from '../../../stories/BaseComponents'
import { MenuItem } from '../../../stories/BaseComponents/Menu'
import ClaimStatusDisplay from '../ClaimsV2/ClaimStatusDisplay'
import ManualStatusUpdateModal from './ManualStatusUpdateModal'

import styles from './InsuranceClaim.module.scss'

const toggleConfirmModal = Modal.confirm

const getSubmittedDateDisplay = (
  claimStatus: CLAIM_STATUS,
  submittedDate?: string | null
) => {
  if ([CLAIM_STATUS.DRAFT, CLAIM_STATUS.CHANGE_ERROR].includes(claimStatus)) {
    return 'Not submitted'
  } else {
    return submittedDate
      ? format(new Date(submittedDate), 'MM/dd/yyyy')
      : 'Unknown'
  }
}

export interface ClaimHeaderProps {
  handleCancelClaim: (...args: unknown[]) => unknown
  handleUpdateStatus: Function
  handleDiscard: Function
  handleSaveAsDraft: Function
  handleDelete: Function
  claimDetails: ClaimDetails
  saveLoading: boolean
  setEditPayments: Function
  requireModal: boolean
  editPayments: boolean
  claimStatus: CLAIM_STATUS
  unsavedDraft: boolean
  saveSubmitLoading: boolean
  disabled?: boolean
  deletionDisabled?: boolean
  claimId?: string
  patientId?: string
}

const ClaimHeader: React.FC<ClaimHeaderProps> = ({
  handleCancelClaim,
  handleUpdateStatus,
  handleDiscard,
  handleSaveAsDraft,
  handleDelete,
  claimDetails,
  saveLoading,
  requireModal,
  claimStatus,
  setEditPayments,
  editPayments,
  unsavedDraft,
  saveSubmitLoading,
  disabled,
  deletionDisabled,
  patientId,
  claimId,
}) => {
  const [showDiscardModal, setShowDiscardModal] = useState<boolean>(false)
  const [showStatusUpdateModal, setShowStatusUpdateModal] =
    useState<boolean>(false)
  const [isDeleting, setIsDeleting] = useState<boolean>(false)
  const [displayStatus, setDisplayStatus] = useState<string>(
    claimDetails.claimStatusUpdate.displayMessage
  )

  const getStatusUpdateDropdown = () => {
    const submitted =
      claimStatus === CLAIM_STATUS.MANUALLY_SUBMITTED ||
      claimStatus === CLAIM_STATUS.SUBMITTED
    return (
      <OsmoDropdown
        items={[
          {
            key: submitted ? 'manually-adjudicated' : 'manually-submitted',
            text: submitted ? 'Adjudicated' : 'Manually submitted',
            onClick: () => {
              setShowStatusUpdateModal(true)
            },
          },
        ]}
        trigger={['click']}
        placement="bottomRight"
      >
        <div
          className={styles.topBarFieldValueDropdown}
          data-testid={'claim-status-update-dropdown'}
        >
          <ClaimStatusDisplay
            testId={'claim-status-update-dropdown-content'}
            displayMessage={displayStatus}
            statusCategory={claimDetails.claimStatusUpdate.statusCategory}
          />
          <DownOutlined style={{ fontSize: '14px', marginLeft: '7px' }} />
        </div>
      </OsmoDropdown>
    )
  }

  const getStatusField = () => {
    if (
      (claimStatus === CLAIM_STATUS.DRAFT ||
        claimStatus === CLAIM_STATUS.MANUALLY_SUBMITTED ||
        claimStatus === CLAIM_STATUS.SUBMITTED) &&
      // Hides the dropdown after the claim gets manually adjudicated
      displayStatus !== 'Adjudicated'
    ) {
      return getStatusUpdateDropdown()
    }
    return (
      <div className={styles.topBarFieldValue}>
        <ClaimStatusDisplay
          testId={'claim-header-status-display-content'}
          displayMessage={displayStatus}
          statusCategory={claimDetails.claimStatusUpdate.statusCategory}
        />
      </div>
    )
  }

  return (
    <>
      <div className={styles.spaceBetween}>
        <div className={styles.mainHeader}>
          <ArrowLeftOutlined
            data-testid={'claim-header-back-button'}
            className={styles.backIcon}
            onClick={() =>
              !requireModal
                ? unsavedDraft
                  ? handleDiscard()
                  : history.back()
                : history.back()
            }
          />
          <ManualStatusUpdateModal
            testId={'manual-claim-status-update-modal'}
            claimStatus={claimStatus}
            handleUpdateStatus={handleUpdateStatus}
            visible={showStatusUpdateModal}
            setVisible={setShowStatusUpdateModal}
            setDisplayStatus={setDisplayStatus}
            patientId={patientId}
            claimId={claimId}
          />
          <Modal
            title="Discard claim?"
            visible={showDiscardModal}
            confirmLoading={false}
            footer={[
              <Button
                data-testid="cancel-discard-button"
                color="#262626"
                onClick={() => setShowDiscardModal(false)}
              >
                Cancel
              </Button>,
              <Button
                data-testid="discard-button"
                type="primary"
                color="#262626"
                loading={isDeleting}
                onClick={() => {
                  try {
                    setIsDeleting(true)
                    handleDelete()
                  } catch (e) {
                    console.error(e)
                  } finally {
                    setIsDeleting(false)
                  }
                }}
              >
                Discard
              </Button>,
            ]}
          >
            <p>
              {
                'Are you sure you want to discard this claim? Discarding will delete this claim draft.'
              }
            </p>
          </Modal>
          <span style={{ marginLeft: 10 }}>{'Create new insurance claim'}</span>
        </div>
        <div className={styles.mainHeaderActions}>
          {claimStatus === undefined || claimStatus === 'DRAFT' ? (
            <>
              <Button
                testId="discard-button"
                type="default"
                disabled={saveLoading || saveSubmitLoading || deletionDisabled}
                color="#262626"
                onClick={() => setShowDiscardModal(true)}
              >
                Delete claim draft
              </Button>
              <Button
                testId="save-as-draft--button"
                type="default"
                color="#262626"
                disabled={saveSubmitLoading || disabled}
                onClick={() => handleSaveAsDraft()}
                loading={saveLoading}
              >
                Save as draft
              </Button>
            </>
          ) : (
            <>
              {claimStatus === CLAIM_STATUS.CHANGE_ERROR && (
                <Button
                  testId="discard-button"
                  type="default"
                  color="#262626"
                  disabled={deletionDisabled}
                  onClick={() => setShowDiscardModal(true)}
                >
                  Delete claim draft
                </Button>
              )}
              <Dropdown
                getPopupContainer={() =>
                  document.getElementById(
                    'edit-claim-element-dropdown'
                  ) as HTMLElement
                }
                overlayClassName={styles.dropdownMenu}
                overlay={
                  <Menu defaultSelectedKeys={[]}>
                    <MenuItem
                      key={`edit-payments-values-claim`}
                      onClick={() => setEditPayments(!editPayments)}
                    >
                      Edit payments values
                    </MenuItem>
                    {claimDetails?.payerControlNumber && (
                      <MenuItem
                        key={`cancel-payments-values-claim`}
                        onClick={() => {
                          toggleConfirmModal({
                            title: 'Cancel/void claim',
                            content: (
                              <>
                                <p>
                                  {
                                    'If you cancel/void this claim the insurance payer will be ask to stop processing this claim.'
                                  }
                                </p>
                                <p>
                                  {
                                    'Note: If the insurance payer has already paid out this claim, you may need to correct any mis-payment that occurs.'
                                  }
                                </p>
                              </>
                            ),
                            okText: 'Cancel claim',
                            cancelText: 'Dismiss',
                            okButtonProps: {
                              style: {
                                backgroundColor: '#FF4D4F',
                                borderWidth: 0,
                              },
                            },
                            onOk: handleCancelClaim,
                          })
                        }}
                      >
                        Cancel / void claim
                      </MenuItem>
                    )}
                  </Menu>
                }
              >
                <Button
                  testId="edit-payments-button-claim-header"
                  className={styles.paymentActionLink}
                  type="default"
                  color="#262626"
                  loading={saveLoading}
                >
                  Edit Claim
                  <i
                    style={{ marginLeft: 5 }}
                    className="fa fa-angle-down fa-lg"
                    aria-hidden="true"
                  />
                </Button>
              </Dropdown>
            </>
          )}
        </div>
      </div>
      <div className={styles.claimTopBarInfo}>
        <div className={styles.firstTopBarField}>
          <span className={styles.topBarFieldName}>Type</span>
          <span className={styles.topBarFieldValue}>{claimDetails.type}</span>
        </div>

        <div className={styles.topBarField}>
          <span className={styles.topBarFieldName}>Patient control number</span>
          <span className={styles.topBarFieldValue}>
            {claimDetails.patientControlNumber || '-'}
          </span>
        </div>

        <div className={styles.topBarField}>
          <span className={styles.topBarFieldName}>Created</span>
          <span className={styles.topBarFieldValue}>
            {claimDetails.created
              ? format(new Date(claimDetails.created), 'MM/dd/yyyy')
              : ''}
          </span>
        </div>

        <div className={styles.topBarField}>
          <span className={styles.topBarFieldName}>Submitted</span>
          <span
            className={styles.topBarFieldValue}
            data-testid={'claim-header-submitted-date'}
          >
            {getSubmittedDateDisplay(claimStatus, claimDetails.submittedDate)}
          </span>
        </div>

        <div
          className={styles.topBarField}
          data-testid={'claim-header-status-display'}
        >
          <span className={styles.topBarFieldName}>Status</span>
          {getStatusField()}
        </div>

        {claimDetails.status === 'CANCELED' && (
          <div className={styles.topBarField}>
            <span className={styles.topBarFieldName}>Canceled at</span>
            <span className={styles.topBarFieldValue}>
              {claimDetails?.canceledAt
                ? format(new Date(claimDetails?.canceledAt), 'MM/dd/yyyy')
                : '-'}
            </span>
          </div>
        )}
      </div>
      {/* This element is used to place dropdown and
          avoid table positions issues.  */}
      <div style={{ position: 'relative' }} id="edit-claim-element-dropdown" />
    </>
  )
}

export default ClaimHeader
