/*
  Map data from claim form to claim data and vice versa
*/
import { format } from 'date-fns'

import { ChangePayersList } from '../../../api/intakeForms'
import { DEFAULT_INSURANCE_TYPE_CODE, RELATIONSHIP } from './constants'
import {
  Address,
  AddressInput,
  AddressUpperCase,
  BoolOptionValue,
  ClaimData,
  ClaimForm,
  ClaimFormProvider,
  Patient,
  PatientInsurance,
  Provider,
} from './types'
import { extractDigits, getClaimFilingCodeForPayer, isMedicare } from './utils'

// date in claim data -> date input value
/* note: this works for fields where time does not matter,
such as date of birth, which is stored as ISO string in UTC by combining the date and an arbitrary time
example:  "1989-04-13T00:00:00.000Z"
*/
export const toDateInputValue = (isoString?: string | null) => {
  if (!isoString) {
    return null
  }
  // remove the timezone ("Z")
  const isoWithoutTZ = isoString.replace('Z', '')
  // attaches local timezone so the date display is always correct regardless of where our user is
  const dateObj = new Date(isoWithoutTZ)
  return format(dateObj, 'MM/dd/yyyy')
}

// address in claim data -> address1 input value
export const toAddressInputValue = (
  addressObj?: AddressUpperCase | Address | null
): AddressInput | null => {
  if (addressObj && 'AddressLine1' in addressObj) {
    return {
      Address1: addressObj?.AddressLine1 ?? null,
      Address2: addressObj?.AddressLine2 ?? null,
      City: addressObj?.City ?? null,
      State: addressObj?.State ?? null,
      Zipcode: addressObj?.ZipCode ?? null,
    }
  }

  if (addressObj && 'addressLine1' in addressObj) {
    return {
      Address1: addressObj?.addressLine1 ?? null,
      Address2: addressObj?.addressLine2 ?? null,
      City: addressObj?.city ?? null,
      State: addressObj?.state ?? null,
      Zipcode: addressObj?.zipCode ?? null,
    }
  }

  return null
}

export const toBoolInputValue = (
  boolFieldValue?: boolean | null
): BoolOptionValue | null => {
  switch (boolFieldValue) {
    case true:
      return 'yes'
    case false:
      return 'no'
    default:
      return null
  }
}

export const toEinInputValue = (ein?: string | null) =>
  ein ? extractDigits(ein) : null

export const toSsnInputValue = (ssnLastFour?: string | null) =>
  ssnLastFour ? `*****${ssnLastFour}` : null

export const toBoolValue = (
  boolInputValue?: BoolOptionValue | null
): boolean | null => {
  switch (boolInputValue) {
    case 'yes':
      return true
    case 'no':
      return false
    default:
      return null
  }
}
// claim data -> form values
// TODO: most of the "?? null" can be removed after implementing #CARE-2254
export const toFormPatient = ({
  firstName = null,
  lastName = null,
  dateOfBirth = null,
  address = null,
  legalSex = null,
}: Patient = {}): ClaimForm['patient'] => ({
  firstName,
  lastName,
  dateOfBirth: toDateInputValue(dateOfBirth),
  address: toAddressInputValue(address),
  address2: address?.AddressLine2 ?? null,
  legalSex,
})

export const toFormPrimaryInsurance = ({
  name = null,
  claimFilingCode = null,
  memberId = null,
  groupId = null,
  isInformationReleaseAuthorized = null,
  isPaymentAuthorized = null,
  isSubscriber = null,
  subscriberRelationship = null,
  subscriberFirstName = null,
  subscriberLastName = null,
  subscriberDateOfBirth = null,
  subscriberLegalSex = null,
  subscriberEmail = null,
  subscriberPhoneNumber = null,
  subscriberAddress = null,
}: PatientInsurance = {}): ClaimForm['primaryInsurance'] => ({
  name,
  claimFilingCode,
  memberId,
  groupId,
  informationReleaseAuthorized: toBoolInputValue(
    isInformationReleaseAuthorized
  ),
  paymentAuthorized: toBoolInputValue(isPaymentAuthorized),
  subscriberRelationship: isSubscriber
    ? RELATIONSHIP.SELF
    : subscriberRelationship,
  subscriberFirstName,
  subscriberLastName,
  subscriberDateOfBirth: toDateInputValue(subscriberDateOfBirth),
  subscriberLegalSex,
  subscriberEmail,
  subscriberPhoneNumber,
  subscriberAddressSameAsPatient: subscriberAddress?.addressLine1
    ? 'no'
    : 'yes',
  subscriberAddress: toAddressInputValue(subscriberAddress),
  subscriberAddress2: subscriberAddress?.addressLine2 ?? null,
})

export const toFormSecondaryInsurance = ({
  isIncluded = null, // Need to discuss what this actually represents with with BE
  name = null,
  claimFilingCode = null,
  insuranceTypeCode = null,
  memberId = null,
  groupId = null,
  isInformationReleaseAuthorized = null,
  isPaymentAuthorized = null,
  isSubscriber = null,
  subscriberRelationship = null,
  subscriberFirstName = null,
  subscriberLastName = null,
  subscriberDateOfBirth = null,
}: PatientInsurance = {}): ClaimForm['secondaryInsurance'] => ({
  isIncluded,
  name,
  claimFilingCode,
  insuranceTypeCode,
  memberId,
  groupId,
  informationReleaseAuthorized: toBoolInputValue(
    isInformationReleaseAuthorized
  ),
  paymentAuthorized: toBoolInputValue(isPaymentAuthorized),
  subscriberRelationship: isSubscriber
    ? RELATIONSHIP.SELF
    : subscriberRelationship,
  subscriberFirstName,
  subscriberLastName,
  subscriberDateOfBirth: toDateInputValue(subscriberDateOfBirth),
})

export const toFormReferringProvider = ({
  isIncluded = null,
  providerFirstName = null,
  providerLastName = null,
  providerEin = null,
  providerNpi = null,
}: ClaimData['referringProvider'] = {}): ClaimForm['referringProvider'] => ({
  isIncluded,
  providerFirstName,
  providerLastName,
  providerEin,
  providerNpi,
})

export const toFormProvider = ({
  isIncluded,
  providerId = null,
  taxType = null,
  providerEin = null,
  providerSSNlastFour = null,
  providerNpi = null,
  providerTaxonomyCode = null,
}: Provider = {}): ClaimFormProvider => ({
  isIncluded,
  providerId,
  taxType,
  providerEin,
  providerSsn: toSsnInputValue(providerSSNlastFour),
  providerNpi,
  providerTaxonomyCode,
})

export const toFormValues = (
  {
    claimMemo,
    patient,
    primaryInsurance,
    selectedInsuranceName,
    secondaryInsurance,
    referringProvider,
    renderingProvider,
    supervisingProvider,
    unsavedDraft,
  }: ClaimData,
  payersByName?: { [name: string]: ChangePayersList }
): ClaimForm => {
  const formValues: ClaimForm = {
    claimMemo: claimMemo ?? null,
    patient: toFormPatient(patient),
    primaryInsurance: toFormPrimaryInsurance(primaryInsurance),
    selectedInsuranceName: selectedInsuranceName ?? null,
    secondaryInsurance: toFormSecondaryInsurance(secondaryInsurance),
    referringProvider: toFormReferringProvider(referringProvider),
    renderingProvider: toFormProvider(renderingProvider),
    supervisingProvider: toFormProvider(supervisingProvider),
  }

  // auto fill some values if it is a newly created claim
  // can be removed after implementation of CARE-2349
  if (unsavedDraft) {
    if (formValues.primaryInsurance.name && payersByName) {
      formValues.primaryInsurance.claimFilingCode = getClaimFilingCodeForPayer(
        payersByName,
        formValues.primaryInsurance.name
      )
    } else {
      formValues.primaryInsurance.claimFilingCode = null
    }

    if (formValues.primaryInsurance.name) {
      formValues.selectedInsuranceName = formValues.primaryInsurance.name
    } else {
      formValues.selectedInsuranceName = null
    }

    if (formValues.secondaryInsurance.name && payersByName) {
      formValues.secondaryInsurance.claimFilingCode =
        getClaimFilingCodeForPayer(
          payersByName,
          formValues.secondaryInsurance.name
        )
    } else {
      formValues.secondaryInsurance.claimFilingCode = null
    }

    if (isMedicare(formValues.secondaryInsurance.claimFilingCode)) {
      formValues.secondaryInsurance.insuranceTypeCode =
        DEFAULT_INSURANCE_TYPE_CODE
    }
  }

  return formValues
}

const toPatient = (
  patientId: string,
  {
    firstName,
    lastName,
    dateOfBirth,
    legalSex,
    address,
    address2,
  }: ClaimForm['patient']
): ClaimData['patient'] => ({
  id: patientId,
  firstName,
  lastName,
  dateOfBirth,
  legalSex,
  address: address
    ? {
        AddressLine1: address.Address1,
        AddressLine2: address2,
        City: address.City,
        State: address.State,
        ZipCode: address.Zipcode,
        Country: address.Address1 ? 'US' : null,
      }
    : null,
})

const toPrimaryInsurance = ({
  name,
  claimFilingCode,
  memberId,
  groupId,
  informationReleaseAuthorized,
  paymentAuthorized,
  subscriberRelationship,
  subscriberFirstName,
  subscriberLastName,
  subscriberDateOfBirth,
  subscriberLegalSex,
  subscriberEmail,
  subscriberPhoneNumber,
  subscriberAddress,
  subscriberAddress2,
}: ClaimForm['primaryInsurance']): ClaimData['primaryInsurance'] => ({
  name,
  claimFilingCode,
  memberId,
  groupId,
  isInformationReleaseAuthorized: toBoolValue(informationReleaseAuthorized),
  isPaymentAuthorized: toBoolValue(paymentAuthorized),
  subscriberRelationship,
  subscriberFirstName,
  subscriberLastName,
  subscriberDateOfBirth,
  subscriberLegalSex,
  subscriberEmail,
  subscriberPhoneNumber,
  subscriberAddress: subscriberAddress
    ? {
        addressLine1: subscriberAddress.Address1,
        addressLine2: subscriberAddress2,
        city: subscriberAddress.City,
        zipCode: subscriberAddress.Zipcode,
        state: subscriberAddress.State,
        country: subscriberAddress.Address1 ? 'US' : null,
      }
    : null,
})

const toSecondaryInsurance = ({
  isIncluded,
  name,
  claimFilingCode,
  insuranceTypeCode,
  memberId,
  groupId,
  informationReleaseAuthorized,
  paymentAuthorized,
  subscriberRelationship,
  subscriberFirstName,
  subscriberLastName,
  subscriberDateOfBirth,
}: ClaimForm['secondaryInsurance']): ClaimData['secondaryInsurance'] => ({
  isIncluded,
  name,
  claimFilingCode,
  insuranceTypeCode,
  memberId,
  groupId,
  isInformationReleaseAuthorized: toBoolValue(informationReleaseAuthorized),
  isPaymentAuthorized: toBoolValue(paymentAuthorized),
  subscriberRelationship,
  subscriberFirstName,
  subscriberLastName,
  subscriberDateOfBirth,
})

const toProvider = ({
  isIncluded,
  providerId,
  taxType,
  providerEin,
  providerSsn,
  providerSsnFirstFive,
  providerSsnLastFour,
  providerNpi,
  providerTaxonomyCode,
}: ClaimFormProvider): Provider => {
  const providerData: Provider = {
    isIncluded,
    providerId,
    taxType,
    providerEin,
    providerSsn,
    providerSSNfirstFive: providerSsnFirstFive,
    providerSSNlastFour: providerSsnLastFour,
    providerNpi,
    providerTaxonomyCode,
  }
  /*
    providerSsn can either be masked (*****1212) or plain digits
    if providerSsn is in plain digits, it means that user manually entered the value
    if providerSsn is masked, there are two possibilities:
      Scenario 1: the value has been saved previously and it has not changed
      Scenario 2: user selected a new provider, and we prefill the providerSsn with providersData
    We do not pass in providerSsn if it is masked since it does not contain the full value of SSN.
      For scenario 1, we do not send SSN values, BE can grab them from the previous snapshot
      For scenario 2, we send providerSSNfirstFive (encrypted) and providerSSNlastFour.
  */
  if (providerSsn?.includes('*')) {
    delete providerData.providerSsn
  }
  return providerData
}

// form values -> claim data
export const toClaimData = (
  patientId: string,
  patientControlNumber: string,
  {
    claimMemo,
    patient,
    primaryInsurance,
    selectedInsuranceName,
    secondaryInsurance,
    referringProvider,
    renderingProvider,
    supervisingProvider,
  }: ClaimForm
): Omit<ClaimData, 'claimStatusUpdate'> => {
  return {
    patientControlNumber,
    claimMemo,
    patient: toPatient(patientId, patient),
    primaryInsurance: toPrimaryInsurance(primaryInsurance),
    selectedInsuranceName,
    secondaryInsurance: toSecondaryInsurance(secondaryInsurance),
    referringProvider,
    renderingProvider: toProvider(renderingProvider),
    supervisingProvider: toProvider(supervisingProvider),
  }
}
