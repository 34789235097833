import { format } from 'date-fns'
import { isBoolean } from 'lodash'

import { ChangePayersList } from '../../../api/intakeForms'
import {
  AppointmentInfo,
  BillingInfo,
  InsuranceHolder,
  PatientConditionRelation,
  PatientInfoType,
  PatientInsurance,
  PracticeDataType,
  ReferringProviderType,
  RenderingProviderType,
  SupervisingProviderType,
} from '../../../hooks/usePatientProfile/shared-types'
import { notification } from '../../../libs/notificationLib'
import {
  einRegEx,
  emailRegEx,
  nameRegEx,
  nineDigitNumberRegEx,
  npiRegex,
  ssnRegEx,
  tenDigitNumberRegEx,
  tenDigitPhoneNumberRegEx,
} from '../../../libs/regex'
import { parseDateOrNull } from '../../../libs/utils'
import { CLAIM_STATUS, Location, Teammate } from '../../../shared-types'
import { formatPhoneNumber } from '../../../shared/Helpers/utils'
import { Diagnosis } from '../../../stories/Invoice/Diagnoses'
import { NoteDetail } from '../../../v2/notes/types'
import { InsuranceClaimNdc, NdcItem } from '../ClaimsV2/types'
import {
  DEFAULT_MEDICARE_INSURANCE_TYPE_CODE,
  InsurancePrimary,
  InsurancePrimaryHolder,
  InsuranceSecondary,
  InsuranceSecondaryHolder,
  MEDICARE_CLAIM_FILING_CODES,
  appointmentArgs,
  appointmentInfoEmptyObject,
  billingInfoArgs,
  billingInfoEmptyObject,
  claimDetailsEmptyObject,
  claimFilingChangeOptions,
  claimInsuranceEmptyObject,
  insuranceHolderEmptyObject,
  patientCondition,
  patientConditionCauses,
  patientInfoEmptyObject,
  patientInformation,
  referringProviderArgs,
  referringProviderEmptyObject,
  renderingProviderArgs,
  renderingProviderEmptyObject,
} from './constants'

export function extractNumbersFromString(
  inputString: string | undefined | null
) {
  if (!inputString) return null
  const numbersArray = inputString.match(/0*\d+/g)
  if (numbersArray) {
    return numbersArray.join('').toString()
  } else {
    return null
  }
}

function handleEINorSSNUpdate(
  newValue: string | null | undefined,
  isEIN: boolean
) {
  //if not EIN then it must be SSN
  if (!isEIN && newValue && newValue.includes('**')) {
    return newValue
  } else if (!newValue) {
    return null
  } else if (nineDigitNumberRegEx.test(newValue)) {
    return isEIN
      ? `${newValue.slice(0, 2)}-${newValue.slice(2)}`
      : `${newValue.slice(0, 3)}-${newValue.slice(3, 5)}-${newValue.slice(5)}`
  } else if (isEIN ? einRegEx.test(newValue) : ssnRegEx.test(newValue)) {
    return newValue
  } else {
    return null
  }
}

// TODO: this data should be typed
export function normalizeClaimDraftData(data: any) {
  const primaryInsurance = {
    ...claimInsuranceEmptyObject,
    ...{
      isPrimaryInsurance: true,
      insuranceName: data.primaryInsurance?.name || null,
      insuranceMemberId: data.primaryInsurance?.memberId || null,
      insuranceGroupId: data.primaryInsurance?.groupId || null,
      isPrimaryInsuranceHolder: data.primaryInsurance?.isSubscriber ?? null,
      isPaymentAuthorized: data.primaryInsurance?.isPaymentAuthorized ?? null,
      isInformationReleaseAuthorized:
        data.primaryInsurance?.isInformationReleaseAuthorized ?? null,
      claimFilingCode: data.primaryInsurance?.claimFilingCode,
    },
  }
  let primaryHolder = null
  if (!data.primaryInsurance?.isSubscriber) {
    primaryHolder = {
      ...insuranceHolderEmptyObject,
      ...{
        address1:
          data.primaryInsurance?.subscriberAddress?.addressLine1 || null,
        address2:
          data.primaryInsurance?.subscriberAddress?.addressLine2 || null,
        zipCode: data.primaryInsurance?.subscriberAddress?.zipCode || null,
        city: data.primaryInsurance?.subscriberAddress?.city || null,
        state: data.primaryInsurance?.subscriberAddress?.state || null,
        country: data.primaryInsurance?.subscriberAddress?.country || null,
        dateOfBirth: data.primaryInsurance?.subscriberDateOfBirth || null,
        email: data.primaryInsurance?.subscriberEmail || null,
        firstName: data.primaryInsurance?.subscriberFirstName || null,
        lastName: data.primaryInsurance?.subscriberLastName || null,
        phoneNumber: extractNumbersFromString(
          data.primaryInsurance?.subscriberPhoneNumber
        ),
        relationship: data.primaryInsurance?.subscriberRelationship || null,
        legalSex: data.primaryInsurance?.subscriberLegalSex || null,
      },
    }
  }
  const shouldIncludeSecondaryInsurance =
    data.secondaryInsurance?.isIncluded || false
  const isMedicareSecondary = MEDICARE_CLAIM_FILING_CODES.includes(
    data.secondaryInsurance?.claimFilingCode ?? ''
  )
  const isPresubmitStatus = [
    CLAIM_STATUS.DRAFT,
    CLAIM_STATUS.CHANGE_ERROR,
  ].includes(data.claimStatus)
  const secondaryInsurance = {
    ...claimInsuranceEmptyObject,
    ...{
      isPrimaryInsurance: false,
      insuranceName: data.secondaryInsurance?.name || null,
      patientInsuranceId: data.secondaryInsurance?.patientInsuranceId || null,
      insuranceMemberId: data.secondaryInsurance?.memberId || null,
      insuranceGroupId: data.secondaryInsurance?.groupId || null,
      isPrimaryInsuranceHolder: data.secondaryInsurance?.isSubscriber ?? null,
      isPaymentAuthorized: data.secondaryInsurance?.isPaymentAuthorized ?? null,
      isInformationReleaseAuthorized:
        data.secondaryInsurance?.isInformationReleaseAuthorized ?? null,
      claimFilingCode: data.secondaryInsurance?.claimFilingCode,
      insuranceTypeCode:
        data.secondaryInsurance?.insuranceTypeCode ??
        (isMedicareSecondary && isPresubmitStatus
          ? DEFAULT_MEDICARE_INSURANCE_TYPE_CODE
          : null),
    },
  }
  let secondaryHolder = null
  if (!data.secondaryInsurance?.isSubscriber) {
    secondaryHolder = {
      ...insuranceHolderEmptyObject,
      ...{
        firstName: data.secondaryInsurance?.subscriberFirstName || null,
        lastName: data.secondaryInsurance?.subscriberLastName || null,
        relationship: data.secondaryInsurance?.subscriberRelationship || null,
        dateOfBirth: data.secondaryInsurance?.subscriberDateOfBirth || null,
      },
    }
  }

  const patientInfoInitial = {
    ...patientInfoEmptyObject,
    ...{
      firstName: data.patient?.firstName || null,
      lastName: data.patient?.lastName || null,
      address1: data.patient?.address?.AddressLine1 || null,
      address2: data.patient?.address?.AddressLine2 || null,
      zipCode: data.patient?.address?.ZipCode || null,
      city: data.patient?.address?.City || null,
      state: data.patient?.address?.State || null,
      country: 'US',
      dateOfBirth: data.patient?.dateOfBirth || null,
      legalSex: data.patient?.legalSex || null,
    },
  }

  const claimHeaderDetails = {
    ...claimDetailsEmptyObject,
    ...{
      type: 'Primary',
      patientControlNumber: data.patientControlNumber,
      created: data.createdAt ? data.createdAt : new Date().toISOString(),
      submittedStatus: '',
      status: data.claimStatus,
      submittedDate: data.submittedInfo?.submittedDate || null,
      canceledAt: data.canceledAt || null,
      payerControlNumber: data.payerControlNumber || null,
      claimStatusUpdate: data.claimStatusUpdate,
    },
  }

  const renderingProviderData = {
    ...renderingProviderEmptyObject,
    ...{
      providerId: data.renderingProvider?.providerId || null,
      providerEin: data.renderingProvider?.providerEin || null,
      providerNpi: data.renderingProvider?.providerNpi || null,
      providerSsn: data.renderingProvider?.providerSsn || null,
      taxType: data.renderingProvider?.taxType || null,
      providerTaxonomyCode:
        data.renderingProvider?.providerTaxonomyCode || null,
    },
  }

  const appointmentInfoData = {
    ...appointmentInfoEmptyObject,
    ...{
      noteId: data.note?.noteId ?? null,
      noteIdV2: data.note?.noteIdV2 ?? data.note?.noteId ?? null,
      startDate: data.note?.startDate || null,
      endDate: data.note?.startDate || null,
      practice: data.note?.practiceName || null,
      locationId: data.note?.locationId || null,
      practiceNpi: data.note?.practiceNpi || null,
      address1: data.note?.address?.addressLine1 || null,
      state: data.note?.address?.state || null,
      city: data.note?.address?.city || null,
      country: 'US',
      zipCode: data.note?.address?.zipCode || null,
      posCode: data.note?.posCode || null,
      isSigned: data.note?.signed || false,
      signedDate: (() => {
        if (data.note?.Signatures?.length) {
          const signatureDates = data.note?.Signatures.map((signature: any) => {
            // @ts-ignore
            return parseDateOrNull(signature?.CreatedAt, true)
          }).filter((a: any) => a)

          if (signatureDates.length) {
            signatureDates.sort()
            return signatureDates[0]
          }
        }
      })(),
    },
  }

  let shouldIncludeReferring = false
  let referringProviderData = { ...referringProviderEmptyObject }
  if (data.referringProvider) {
    shouldIncludeReferring = data.referringProvider.isIncluded || false
    referringProviderData = {
      ...referringProviderData,
      ...{
        firstName: data.referringProvider?.providerFirstName || null,
        lastName: data.referringProvider?.providerLastName || null,
        providerEin: data.referringProvider?.providerEin || null,
        providerNpi: data.referringProvider?.providerNpi || null,
      },
    }
  }
  let shouldIncludeSupervising = false
  let supervisingProviderData = { ...renderingProviderEmptyObject }
  if (data.supervisingProvider) {
    shouldIncludeSupervising = data.supervisingProvider.isIncluded || false
    supervisingProviderData = {
      ...supervisingProviderData,
      ...{
        providerId: data.supervisingProvider?.providerId || null,
        providerEin: data.supervisingProvider?.providerEin || null,
        providerNpi: data.supervisingProvider?.providerNpi || null,
      },
    }
  }
  let billingData: BillingInfo = { ...billingInfoEmptyObject }
  if (data.billing) {
    if (data.billingType === 'Provider') {
      billingData = {
        ...billingData,
        ...{
          type: 'Provider',
          name: data.billing.name || null,
          locationId: data.billing.locationId || null,
          address1: data.billing.address?.addressLine1 || null,
          address2: data.billing.address?.addressLine2 || null,
          zipCode: data.billing.address?.zipCode || null,
          city: data.billing.address?.city || null,
          state: data.billing.address?.state || null,
          country: data.billing.address?.country || null,
          phone: extractNumbersFromString(data.billing.phoneNumber),
          EIN: data.billing.ein || null,
          taxType: data.billing.taxType || null,
          SSN: data.billing.ssn || null,
          NPI: data.billing.npi || null,
          taxonomyCode: data.billing.taxonomyCode || null,
        },
      }
    } else if (data.billingType === 'Practice') {
      billingData = {
        ...billingData,
        ...{
          type: 'Practice',
          name: data.billing.name || null,
          locationId: data.billing.locationId || null,
          address1: data.billing.address?.addressLine1 || null,
          address2: data.billing.address?.addressLine2 || null,
          zipCode: data.billing.address?.zipCode || null,
          city: data.billing.address?.city || null,
          state: data.billing.address?.state || null,
          country: data.billing.address?.country || null,
          phone: extractNumbersFromString(data.billing.phoneNumber),
          taxType: data.billing.taxType || null,
          SSN: data.billing.ssn || null,
          EIN: data.billing.ein || null,
          NPI: data.billing.npi || null,
        },
      }
    }
  }

  let diagnosisRelationInfo = { ...patientConditionCauses }
  if (data.patientConditionRelation) {
    diagnosisRelationInfo = {
      ...diagnosisRelationInfo,
      ...{
        employmentAccident: data.patientConditionRelation.employmentAccident,
        autoAccident: data.patientConditionRelation.autoAccident,
        accidentState: data.patientConditionRelation.accidentState,
        otherAccident: data.patientConditionRelation.otherAccident,
      },
    }
  }

  const signatureProvider = data.signature?.providerId || null

  let billableEntityInfo = []
  if (data.lineItems) {
    billableEntityInfo = data.lineItems.map((l: any) => {
      return {
        uuid: l.uuid,
        units: l.units,
        unitChargeAmountCents: l.unitChargeAmountCents,
        totalAdjustmentAmountCents: l.totalAdjustmentAmountCents,
        billingCode: {
          code: l.billingCode?.code,
          version: l.billingCode?.version,
          ownerId: l.billingCode?.ownerId,
          description: l.billingCode?.description,
          id: l.billingCode?.id,
        },
        billingTemplateId: '',
        modifiers: l.modifiers,
        billingTemplateCodeId: '',
        dxPointers: l.dxPointers,
      }
    })
  }

  let diagnosisInfo = []
  if (data.diagnoses) {
    diagnosisInfo = data.diagnoses.map((d: any, index: number) => {
      return {
        diagnosisId: d.diagnosisId,
        id: d.diagnosisId,
        order: index,
        code: d.code,
        description: d.description,
        // hardcode status if it's coming from the claim draft snapshot
        status: 'Active',
        isDeleted: d.isDeleted,
      }
    })
  }
  let ndcs: NdcItem[] = []
  if (data.ndcs?.length) {
    ndcs = data.ndcs.map(
      (el: InsuranceClaimNdc): NdcItem => ({
        key: el.uuid as string,
        uuid: el.uuid,
        billingCodeId: el.billingCodeId,
        idQualifier: el.ndcIdQualifier,
        ndc: el.ndcEntered,
        ndcLabel: el.ndcLabel,
        numberOfUnits: el.numberOfUnits,
        unitType: el.unitMeasurementType,
        prescriptionNumber: el.prescriptionNumber,
      })
    )
  }
  const selectedInsuranceName = data.selectedInsuranceName || null
  const autorizationNumber = data.authNumber
  const fetchedClaimStatus = data.claimStatus
  const unsavedDraft = data.unsavedDraft || false
  const claimMemo = data.claimMemo || ''
  return {
    primaryInsurance,
    primaryHolder,
    shouldIncludeSecondaryInsurance,
    secondaryInsurance,
    secondaryHolder,
    patientInfoInitial,
    claimHeaderDetails,
    renderingProviderData,
    appointmentInfoData,
    referringProviderData,
    shouldIncludeReferring,
    shouldIncludeSupervising,
    supervisingProviderData,
    billingData,
    diagnosisRelationInfo,
    signatureProvider,
    billableEntityInfo,
    diagnosisInfo,
    selectedInsuranceName,
    autorizationNumber,
    fetchedClaimStatus,
    unsavedDraft,
    claimMemo,
    ndcs,
  }
}

function constructAddressFromObject(object: any) {
  return {
    addressLine1: object?.address1 || null,
    addressLine2: object?.address2 || null,
    zipCode: object?.zipCode || null,
    city: object?.city || null,
    state: object?.state || null,
    country: object?.country || null,
  }
}

export function constructPOSTBody(data: any) {
  const body = {
    primaryInsurance: {
      name: data.primaryInsurance.insuranceName,
      patientInsuranceId: data.primaryInsurance.patientInsuranceId,
      memberId: data.primaryInsurance.insuranceMemberId,
      groupId: data.primaryInsurance.insuranceGroupId,
      isSubscriber: data.primaryInsurance.isPrimaryInsuranceHolder,
      isPaymentAuthorized: data.primaryInsurance.isPaymentAuthorized,
      isInformationReleaseAuthorized:
        data.primaryInsurance.isInformationReleaseAuthorized,
      claimFilingCode: data.primaryInsurance.claimFilingCode,
      subscriberAddress: constructAddressFromObject(data.primaryHolder),
      subscriberDateOfBirth: data.primaryHolder?.dateOfBirth,
      subscriberEmail: data.primaryHolder?.email,
      subscriberFirstName: data.primaryHolder?.firstName,
      subscriberLastName: data.primaryHolder?.lastName,
      subscriberPhoneNumber: data.primaryHolder?.phoneNumber,
      subscriberRelationship: data.primaryInsurance.isPrimaryInsuranceHolder
        ? 'Self'
        : data.primaryHolder?.relationship,
      subscriberLegalSex: data.primaryHolder?.legalSex,
    },
    secondaryInsurance: data.includeSecondary
      ? {
          name: data.secondaryInsurance?.insuranceName,
          patientInsuranceId: data.secondaryInsurance?.patientInsuranceId,
          memberId: data.secondaryInsurance?.insuranceMemberId,
          groupId: data.secondaryInsurance?.insuranceGroupId,
          isSubscriber: data.secondaryInsurance?.isPrimaryInsuranceHolder,
          isPaymentAuthorized: data.secondaryInsurance?.isPaymentAuthorized,
          isInformationReleaseAuthorized:
            data.secondaryInsurance?.isInformationReleaseAuthorized,
          claimFilingCode: data.secondaryInsurance?.claimFilingCode,
          insuranceTypeCode: MEDICARE_CLAIM_FILING_CODES.includes(
            data.secondaryInsurance?.claimFilingCode
          )
            ? data.secondaryInsurance?.insuranceTypeCode
            : null,
          subscriberDateOfBirth: data.secondaryHolder?.dateOfBirth,
          subscriberEmail: data.secondaryHolder?.email,
          subscriberFirstName: data.secondaryHolder?.firstName,
          subscriberLastName: data.secondaryHolder?.lastName,
          subscriberPhoneNumber: data.secondaryHolder?.phoneNumber,
          subscriberRelationship: data.secondaryInsurance
            ?.isPrimaryInsuranceHolder
            ? 'Self'
            : data.secondaryHolder?.relationship,
          subscriberLegalSex: data.secondaryHolder?.legalSex,
        }
      : {},
    includeSecondary: data.includeSecondary,
    patientConditionRelation: {
      autoAccident: data.patientConditionRelation.autoAccident,
      accidentState: data.patientConditionRelation.accidentState,
      employmentAccident: data.patientConditionRelation.employmentAccident,
      otherAccident: data.patientConditionRelation.otherAccident,
    },
    patient: {
      address: {
        AddressLine1: data?.patientInfo.address1 || null,
        AddressLine2: data?.patientInfo.address2 || null,
        ZipCode: data?.patientInfo.zipCode || null,
        City: data?.patientInfo.city || null,
        State: data?.patientInfo.state || null,
        Country: data?.patientInfo.country || null,
      },
      id: data.patientId,
      dateOfBirth: data.patientInfo?.dateOfBirth,
      firstName: data.patientInfo?.firstName,
      lastName: data.patientInfo?.lastName,
      legalSex: data.patientInfo?.legalSex,
    },
    referringProvider: {
      providerId: data.referringProviderInfo.providerId,
      providerEin: data.referringProviderInfo.providerEin,
      providerNpi: data.referringProviderInfo.providerNpi,
      providerFirstName: data.referringProviderInfo.firstName,
      providerLastName: data.referringProviderInfo.lastName,
    },
    includeReferringProvider: data.includeReferringProvider,
    renderingProvider: {
      ...data.renderingProviderInfo,
      providerEin: data.renderingProviderInfo.providerEin
        ? data.renderingProviderInfo.providerEin.replace('-', '')
        : null,
    },
    note: {
      startDate: data.appointmentInfo.startDate,
      endDate: data.appointmentInfo.endDate,
      practiceName: data.appointmentInfo.practice,
      posCode: data.appointmentInfo.posCode,
      locationId: data.appointmentInfo.locationId,
      practiceNpi: data.appointmentInfo.practiceNpi,
      address: constructAddressFromObject(data.appointmentInfo),
      noteId: data.appointmentInfo.noteId,
      noteIdV2: data.appointmentInfo.noteIdV2,
    },
    includeSupervisingProvider: data.includeSupervisingProvider,
    supervisingProvider: data.supervisingProvider,
    billing: {
      type: data.billingInfo.type,
      name: data.billingInfo.type === 'Practice' ? data.billingInfo.name : null,
      providerId:
        data.billingInfo.type === 'Provider' ? data.billingInfo.name : null,
      locationId: data.billingInfo.locationId,
      address: constructAddressFromObject(data.billingInfo),
      phoneNumber: data.billingInfo.phone,
      ein:
        data.billingInfo.taxType === 'EIN' ? data.billingInfo.EIN : undefined,
      npi: data.billingInfo.NPI,
      ssn:
        data.billingInfo.taxType === 'SSN' ? data.billingInfo.SSN : undefined,
      taxType: data.billingInfo.taxType,
      firstName: data.billingInfo.firstName,
      lastName: data.billingInfo.lastName,
      locationName: data.billingInfo.locationName,
      ssnFirstFive: data.billingInfo.SSNfirstFive,
      ssnLastFour: data.billingInfo.SSNlastFour,
      taxonomyCode: data.billingInfo.taxonomyCode,
    },
    billingType: data.billingInfo?.type,
    diagnoses: data.diagnoses,
    lineItems: data.lineItems,
    selectedInsuranceName: data.companyToSubmitTo,
    signature: {
      providerId: data.signingProvider,
    },
    authNumber: data.authNumber,
    claimMemo: data.claimMemoContent,
    claimId: data.claimId,
    ndcs: data.ndcs.map(
      (el: NdcItem): InsuranceClaimNdc => ({
        uuid: el.uuid,
        billingCodeId: el.billingCodeId,
        ndcIdQualifier: el.idQualifier,
        ndcEntered: el.ndc,
        ndcLabel: el.ndcLabel,
        numberOfUnits: el.numberOfUnits,
        unitMeasurementType: el.unitType,
        prescriptionNumber: el.prescriptionNumber,
      })
    ),
  }
  return body
}

export function populateAppointmentInfo(
  itemsCopy: any,
  appointmentInfo: AppointmentInfo
) {
  // the start and end dates of appointmentInfo are sourced from the ClinicalNotes created_on column
  // this column is stored in UTC time, but the timezone has no meaning. The date is intended to be relative to
  // the clinic location, and the UTC formatting is an artifact of implementation. Therefore, the best way
  // to avoid time zone issues when presenting this date is to treat it as having no timezone.
  // https://osmind.atlassian.net/browse/CARE-1532
  const extractDateFromTimestamp = (isoString?: string | null) => {
    if (!isoString) {
      return ''
    }
    // remove the timezone ("Z")
    const withoutTz = isoString.replace('Z', '')
    const dateObj = new Date(withoutTz)
    return format(dateObj, 'MM/dd/yyyy')
  }
  itemsCopy.forEach(({ items }: { items: any }) => {
    items.forEach((i: any) => {
      switch (i.id) {
        case appointmentArgs.noteIdV2:
          i.value = appointmentInfo.noteIdV2
          break
        case appointmentArgs.startDate:
          i.value = extractDateFromTimestamp(appointmentInfo.startDate)
          break
        case appointmentArgs.endDate:
          i.value = extractDateFromTimestamp(appointmentInfo.endDate)
          break
        case appointmentArgs.practice:
          i.value = appointmentInfo.practice
          break
        case appointmentArgs.locationId:
          i.value = appointmentInfo.locationId || ''
          break
        case appointmentArgs.practiceNpi:
          i.value = appointmentInfo.practiceNpi || ''
          break
        case appointmentArgs.posCode:
          i.value = appointmentInfo.posCode || ''
          break
        case appointmentArgs.address:
          i.value = appointmentInfo.address1
            ? `${appointmentInfo.address1}${
                appointmentInfo.city ? `, ${appointmentInfo.city}` : ''
              }${appointmentInfo.state ? `, ${appointmentInfo.state}` : ''}${
                appointmentInfo.zipCode ? `, ${appointmentInfo.zipCode}` : ''
              }`
            : ''
      }
    })
  })
}

export function populatePatientInfo(
  itemsCopy: any,
  patientInfo: PatientInfoType
) {
  itemsCopy.forEach(({ items }: { items: any }) => {
    items.forEach((i: any) => {
      switch (i.id) {
        case patientInformation.firstName:
          i.value = patientInfo.firstName || null
          break
        case patientInformation.lastName:
          i.value = patientInfo.lastName || null
          break
        case patientInformation.dateOfBirth:
          {
            if (patientInfo.dateOfBirth) {
              const date = new Date(patientInfo.dateOfBirth)
              const result = new Date(date.toISOString().slice(0, -1))
              i.value = format(result, 'MM/dd/yyyy')
            } else {
              i.value = ''
            }
          }
          break
        case patientInformation.legalSex:
          if (
            patientInfo?.legalSex &&
            (patientInfo.legalSex === 'M' ||
              patientInfo.legalSex === 'F' ||
              patientInfo.legalSex === 'X' ||
              patientInfo.legalSex === 'Unknown')
          ) {
            i.value = patientInfo.legalSex || null
          } else {
            i.value = null
          }
          break
        case patientInformation.address:
          i.value = patientInfo?.address1
            ? `${patientInfo.address1}${
                patientInfo.city ? `, ${patientInfo.city}` : ''
              }${patientInfo.state ? `, ${patientInfo.state}` : ''}${
                patientInfo.zipCode ? `, ${patientInfo.zipCode}` : ''
              }`
            : ''
          break
        case patientInformation.unit:
          i.value = patientInfo.address2 || ''
      }
    })
  })
}

export function populatePrimaryInsuranceInfo(
  itemsCopy: any,
  primaryInsurance: PatientInsurance,
  primaryHolder: InsuranceHolder | null | undefined,
  sameAddressAsPatient: boolean
) {
  itemsCopy.forEach(({ items }: { items: any }) => {
    items.forEach((i: any) => {
      switch (i.id) {
        case InsurancePrimary.insuranceName:
          i.value = primaryInsurance.insuranceName
          break
        case InsurancePrimary.claimFilingCode:
          i.value = primaryInsurance.claimFilingCode || null
          break
        case InsurancePrimary.insuranceGroupId:
          i.value = primaryInsurance.insuranceGroupId
          break
        case InsurancePrimary.insuranceMemberId:
          i.value = primaryInsurance.insuranceMemberId
          break
        case InsurancePrimary.informationRelease:
          if (primaryInsurance.isInformationReleaseAuthorized !== null) {
            i.value = primaryInsurance.isInformationReleaseAuthorized
              ? 'Yes'
              : 'No'
          } else {
            i.value = null
          }

          break
        case InsurancePrimary.paymentAutorization:
          if (primaryInsurance.isPaymentAuthorized !== null) {
            i.value = primaryInsurance.isPaymentAuthorized ? 'Yes' : 'No'
          } else {
            i.value = null
          }
          break
        case InsurancePrimaryHolder.primaryRelationship:
          if (
            primaryInsurance.isPrimaryInsuranceHolder === null &&
            !primaryHolder
          ) {
            i.value = null
          } else if (
            primaryInsurance.isPrimaryInsuranceHolder &&
            !primaryHolder
          ) {
            i.value = 'Self'
          } else {
            i.value = primaryHolder?.relationship || ''
          }
          break
        case InsurancePrimaryHolder.primaryFirstName:
          i.value = primaryHolder?.firstName || ''
          break
        case InsurancePrimaryHolder.primaryLastName:
          i.value = primaryHolder?.lastName || ''
          break
        case InsurancePrimaryHolder.dateOfBirth:
          i.value = primaryHolder?.dateOfBirth
            ? format(new Date(primaryHolder.dateOfBirth), 'MM/dd/yyyy')
            : ''
          break
        case InsurancePrimaryHolder.legalSex:
          i.value = primaryHolder?.legalSex || ''
          break
        case InsurancePrimaryHolder.email:
          i.value = primaryHolder?.email || ''
          break
        case InsurancePrimaryHolder.phoneNumber:
          i.value = primaryHolder?.phoneNumber
            ? formatPhoneNumber(primaryHolder.phoneNumber)
            : ''
          break
        case InsurancePrimaryHolder.sameAddressAsPatient:
          i.value = primaryHolder?.address1
            ? primaryHolder.address1
              ? 'No'
              : 'Yes'
            : sameAddressAsPatient
            ? 'Yes'
            : 'No'
          break
        case InsurancePrimaryHolder.address:
          i.value = primaryHolder?.address1
            ? `${primaryHolder.address1}${
                primaryHolder.city ? `, ${primaryHolder.city}` : ''
              }${primaryHolder.state ? `, ${primaryHolder.state}` : ''}${
                primaryHolder.zipCode ? `, ${primaryHolder.zipCode}` : ''
              }`
            : ''
          break
        case InsurancePrimaryHolder.unit:
          i.value = primaryHolder?.address2 || ''
      }
    })
  })
}

export function populateSecondaryInsuranceInfo(
  itemsCopy: any,
  secondaryInsurance: PatientInsurance,
  secondaryHolder: InsuranceHolder | null | undefined
) {
  itemsCopy.forEach(({ items }: { items: any }) => {
    items.forEach((i: any) => {
      switch (i.id) {
        case InsuranceSecondary.secondaryInsuranceName:
          i.value = secondaryInsurance.insuranceName
          break
        case InsuranceSecondary.secondaryClaimFillingCode:
          i.value = secondaryInsurance.claimFilingCode
          break
        case InsuranceSecondary.secondaryInsuranceTypeCode:
          i.value = secondaryInsurance.insuranceTypeCode
          break
        case InsuranceSecondary.secondaryInsuranceGroupId:
          i.value = secondaryInsurance.insuranceGroupId
          break
        case InsuranceSecondary.secondaryInsuranceMemberId:
          i.value = secondaryInsurance.insuranceMemberId
          break
        case InsuranceSecondary.secondaryInformationRelease:
          if (secondaryInsurance.isInformationReleaseAuthorized !== null) {
            i.value = secondaryInsurance.isInformationReleaseAuthorized
              ? 'Yes'
              : 'No'
          } else {
            i.value = null
          }
          break
        case InsuranceSecondary.secondaryPaymentAuthorization:
          if (secondaryInsurance.isPaymentAuthorized !== null) {
            i.value = secondaryInsurance.isPaymentAuthorized ? 'Yes' : 'No'
          } else {
            i.value = null
          }
          break
        case InsuranceSecondaryHolder.secondaryFirstName:
          i.value = secondaryHolder?.firstName || ''
          break
        case InsuranceSecondaryHolder.secondaryLastName:
          i.value = secondaryHolder?.lastName || ''
          break
        case InsuranceSecondaryHolder.secondaryRelationship:
          if (
            secondaryInsurance.isPrimaryInsuranceHolder === null &&
            !secondaryHolder
          ) {
            i.value = null
          } else if (
            secondaryInsurance.isPrimaryInsuranceHolder ||
            !secondaryHolder
          ) {
            i.value = 'Self'
          } else {
            i.value = secondaryHolder?.relationship || null
          }
          break
        case InsuranceSecondaryHolder.secondaryDateOfBirth:
          i.value = secondaryHolder?.dateOfBirth
            ? format(new Date(secondaryHolder.dateOfBirth), 'MM/dd/yyyy')
            : ''
      }
    })
  })
}

export function populateReferringProviderInfo(
  itemsCopy: any,
  referringProviderInfo: ReferringProviderType
) {
  itemsCopy.forEach(({ items }: { items: string[] }) => {
    items.forEach((i: any) => {
      if (i.id === 'providerEin') {
        i.value = handleEINorSSNUpdate(referringProviderInfo.providerEin, true)
      } else {
        // @ts-ignore
        i.value = referringProviderInfo[i.id] || null
      }
    })
  })
}

export function populateSupervisingProviderInfo(
  itemsCopy: any,
  supervisingProviderInfo: SupervisingProviderType
) {
  itemsCopy.forEach(({ items }: { items: any }) => {
    items.forEach((i: any) => {
      switch (i.id) {
        case renderingProviderArgs.providerId:
          i.value = supervisingProviderInfo.providerId || null
          break
        case renderingProviderArgs.providerEin:
          i.value = handleEINorSSNUpdate(
            supervisingProviderInfo.providerEin,
            true
          )
          break
        case renderingProviderArgs.providerNpi:
          i.value = supervisingProviderInfo.providerNpi || null
      }
    })
  })
}

export function populateRenderingProviderInfo(
  itemsCopy: any,
  renderingProviderInfo: RenderingProviderType
) {
  itemsCopy.forEach(({ items }: { items: any }) => {
    items.forEach((i: any) => {
      switch (i.id) {
        case renderingProviderArgs.providerId:
          i.value = renderingProviderInfo.providerId || null
          break
        case renderingProviderArgs.providerEin:
          i.value = handleEINorSSNUpdate(
            renderingProviderInfo.providerEin,
            true
          )
          break
        case renderingProviderArgs.providerSsn:
          i.value = handleEINorSSNUpdate(
            renderingProviderInfo.providerSsn,
            false
          )
          break
        case renderingProviderArgs.taxonomyCode:
          i.value = renderingProviderInfo.providerTaxonomyCode || null
          break
        case renderingProviderArgs.taxType:
          i.value = renderingProviderInfo.taxType || null
          break
        case renderingProviderArgs.providerNpi:
          i.value = renderingProviderInfo.providerNpi || null
      }
    })
  })
}

export function populateBillinInfo(itemsCopy: any, billingInfo: BillingInfo) {
  itemsCopy.forEach(({ items }: { items: any }) => {
    items.forEach((i: any) => {
      switch (i.id) {
        case billingInfoArgs.type:
          i.value = billingInfo.type || null
          break
        case billingInfoArgs.name:
          i.value = billingInfo.name || null
          break
        case billingInfoArgs.locationId:
          i.value = billingInfo.locationId || null
          break
        case billingInfoArgs.locationName:
          i.value = billingInfo.locationName || null
          break
        case billingInfoArgs.address1:
          i.value = billingInfo.address1
            ? `${billingInfo.address1}${
                billingInfo.city ? `, ${billingInfo.city}` : ''
              }${billingInfo.state ? `, ${billingInfo.state}` : ''}${
                billingInfo.zipCode ? `, ${billingInfo.zipCode}` : ''
              }`
            : ''
          break
        case billingInfoArgs.address2:
          i.value = billingInfo.address2 || null
          break
        case billingInfoArgs.phone:
          i.value = billingInfo.phone
            ? formatPhoneNumber(billingInfo.phone)
            : null
          break
        case billingInfoArgs.taxType:
          i.value = billingInfo.taxType || null
          break
        case billingInfoArgs.SSN:
          i.value = handleEINorSSNUpdate(billingInfo.SSN, false)
          i.label = `${billingInfo.type} SSN`
          break
        case billingInfoArgs.EIN:
          i.value = handleEINorSSNUpdate(billingInfo.EIN, true)
          i.label = `${billingInfo.type} EIN`
          break
        case billingInfoArgs.NPI:
          i.value = billingInfo.NPI || null
          i.label = `${billingInfo.type} NPI`
          break
        case billingInfoArgs.taxonomyCode:
          i.value = billingInfo.taxonomyCode || null
          break
      }
    })
  })
}

export function populatePatientconditionRelationInfo(
  itemsCopy: any,
  patientConditionRelation: PatientConditionRelation
) {
  itemsCopy.forEach(({ items }: { items: any }) => {
    items.forEach((i: any) => {
      // @ts-ignore
      if (patientConditionRelation[i.id] === null) {
        i.value = null
      } else if (i.id === patientCondition.accidentState) {
        i.value = patientConditionRelation.accidentState || null
      } else {
        // @ts-ignore
        i.value = patientConditionRelation[i.id] ? 'Yes' : 'No'
      }
    })
  })
}

const findLocationIndexWithLocationId = (
  locationId: string,
  locations: Location[]
): number => {
  let locationIndex = -1
  locations.forEach((l, index) => {
    if (l.LocationId === locationId) {
      locationIndex = index
    }
  })
  return locationIndex
}

function testRegEx(
  value: any,
  regEx: any,
  object: any,
  arg: string,
  message: string
) {
  if (!value || regEx.test(value)) {
    object[arg] = value || null
    return true
  }
  notification(message, 'failure')
  return false
}

export function handleValueUpdateAppointmentInfo(
  newVal: any,
  id: string,
  copyAppointmentInfo: AppointmentInfo,
  notes: NoteDetail[],
  locations: Location[],
  handleNoteChange: Function
): boolean {
  let selectedNote: NoteDetail | undefined
  let locationIndex: number | undefined
  let shouldSave = true
  switch (id) {
    case appointmentArgs.noteIdV2:
      selectedNote = notes.find((n) => n.noteId === newVal)
      if (!selectedNote) {
        copyAppointmentInfo.noteId = null
        copyAppointmentInfo.noteIdV2 = null
        copyAppointmentInfo.address1 = null
        copyAppointmentInfo.city = null
        copyAppointmentInfo.zipCode = null
        copyAppointmentInfo.state = null
        copyAppointmentInfo.country = null
        copyAppointmentInfo.posCode = null
        copyAppointmentInfo.startDate = null
        copyAppointmentInfo.endDate = null
        copyAppointmentInfo.practice = null
        copyAppointmentInfo.locationId = null
        copyAppointmentInfo.practiceNpi = null
        copyAppointmentInfo.isSigned = false
        copyAppointmentInfo.signedDate = null
        handleNoteChange(undefined, undefined)
        break
      }
      // noteId is saved as a FK to the v1 notes table in the backend, so only set it if it's a v1 note
      copyAppointmentInfo.noteId = selectedNote.version === '1' ? newVal : null
      copyAppointmentInfo.noteIdV2 = newVal || null
      copyAppointmentInfo.startDate = selectedNote.appointmentDate
        ? format(selectedNote.appointmentDate, 'MM/dd/yyyy')
        : null
      copyAppointmentInfo.signedDate = selectedNote.firstSignedAt
      copyAppointmentInfo.isSigned = selectedNote.isSigned
      copyAppointmentInfo.endDate = selectedNote.appointmentDate
        ? format(selectedNote.appointmentDate, 'MM/dd/yyyy')
        : null
      if (selectedNote.locationId) {
        const location = locations.find(
          (l) => l.LocationId === selectedNote?.locationId
        )
        copyAppointmentInfo.locationId = selectedNote.locationId
        if (location?.AddressLine1) {
          copyAppointmentInfo.address1 = location.AddressLine1 ?? null
          copyAppointmentInfo.city = location.City ?? null
          copyAppointmentInfo.zipCode = location.Zipcode ?? null
          copyAppointmentInfo.state = location.State ?? null
          copyAppointmentInfo.country = 'US'
          copyAppointmentInfo.posCode = location.Pos ?? null
          copyAppointmentInfo.practiceNpi = location.BillingNPI ?? null
        } else {
          copyAppointmentInfo.address1 = null
          copyAppointmentInfo.city = null
          copyAppointmentInfo.zipCode = null
          copyAppointmentInfo.state = null
          copyAppointmentInfo.country = null
          copyAppointmentInfo.posCode = null
          copyAppointmentInfo.practiceNpi = null
        }
      }
      handleNoteChange(
        selectedNote?.noteId,
        selectedNote?.renderingProviderId,
        copyAppointmentInfo
      )
      break
    case appointmentArgs.startDate:
      copyAppointmentInfo.startDate = newVal
        ? format(new Date(newVal.trim()), 'MM/dd/yyyy')
        : null
      break
    case appointmentArgs.endDate:
      copyAppointmentInfo.endDate = newVal
        ? format(new Date(newVal.trim()), 'MM/dd/yyyy')
        : null
      break
    case appointmentArgs.locationId:
      locationIndex = findLocationIndexWithLocationId(newVal, locations)
      copyAppointmentInfo.locationId = newVal || null
      if (locationIndex > -1 && locations[locationIndex]?.AddressLine1) {
        copyAppointmentInfo.address1 =
          locations[locationIndex]?.AddressLine1 || null
        copyAppointmentInfo.city = locations[locationIndex]?.City || null
        copyAppointmentInfo.zipCode = locations[locationIndex]?.Zipcode || null
        copyAppointmentInfo.state = locations[locationIndex]?.State || null
        copyAppointmentInfo.country = 'US'
        copyAppointmentInfo.posCode = locations[locationIndex]?.Pos || null
        copyAppointmentInfo.practiceNpi =
          locations[locationIndex]?.BillingNPI || null
      } else {
        copyAppointmentInfo.address1 = null
        copyAppointmentInfo.city = null
        copyAppointmentInfo.zipCode = null
        copyAppointmentInfo.state = null
        copyAppointmentInfo.country = null
        copyAppointmentInfo.posCode = null
        copyAppointmentInfo.practiceNpi = null
      }
      break
    case appointmentArgs.address:
      copyAppointmentInfo.address1 = newVal.Address1?.trim() || null
      copyAppointmentInfo.address2 = newVal.Address2?.trim() || null
      copyAppointmentInfo.city = newVal.City?.trim() || null
      copyAppointmentInfo.zipCode = newVal.Zipcode?.trim() || null
      copyAppointmentInfo.state = newVal.State?.trim() || null
      copyAppointmentInfo.country = 'US'
      break
    case appointmentArgs.posCode:
      copyAppointmentInfo.posCode = newVal || null
      break
    case appointmentArgs.practiceNpi:
      shouldSave = testRegEx(
        newVal,
        npiRegex,
        copyAppointmentInfo,
        'practiceNpi',
        'Please input a ten digit number without special characters.'
      )
      break
    case appointmentArgs.practice:
      copyAppointmentInfo.practice = newVal || null
  }
  return shouldSave
}

export function isActive(dx: Diagnosis) {
  return !dx.isDeleted && dx.status === 'Active'
}

export function handlePatientInfoUpdate(
  newVal: any,
  id: string,
  copyPatientInfo: PatientInfoType
) {
  let shouldSave = true
  switch (id) {
    case patientInformation.firstName:
      shouldSave = testRegEx(
        newVal,
        nameRegEx,
        copyPatientInfo,
        'firstName',
        'Please input a first name with no symbols nor special characters.'
      )
      break
    case patientInformation.lastName:
      shouldSave = testRegEx(
        newVal,
        nameRegEx,
        copyPatientInfo,
        'lastName',
        'Please input a last name with no symbols nor special characters.'
      )
      break
    case patientInformation.dateOfBirth:
      copyPatientInfo.dateOfBirth = newVal
        ? format(new Date(newVal), 'MM/dd/yyyy')
        : null
      break
    case patientInformation.legalSex:
      copyPatientInfo.legalSex = newVal || null
      break
    case patientInformation.address:
      copyPatientInfo.address1 = newVal.Address1?.trim() || null
      copyPatientInfo.address2 = newVal.Address2?.trim() || null
      copyPatientInfo.city = newVal.City?.trim() || null
      copyPatientInfo.zipCode = newVal.Zipcode?.trim() || null
      copyPatientInfo.state = newVal.State?.trim() || null
      copyPatientInfo.country = 'US'
      break
    case patientInformation.unit:
      copyPatientInfo.address2 = newVal || null
  }
  return shouldSave
}

export function handlePrimaryInsuranceUpdate(
  newVal: any,
  id: string,
  primaryInsurance: PatientInsurance | null | undefined,
  primaryHolder: InsuranceHolder | null | undefined,
  setSameAddressAsPatient: Function,
  changeList: ChangePayersList[]
) {
  let primaryInsuranceObject: PatientInsurance | null = null
  let primaryInsuranceHolderObject: InsuranceHolder | null = null
  let primaryInsuranceItem: any = {}
  if (primaryInsurance || Object.values(InsurancePrimary).includes(id)) {
    const primaryInsuranceCopy = { ...primaryInsurance }
    primaryInsuranceObject = {
      ...claimInsuranceEmptyObject,
      ...primaryInsuranceCopy,
    }
    primaryInsuranceItem = {
      patientInsurance: {
        insuranceName: primaryInsuranceObject.insuranceName || null,
        claimFilingCode: primaryInsuranceObject.claimFilingCode || null,
        insuranceMemberId: primaryInsuranceObject.insuranceMemberId || null,
        insuranceGroupId: primaryInsuranceObject.insuranceGroupId || null,
        insuranceCompany: primaryInsuranceObject.insuranceCompany || null,
        insuranceCompanyAddress1:
          primaryInsuranceObject.insuranceCompanyAddress1 || null,
        insuranceCompanyAddress2:
          primaryInsuranceObject.insuranceCompanyAddress2 || null,
        insuranceCompanyCity:
          primaryInsuranceObject.insuranceCompanyCity || null,
        insuranceCompanyState:
          primaryInsuranceObject.insuranceCompanyState || null,
        insuranceCompanyZipCode:
          primaryInsuranceObject.insuranceCompanyZipCode || null,
        insuranceCardBack: primaryInsuranceObject.insuranceCardBack || null,
        insuranceCardFront: primaryInsuranceObject.insuranceCardFront || null,
        isPrimaryInsuranceHolder: true,
        hasInsuranceCardSaved:
          primaryInsuranceObject.hasInsuranceCardSaved ||
          (isBoolean(primaryInsuranceObject?.hasInsuranceCardSaved)
            ? false
            : null),
        isPaymentAuthorized:
          primaryInsuranceObject.isPaymentAuthorized ||
          (isBoolean(primaryInsuranceObject?.isPaymentAuthorized)
            ? false
            : null),
        isInformationReleaseAuthorized:
          primaryInsuranceObject.isInformationReleaseAuthorized ||
          (isBoolean(primaryInsuranceObject?.isInformationReleaseAuthorized)
            ? false
            : null),
      },
    }
    if (primaryHolder || Object.values(InsurancePrimaryHolder).includes(id)) {
      primaryInsuranceHolderObject = {
        ...insuranceHolderEmptyObject,
        ...primaryHolder,
      }
      primaryInsuranceItem.patientInsurance.isPrimaryInsuranceHolder = false
      primaryInsuranceItem.insuranceHolder = {
        address1: primaryInsuranceHolderObject.address1 || null,
        address2: primaryInsuranceHolderObject.address2 || null,
        zipCode: primaryInsuranceHolderObject.zipCode || null,
        city: primaryInsuranceHolderObject.city || null,
        state: primaryInsuranceHolderObject.state || null,
        country: primaryInsuranceHolderObject.country || null,
        dateOfBirth: primaryInsuranceHolderObject.dateOfBirth || null,
        email: primaryInsuranceHolderObject.email || null,
        firstName: primaryInsuranceHolderObject.firstName || null,
        lastName: primaryInsuranceHolderObject.lastName || null,
        phoneNumber: primaryInsuranceHolderObject.phoneNumber || null,
        relationship: primaryInsuranceHolderObject.relationship || null,
        legalSex: primaryInsuranceHolderObject.legalSex || null,
      }
    }
  }
  let shouldSave = true
  switch (id) {
    case InsurancePrimaryHolder.sameAddressAsPatient:
      if (newVal === 'Yes') {
        setSameAddressAsPatient(true)
        primaryInsuranceItem.insuranceHolder.address1 = null
        primaryInsuranceItem.insuranceHolder.address2 = null
        primaryInsuranceItem.insuranceHolder.city = null
        primaryInsuranceItem.insuranceHolder.zipCode = null
        primaryInsuranceItem.insuranceHolder.state = null
        primaryInsuranceItem.insuranceHolder.country = null
      } else {
        setSameAddressAsPatient(false)
      }
      break
    case InsurancePrimary.insuranceName: {
      primaryInsuranceItem.patientInsurance[id] = newVal || null
      const insuranceInfo = changeList.filter(
        (changeItem) => newVal === changeItem.organizationName
      )
      if (insuranceInfo.length > 0) {
        const claimCode = claimFilingChangeOptions.filter(
          (claim) => claim.label === insuranceInfo[0].claimInsuranceType
        )
        if (claimCode.length > 0) {
          primaryInsuranceItem.patientInsurance[
            InsurancePrimary.claimFilingCode
          ] = claimCode[0].value
        } else {
          primaryInsuranceItem.patientInsurance[
            InsurancePrimary.claimFilingCode
          ] = null
        }
      }
      break
    }
    case InsurancePrimary.insuranceMemberId:
      primaryInsuranceItem.patientInsurance[
        InsurancePrimary.insuranceMemberId
      ] = newVal || null
      break
    case InsurancePrimary.insuranceGroupId:
      primaryInsuranceItem.patientInsurance[InsurancePrimary.insuranceGroupId] =
        newVal || null
      break
    case InsurancePrimary.informationRelease:
      if (newVal === '') {
        primaryInsuranceItem.patientInsurance.isInformationReleaseAuthorized =
          null
      } else {
        primaryInsuranceItem.patientInsurance.isInformationReleaseAuthorized =
          newVal === 'Yes'
      }
      break
    case InsurancePrimary.paymentAutorization:
      if (newVal === '') {
        primaryInsuranceItem.patientInsurance.isPaymentAuthorized = null
      } else {
        primaryInsuranceItem.patientInsurance.isPaymentAuthorized =
          newVal === 'Yes'
      }
      break
    case InsurancePrimaryHolder.address:
      primaryInsuranceItem.insuranceHolder.address1 =
        newVal.Address1?.trim() || null
      primaryInsuranceItem.insuranceHolder.address2 =
        newVal.Address2?.trim() || null
      primaryInsuranceItem.insuranceHolder.city = newVal.City?.trim() || null
      primaryInsuranceItem.insuranceHolder.zipCode =
        newVal.Zipcode?.trim() || null
      primaryInsuranceItem.insuranceHolder.state = newVal.State?.trim() || null
      primaryInsuranceItem.insuranceHolder.country = 'US'
      break
    case InsurancePrimaryHolder.unit:
      primaryInsuranceItem.insuranceHolder.address2 = newVal || null
      break
    case InsurancePrimaryHolder.primaryRelationship:
      if (!newVal) {
        primaryInsuranceItem.patientInsurance.isPrimaryInsuranceHolder = false
        primaryInsuranceItem.insuranceHolder.relationship = null
      } else if (newVal === 'Self') {
        delete primaryInsuranceItem.insuranceHolder
        primaryInsuranceItem.patientInsurance.isPrimaryInsuranceHolder = true
      } else {
        primaryInsuranceItem.patientInsurance.isPrimaryInsuranceHolder = false
        primaryInsuranceItem.insuranceHolder.relationship = newVal || null
      }
      break
    case InsurancePrimaryHolder.primaryFirstName:
      shouldSave = testRegEx(
        newVal,
        nameRegEx,
        primaryInsuranceItem.insuranceHolder,
        'firstName',
        'Please input a first name with no symbols nor special characters.'
      )
      break
    case InsurancePrimaryHolder.primaryLastName:
      shouldSave = testRegEx(
        newVal,
        nameRegEx,
        primaryInsuranceItem.insuranceHolder,
        'lastName',
        'Please input a last name with no symbols nor special characters.'
      )
      break
    case InsurancePrimaryHolder.dateOfBirth:
      primaryInsuranceItem.insuranceHolder.dateOfBirth = newVal
        ? format(new Date(newVal), 'MM/dd/yyyy')
        : null
      break
    case InsurancePrimaryHolder.legalSex:
      primaryInsuranceItem.insuranceHolder.legalSex = newVal || null
      break
    case InsurancePrimaryHolder.email:
      shouldSave = testRegEx(
        newVal,
        emailRegEx,
        primaryInsuranceItem.insuranceHolder,
        'email',
        'Please input a correct email.'
      )
      break
    case InsurancePrimaryHolder.phoneNumber:
      shouldSave = testRegEx(
        extractNumbersFromString(newVal),
        tenDigitPhoneNumberRegEx,
        primaryInsuranceItem.insuranceHolder,
        'phoneNumber',
        'Please input a ten digit phone number without special characters.'
      )
      break
    case InsurancePrimary.claimFilingCode:
      primaryInsuranceItem.patientInsurance[InsurancePrimary.claimFilingCode] =
        newVal || null
  }
  return {
    primaryInsuranceItem,
    shouldSave,
  }
}

export function handleSecondaryInsuranceUpdate(
  newVal: any,
  id: string,
  secondaryInsurance: PatientInsurance | null | undefined,
  secondaryHolder: InsuranceHolder | null | undefined,
  changeList: ChangePayersList[]
) {
  let secondaryInsuranceObject: PatientInsurance | null = null
  let secondaryInsuranceHolderObject: InsuranceHolder | null = null

  let secondaryInsuranceItem: any = {}

  if (secondaryInsurance || Object.values(InsuranceSecondary).includes(id)) {
    const secondaryInsuranceCopy = { ...secondaryInsurance }
    secondaryInsuranceObject = {
      ...claimInsuranceEmptyObject,
      ...secondaryInsuranceCopy,
    }
    secondaryInsuranceItem = {
      patientInsurance: {
        insuranceName: secondaryInsuranceObject.insuranceName || null,
        claimFilingCode: secondaryInsuranceObject.claimFilingCode || null,
        insuranceTypeCode: secondaryInsuranceObject.insuranceTypeCode ?? null,
        insuranceMemberId: secondaryInsuranceObject.insuranceMemberId || null,
        insuranceGroupId: secondaryInsuranceObject.insuranceGroupId || null,
        insuranceCompany: secondaryInsuranceObject.insuranceCompany || null,
        insuranceCompanyAddress1:
          secondaryInsuranceObject.insuranceCompanyAddress1 || null,
        insuranceCompanyAddress2:
          secondaryInsuranceObject.insuranceCompanyAddress2 || null,
        insuranceCompanyCity:
          secondaryInsuranceObject.insuranceCompanyCity || null,
        insuranceCompanyState:
          secondaryInsuranceObject.insuranceCompanyState || null,
        insuranceCompanyZipCode:
          secondaryInsuranceObject.insuranceCompanyZipCode || null,
        insuranceCardBack: secondaryInsuranceObject.insuranceCardBack || null,
        insuranceCardFront: secondaryInsuranceObject.insuranceCardFront || null,
        isPrimaryInsuranceHolder: true,
        hasInsuranceCardSaved:
          secondaryInsuranceObject.hasInsuranceCardSaved ||
          (isBoolean(secondaryInsuranceObject?.hasInsuranceCardSaved)
            ? false
            : null),
        isPaymentAuthorized:
          secondaryInsuranceObject.isPaymentAuthorized ||
          (isBoolean(secondaryInsuranceObject?.isPaymentAuthorized)
            ? false
            : null),
        isInformationReleaseAuthorized:
          secondaryInsuranceObject.isInformationReleaseAuthorized ||
          (isBoolean(secondaryInsuranceObject?.isInformationReleaseAuthorized)
            ? false
            : null),
      },
    }
    if (
      secondaryHolder ||
      Object.values(InsuranceSecondaryHolder).includes(id)
    ) {
      secondaryInsuranceHolderObject = {
        ...insuranceHolderEmptyObject,
        ...secondaryHolder,
      }
      secondaryInsuranceItem.patientInsurance.isPrimaryInsuranceHolder = false
      secondaryInsuranceItem.insuranceHolder = {
        address1: secondaryInsuranceHolderObject.address1 || null,
        address2: secondaryInsuranceHolderObject.address2 || null,
        zipCode: secondaryInsuranceHolderObject.zipCode || null,
        city: secondaryInsuranceHolderObject.city || null,
        state: secondaryInsuranceHolderObject.state || null,
        country: secondaryInsuranceHolderObject.country || null,
        dateOfBirth: secondaryInsuranceHolderObject.dateOfBirth || null,
        email: secondaryInsuranceHolderObject.email || null,
        firstName: secondaryInsuranceHolderObject.firstName || null,
        lastName: secondaryInsuranceHolderObject.lastName || null,
        phoneNumber: secondaryInsuranceHolderObject.phoneNumber || null,
        relationship: secondaryInsuranceHolderObject.relationship || null,
        legalSex: secondaryInsuranceHolderObject.legalSex || null,
      }
    }
  }
  let shouldSave = true
  switch (id) {
    case InsuranceSecondary.secondaryInsuranceName: {
      secondaryInsuranceItem.patientInsurance.insuranceName = newVal || null
      const insuranceInfo = changeList.filter(
        (changeItem) => newVal === changeItem.organizationName
      )
      if (insuranceInfo.length > 0) {
        const claimCode = claimFilingChangeOptions.filter(
          (claim) => claim.label === insuranceInfo[0].claimInsuranceType
        )
        if (claimCode.length > 0) {
          const newClaimCode = claimCode[0].value
          secondaryInsuranceItem.patientInsurance.claimFilingCode = newClaimCode
          if (MEDICARE_CLAIM_FILING_CODES.includes(newClaimCode)) {
            secondaryInsuranceItem.patientInsurance.insuranceTypeCode =
              DEFAULT_MEDICARE_INSURANCE_TYPE_CODE
          } else {
            secondaryInsuranceItem.patientInsurance.insuranceTypeCode = null
          }
        } else {
          secondaryInsuranceItem.patientInsurance.claimFilingCode = null
        }
      }
      break
    }
    case InsuranceSecondary.secondaryInsuranceMemberId:
      secondaryInsuranceItem.patientInsurance.insuranceMemberId = newVal || null
      break
    case InsuranceSecondary.secondaryInsuranceGroupId:
      secondaryInsuranceItem.patientInsurance.insuranceGroupId = newVal || null
      break
    case InsuranceSecondary.secondaryInformationRelease:
      if (newVal === '') {
        secondaryInsuranceItem.patientInsurance.isInformationReleaseAuthorized =
          null
      } else {
        secondaryInsuranceItem.patientInsurance.isInformationReleaseAuthorized =
          newVal === 'Yes'
      }
      break
    case InsuranceSecondary.secondaryPaymentAuthorization:
      if (newVal === '') {
        secondaryInsuranceItem.patientInsurance.isPaymentAuthorized = null
      } else {
        secondaryInsuranceItem.patientInsurance.isPaymentAuthorized =
          newVal === 'Yes'
      }
      break
    case InsuranceSecondaryHolder.secondaryRelationship:
      if (!newVal) {
        secondaryInsuranceItem.patientInsurance.isPrimaryInsuranceHolder = false
        secondaryInsuranceItem.insuranceHolder.relationship = null
      } else if (newVal === 'Self') {
        delete secondaryInsuranceItem.insuranceHolder
        secondaryInsuranceItem.patientInsurance.isPrimaryInsuranceHolder = true
      } else {
        secondaryInsuranceItem.patientInsurance.isPrimaryInsuranceHolder = false
        secondaryInsuranceItem.insuranceHolder.relationship = newVal || null
      }
      break
    case InsuranceSecondaryHolder.secondaryFirstName:
      shouldSave = testRegEx(
        newVal,
        nameRegEx,
        secondaryInsuranceItem.insuranceHolder,
        'firstName',
        'Please input a first name with no symbols nor special characters.'
      )
      break
    case InsuranceSecondaryHolder.secondaryLastName:
      shouldSave = testRegEx(
        newVal,
        nameRegEx,
        secondaryInsuranceItem.insuranceHolder,
        'lastName',
        'Please input a last name with no symbols nor special characters.'
      )
      break
    case InsuranceSecondary.secondaryClaimFillingCode:
      secondaryInsuranceItem.patientInsurance.claimFilingCode = newVal || null
      if (MEDICARE_CLAIM_FILING_CODES.includes(newVal)) {
        secondaryInsuranceItem.patientInsurance.insuranceTypeCode =
          DEFAULT_MEDICARE_INSURANCE_TYPE_CODE
      } else {
        secondaryInsuranceItem.patientInsurance.insuranceTypeCode = null
      }
      break
    case InsuranceSecondary.secondaryInsuranceTypeCode:
      secondaryInsuranceItem.patientInsurance.insuranceTypeCode = newVal || null
      break
    case InsuranceSecondaryHolder.secondaryDateOfBirth:
      secondaryInsuranceItem.insuranceHolder.dateOfBirth = newVal
        ? format(new Date(newVal), 'MM/dd/yyyy')
        : null
  }
  return {
    secondaryInsuranceItem,
    shouldSave,
  }
}

export function handleRefferingProvUpdate(
  newVal: any,
  id: string,
  referringProviderInfo: ReferringProviderType
) {
  let shouldSave = true
  switch (id) {
    case referringProviderArgs.firstName:
      shouldSave = testRegEx(
        newVal,
        nameRegEx,
        referringProviderInfo,
        'firstName',
        'Please input a first name with no symbols nor special characters.'
      )
      break
    case referringProviderArgs.lastName:
      shouldSave = testRegEx(
        newVal,
        nameRegEx,
        referringProviderInfo,
        'lastName',
        'Please input a first name with no symbols nor special characters.'
      )
      break
    case referringProviderArgs.providerEin:
      shouldSave = testRegEx(
        newVal.replaceAll('-', ''),
        nineDigitNumberRegEx,
        referringProviderInfo,
        'providerEin',
        'Please input a nine digit number with no special characters.'
      )
      break
    case referringProviderArgs.providerNpi:
      shouldSave = testRegEx(
        newVal,
        tenDigitNumberRegEx,
        referringProviderInfo,
        'providerNpi',
        'Please input a ten digit number with no special characters.'
      )
  }
  return shouldSave
}

export function handleSupervisingProvUpdate(
  newVal: any,
  id: string,
  copySupervisingProviderInfo: SupervisingProviderType,
  selectedTeammate: Teammate[]
) {
  let shouldSave = true
  switch (id) {
    case renderingProviderArgs.providerId:
      if (newVal) {
        copySupervisingProviderInfo.providerId = newVal.trim() || null
        copySupervisingProviderInfo.providerNpi =
          selectedTeammate[0]?.billingNPI || null
      } else {
        copySupervisingProviderInfo.providerId = null
        copySupervisingProviderInfo.providerNpi = null
      }
      break
    case renderingProviderArgs.providerEin:
      shouldSave = testRegEx(
        newVal.replaceAll('-', ''),
        nineDigitNumberRegEx,
        copySupervisingProviderInfo,
        'providerEin',
        'Please input a nine digit number with no special characters.'
      )
      break
    case renderingProviderArgs.providerNpi:
      shouldSave = testRegEx(
        newVal,
        tenDigitNumberRegEx,
        copySupervisingProviderInfo,
        'providerNpi',
        'Please input a ten digit number with no special characters.'
      )
  }
  return shouldSave
}

export function handleRenderingProvUpdate(
  newVal: any,
  id: string,
  copyRenderingProviderInfo: RenderingProviderType,
  selectedTeammate: Teammate[]
) {
  let shouldSave = true
  switch (id) {
    case renderingProviderArgs.providerId:
      if (newVal) {
        copyRenderingProviderInfo.providerId = newVal.trim() || null
        copyRenderingProviderInfo.providerNpi =
          selectedTeammate[0]?.billingNPI || null
        copyRenderingProviderInfo.taxType =
          selectedTeammate[0]?.useEINorSSN || null
        copyRenderingProviderInfo.providerEin = selectedTeammate[0]?.ein
          ? selectedTeammate[0]?.ein.replaceAll('-', '')
          : null
        copyRenderingProviderInfo.providerSsn = selectedTeammate[0]?.ssnLastFour
          ? '**-***-' + selectedTeammate[0]?.ssnLastFour
          : null
        copyRenderingProviderInfo.providerSSNfirstFive =
          selectedTeammate[0]?.ssnFirstFive || null
        copyRenderingProviderInfo.providerSSNlastFour =
          selectedTeammate[0]?.ssnLastFour || null
      } else {
        copyRenderingProviderInfo.providerId = null
        copyRenderingProviderInfo.providerNpi = null
        copyRenderingProviderInfo.taxType = null
        copyRenderingProviderInfo.providerEin = null
        copyRenderingProviderInfo.providerSsn = null
        copyRenderingProviderInfo.providerSSNfirstFive = null
        copyRenderingProviderInfo.providerSSNlastFour = null
      }
      break
    case renderingProviderArgs.taxType:
      copyRenderingProviderInfo.taxType = newVal.trim() || null
      break
    case renderingProviderArgs.taxonomyCode:
      copyRenderingProviderInfo.providerTaxonomyCode = newVal.trim() || null
      break
    case renderingProviderArgs.providerEin:
      shouldSave = testRegEx(
        newVal.replaceAll('-', ''),
        nineDigitNumberRegEx,
        copyRenderingProviderInfo,
        'providerEin',
        'Please input a nine digit number with no special characters.'
      )
      break
    case renderingProviderArgs.providerSsn:
      shouldSave = testRegEx(
        newVal.replaceAll('-', ''),
        nineDigitNumberRegEx,
        copyRenderingProviderInfo,
        'providerSsn',
        'Please input a nine digit number with no special characters.'
      )
      break
    case renderingProviderArgs.providerNpi:
      shouldSave = testRegEx(
        newVal,
        tenDigitNumberRegEx,
        copyRenderingProviderInfo,
        'providerNpi',
        'Please input a ten digit number with no special characters.'
      )
  }
  return shouldSave
}

export function handleBillingInfoUpdate(
  newVal: any,
  id: string,
  copyBilingInfo: BillingInfo,
  teammates: Teammate[],
  practiceData: PracticeDataType,
  locations: Location[],
  renderingProviderId: string | null
) {
  let shouldSave = true
  switch (id) {
    case billingInfoArgs.type:
      if (newVal === 'Practice') {
        copyBilingInfo.type = newVal || null
        copyBilingInfo.name = practiceData.PracticeName || null
        copyBilingInfo.phone = extractNumbersFromString(
          practiceData.ProviderPhone
        )
        copyBilingInfo.EIN =
          practiceData.ProviderPracticeEIN?.replaceAll('-', '') || null
        copyBilingInfo.SSN = null
        copyBilingInfo.NPI = practiceData.ProviderPracticeBillingNPI || null
        copyBilingInfo.taxType = 'EIN'
        copyBilingInfo.SSNfirstFive = null
        copyBilingInfo.SSNlastFour = null
      } else if (newVal === 'Provider') {
        copyBilingInfo.type = newVal || null
        const [selectedProvider] = teammates.filter(
          (t) => t.cognitoId === renderingProviderId
        )
        if (selectedProvider) {
          copyBilingInfo.name = selectedProvider.cognitoId || null
          copyBilingInfo.phone = extractNumbersFromString(
            selectedProvider.phone
          )
          copyBilingInfo.EIN = selectedProvider.ein?.replaceAll('-', '') || null
          copyBilingInfo.SSN = selectedProvider.ssn?.replaceAll('', '') || null
          copyBilingInfo.NPI = selectedProvider.billingNPI || null
          copyBilingInfo.taxType = selectedProvider.useEINorSSN || null
          copyBilingInfo.SSNfirstFive = selectedProvider.ssnFirstFive || null
          copyBilingInfo.SSNlastFour = selectedProvider.ssnLastFour || null
        } else {
          copyBilingInfo.name = null
          copyBilingInfo.phone = null
          copyBilingInfo.EIN = null
          copyBilingInfo.SSN = null
          copyBilingInfo.NPI = null
          copyBilingInfo.taxType = null
          copyBilingInfo.SSNfirstFive = null
          copyBilingInfo.SSNlastFour = null
        }
      } else {
        copyBilingInfo.type = null
      }
      break
    case billingInfoArgs.name:
      if (copyBilingInfo.type === 'Practice') {
        copyBilingInfo.name = newVal || null
      } else {
        const [selectedProvider] = teammates.filter(
          (t) => t.cognitoId === newVal
        )
        if (selectedProvider) {
          copyBilingInfo.name = selectedProvider.cognitoId || null
          copyBilingInfo.phone = extractNumbersFromString(
            selectedProvider.phone
          )
          copyBilingInfo.EIN = selectedProvider.ein?.replaceAll('-', '') || null
          copyBilingInfo.SSN = selectedProvider.ssn?.replaceAll('', '') || null
          copyBilingInfo.taxType = selectedProvider.useEINorSSN || null
          copyBilingInfo.NPI = selectedProvider.billingNPI || null
          copyBilingInfo.taxType = selectedProvider.useEINorSSN || null
          copyBilingInfo.SSNfirstFive = selectedProvider.ssnFirstFive || null
          copyBilingInfo.SSNlastFour = selectedProvider.ssnLastFour || null
        } else {
          copyBilingInfo.name = null
          copyBilingInfo.phone = null
          copyBilingInfo.EIN = null
          copyBilingInfo.SSN = null
          copyBilingInfo.taxType = null
          copyBilingInfo.NPI = null
          copyBilingInfo.taxType = null
          copyBilingInfo.SSNfirstFive = null
          copyBilingInfo.SSNlastFour = null
        }
      }
      break
    case billingInfoArgs.locationId: {
      const locationIndex = findLocationIndexWithLocationId(newVal, locations)
      copyBilingInfo.locationId = newVal || null
      if (locationIndex > -1 && locations[locationIndex]?.AddressLine1) {
        copyBilingInfo.address1 = locations[locationIndex]?.AddressLine1 || null
        copyBilingInfo.city = locations[locationIndex]?.City || null
        copyBilingInfo.zipCode = locations[locationIndex]?.Zipcode || null
        copyBilingInfo.state = locations[locationIndex]?.State || null
        copyBilingInfo.locationName = locations[locationIndex]?.Name || null
        copyBilingInfo.country = 'US'
      } else {
        copyBilingInfo.address1 = null
        copyBilingInfo.city = null
        copyBilingInfo.zipCode = null
        copyBilingInfo.state = null
        copyBilingInfo.country = null
      }
      break
    }
    case billingInfoArgs.address1:
      copyBilingInfo.address1 = newVal.Address1?.trim() || null
      copyBilingInfo.address2 = newVal.Address2?.trim() || null
      copyBilingInfo.city = newVal.City?.trim() || null
      copyBilingInfo.zipCode = newVal.Zipcode?.trim() || null
      copyBilingInfo.state = newVal.State?.trim() || null
      copyBilingInfo.country = 'US'
      break
    case billingInfoArgs.taxType:
      if (!(copyBilingInfo.type === 'Practice' && newVal === 'SSN')) {
        copyBilingInfo.taxType = newVal || null
      }
      break
    case billingInfoArgs.address2:
      copyBilingInfo.address2 = newVal.trim() || null
      break
    case billingInfoArgs.EIN:
      shouldSave = testRegEx(
        newVal.replaceAll('-', ''),
        nineDigitNumberRegEx,
        copyBilingInfo,
        'EIN',
        'Please input a nine digit number with no special characters.'
      )
      break
    case billingInfoArgs.NPI:
      shouldSave = testRegEx(
        newVal,
        tenDigitNumberRegEx,
        copyBilingInfo,
        'NPI',
        'Please input a ten digit number with no special characters.'
      )
      break
    case billingInfoArgs.SSN:
      shouldSave = testRegEx(
        newVal.replaceAll('', ''),
        nineDigitNumberRegEx,
        copyBilingInfo,
        'SSN',
        'Please input a nine digit number with no special characters.'
      )
      break
    case billingInfoArgs.phone:
      shouldSave = testRegEx(
        extractNumbersFromString(newVal),
        tenDigitPhoneNumberRegEx,
        copyBilingInfo,
        'phone',
        'Please input a ten digit phone number without special characters.'
      )
      break
    case billingInfoArgs.taxonomyCode:
      copyBilingInfo.taxonomyCode = newVal || null
      break
  }
  return shouldSave
}

export function handlePatientConditionRelationUpdate(
  newVal: any,
  id: string,
  copypatientConditionRelation: PatientConditionRelation
) {
  switch (id) {
    case patientCondition.employmentAccident:
      if (newVal === '') {
        copypatientConditionRelation.employmentAccident = null
      } else {
        copypatientConditionRelation.employmentAccident = newVal === 'Yes'
      }
      break
    case patientCondition.autoAccident:
      if (newVal === '') {
        copypatientConditionRelation.autoAccident = null
      } else {
        copypatientConditionRelation.autoAccident = newVal === 'Yes'
      }
      if (newVal !== 'Yes') {
        copypatientConditionRelation.accidentState = null
      }
      break
    case patientCondition.otherAccident:
      if (newVal === '') {
        copypatientConditionRelation.otherAccident = null
      } else {
        copypatientConditionRelation.otherAccident = newVal === 'Yes'
      }
      break
    case patientCondition.accidentState:
      copypatientConditionRelation.accidentState = newVal || null
  }
}

enum ClaimSections {
  'Patient_information' = 'Patient information',
  'Primary_insurance' = 'Primary insurance',
  'Primary_insurance_subscriber_info' = 'Primary insurance subscriber info',
  'Company_to_submit_this_claim_to' = 'Company to submit this claim to',
  'Secondary_insurance' = 'Secondary insurance',
  'Referring_provider' = 'Referring provider',
  'Appointment_information' = 'Appointment information',
  'Rendering_provider' = 'Rendering provider',
  'Supervising_provider' = 'Supervising provider',
  'Billing' = 'Billing',
  'Diagnoses' = 'Diagnoses',
  'National_drug_codes' = 'National drug codes',
  'Signature' = 'Signature',
  'Procedures' = 'Procedures',
}

const PatientRequiredValues = [
  'firstName',
  'lastName',
  'dateOfBirth',
  'legalSex',
]
const PrimaryInsuranceRequiredValues = [
  'insuranceName',
  'claimFilingCode',
  'insuranceMemberId',
  'isInformationReleaseAuthorized',
  'isPaymentAuthorized',
]
const PrimaryInsuranceHolderRequiredValues = [
  'relationship',
  'firstName',
  'lastName',
  'dateOfBirth',
]
const SecondaryInsuranceRequiredValues = [
  'insuranceName',
  'claimFilingCode',
  'insuranceMemberId',
  'isInformationReleaseAuthorized',
  'isPaymentAuthorized',
]
const ScondaryInsuranceHolderRequiredValues = [
  'relationship',
  'firstName',
  'lastName',
  'dateOfBirth',
]
const ReferringProviderRequiredValues = ['providerEin', 'providerNpi']
const AppointmentInfoRequiredValues = [
  'chartNote',
  'startDate',
  'practice',
  'locationId',
  'address1',
  'posCode',
]
const RenderingInfoRequiredValues = ['providerId', 'providerNpi']
const SupervisingInfoRequiredValues = [
  'providerId',
  'providerEin',
  'providerNpi',
]
const BillingInfoRequiredValues = [
  'type',
  'locationId',
  'address1',
  'NPI',
  'taxType',
  'phone',
]

const getIsAnyRequiredValueMissingInNdcItem = (ndcItem: NdcItem) => {
  const NdcRequiredValues: (keyof NdcItem)[] = [
    'billingCodeId',
    'idQualifier',
    'ndc',
    'numberOfUnits',
    'unitType',
  ]
  return NdcRequiredValues.some(
    (fieldName) => ndcItem[fieldName] === '' || ndcItem[fieldName] === null
  )
}

export function validateClaimSubmit(claimData: any) {
  let shouldSubmit = true
  const sectionsWithMissingValues: string[] = []
  for (const r of PatientRequiredValues) {
    if (claimData.patientInfo[r] === null) {
      shouldSubmit = false
      if (
        !sectionsWithMissingValues.includes(ClaimSections.Patient_information)
      ) {
        sectionsWithMissingValues.push(ClaimSections.Patient_information)
      }
    }
  }
  for (const r of PrimaryInsuranceRequiredValues) {
    if (
      claimData.primaryInsurance[r] === null ||
      claimData.primaryInsurance[r] === undefined
    ) {
      shouldSubmit = false
      if (
        !sectionsWithMissingValues.includes(ClaimSections.Primary_insurance)
      ) {
        sectionsWithMissingValues.push(ClaimSections.Primary_insurance)
      }
    }
  }
  if (claimData.primaryHolder?.relationship !== 'Self') {
    for (const r of PrimaryInsuranceHolderRequiredValues) {
      if (claimData.primaryHolder && claimData.primaryHolder[r] === null) {
        shouldSubmit = false
        if (
          !sectionsWithMissingValues.includes(
            ClaimSections.Primary_insurance_subscriber_info
          )
        ) {
          sectionsWithMissingValues.push(
            ClaimSections.Primary_insurance_subscriber_info
          )
        }
      }
    }
  }

  if (!claimData.companyToSubmitTo) {
    shouldSubmit = false
    sectionsWithMissingValues.push(
      ClaimSections.Company_to_submit_this_claim_to
    )
  }

  if (claimData.includeSecondary) {
    for (const r of SecondaryInsuranceRequiredValues) {
      if (
        claimData.secondaryInsurance[r] === null ||
        claimData.secondaryInsurance[r] === undefined
      ) {
        shouldSubmit = false
        if (
          !sectionsWithMissingValues.includes(ClaimSections.Secondary_insurance)
        ) {
          sectionsWithMissingValues.push(ClaimSections.Secondary_insurance)
        }
      }
    }
    if (
      MEDICARE_CLAIM_FILING_CODES.includes(
        claimData.secondaryInsurance.claimFilingCode
      )
    ) {
      if (
        claimData.secondaryInsurance.insuranceTypeCode === null ||
        claimData.secondaryInsurance.insuranceTypeCode === undefined
      ) {
        shouldSubmit = false
        if (
          !sectionsWithMissingValues.includes(ClaimSections.Secondary_insurance)
        ) {
          sectionsWithMissingValues.push(ClaimSections.Secondary_insurance)
        }
      }
    }
    if (claimData.secondaryHolder?.relationship !== 'Self') {
      for (const r of ScondaryInsuranceHolderRequiredValues) {
        if (
          claimData.secondaryHolder &&
          claimData.secondaryHolder[r] === null
        ) {
          shouldSubmit = false
          if (
            !sectionsWithMissingValues.includes(
              ClaimSections.Secondary_insurance
            )
          ) {
            sectionsWithMissingValues.push(ClaimSections.Secondary_insurance)
          }
        }
      }
    }
  }

  if (claimData.includeReferringProvider) {
    for (const r of ReferringProviderRequiredValues) {
      if (
        claimData.referringProviderInfo &&
        claimData.referringProviderInfo[r] === null
      ) {
        shouldSubmit = false
        if (
          !sectionsWithMissingValues.includes(ClaimSections.Referring_provider)
        ) {
          sectionsWithMissingValues.push(ClaimSections.Referring_provider)
        }
      }
    }
  }
  if (claimData.appointmentInfo) {
    for (const r of AppointmentInfoRequiredValues) {
      if (claimData.appointmentInfo[r] === null) {
        shouldSubmit = false
        if (
          !sectionsWithMissingValues.includes(
            ClaimSections.Appointment_information
          )
        ) {
          sectionsWithMissingValues.push(ClaimSections.Appointment_information)
        }
      }
    }
  }

  if (claimData.renderingProviderInfo) {
    for (const r of RenderingInfoRequiredValues) {
      if (claimData.renderingProviderInfo[r] === null) {
        shouldSubmit = false
        if (
          !sectionsWithMissingValues.includes(ClaimSections.Rendering_provider)
        ) {
          sectionsWithMissingValues.push(ClaimSections.Rendering_provider)
        }
      }
    }
    if (
      (claimData.renderingProviderInfo.taxType === 'EIN' &&
        !claimData.renderingProviderInfo.providerEin) ||
      (claimData.renderingProviderInfo.taxType === 'SSN' &&
        !claimData.renderingProviderInfo.providerSsn)
    ) {
      shouldSubmit = false
      if (
        !sectionsWithMissingValues.includes(ClaimSections.Rendering_provider)
      ) {
        sectionsWithMissingValues.push(ClaimSections.Rendering_provider)
      }
    }
  }

  if (claimData.includeSupervisingProvider) {
    for (const r of SupervisingInfoRequiredValues) {
      if (claimData.supervisingProvider[r] === null) {
        shouldSubmit = false
        if (
          !sectionsWithMissingValues.includes(
            ClaimSections.Supervising_provider
          )
        ) {
          sectionsWithMissingValues.push(ClaimSections.Supervising_provider)
        }
      }
    }
  }
  if (claimData.billingInfo) {
    for (const r of BillingInfoRequiredValues) {
      if (
        claimData.billingInfo[r] === null ||
        claimData.billingInfo[r] === undefined
      ) {
        shouldSubmit = false
        if (!sectionsWithMissingValues.includes(ClaimSections.Billing)) {
          sectionsWithMissingValues.push(ClaimSections.Billing)
        }
      }
      if (
        claimData.billingInfo.type === 'Practice' &&
        !claimData.billingInfo.name
      ) {
        shouldSubmit = false
        if (!sectionsWithMissingValues.includes(ClaimSections.Billing)) {
          sectionsWithMissingValues.push(ClaimSections.Billing)
        }
      }
    }
    if (
      (claimData.billingInfo.taxType === 'EIN' && !claimData.billingInfo.EIN) ||
      (claimData.billingInfo.taxType === 'SSN' && !claimData.billingInfo.SSN)
    ) {
      shouldSubmit = false
      if (!sectionsWithMissingValues.includes(ClaimSections.Billing)) {
        sectionsWithMissingValues.push(ClaimSections.Billing)
      }
    }
  }
  if (!claimData.diagnoses.length) {
    shouldSubmit = false
    if (!sectionsWithMissingValues.includes(ClaimSections.Diagnoses)) {
      sectionsWithMissingValues.push(ClaimSections.Diagnoses)
    }
  }

  if (claimData.ndcs?.length) {
    const isAnyMissingValues = claimData.ndcs.some((el: NdcItem) =>
      getIsAnyRequiredValueMissingInNdcItem(el)
    )
    if (isAnyMissingValues) {
      sectionsWithMissingValues.push(ClaimSections.National_drug_codes)
      shouldSubmit = false
    }
  }

  if (!claimData.signingProvider) {
    shouldSubmit = false
    if (!sectionsWithMissingValues.includes(ClaimSections.Signature)) {
      sectionsWithMissingValues.push(ClaimSections.Signature)
    }
  }
  if (!claimData.signingProvider) {
    shouldSubmit = false
    if (!sectionsWithMissingValues.includes(ClaimSections.Signature)) {
      sectionsWithMissingValues.push(ClaimSections.Signature)
    }
  }
  if (claimData.lineItems.length) {
    for (const l of claimData.lineItems) {
      if (!l.dxPointers.length || !l.units || !l.unitChargeAmountCents) {
        shouldSubmit = false
        if (!sectionsWithMissingValues.includes(ClaimSections.Procedures)) {
          sectionsWithMissingValues.push(ClaimSections.Procedures)
        }
      }
    }
  } else {
    shouldSubmit = false
    if (!sectionsWithMissingValues.includes(ClaimSections.Procedures)) {
      sectionsWithMissingValues.push(ClaimSections.Procedures)
    }
  }
  if (!shouldSubmit && sectionsWithMissingValues.length)
    notification(
      `There is missing information in the following sections, please complete it before submitting again: ${sectionsWithMissingValues.join(
        ', '
      )}`,
      'failure'
    )
  return shouldSubmit
}

export const isClaimNotSubmitted = (claimStatus: CLAIM_STATUS) => {
  const notSubmittedStatuses = [
    CLAIM_STATUS.DRAFT,
    CLAIM_STATUS.CANCELED,
    CLAIM_STATUS.CHANGE_ERROR,
  ]
  return notSubmittedStatuses.includes(claimStatus)
}
