import { API } from 'aws-amplify'

import { ClaimData, ClaimStatus } from '../containers/Patient/ClaimsV2/types'

export const createClaimFromPatientInfo = ({
  patientId,
  noteId,
}: {
  patientId: string
  noteId?: string
}): Promise<ClaimData> =>
  API.post('insurance', '/insurance/v2/claims', {
    body: {
      patientId,
      clinicalNoteId: noteId,
    },
  })

export const getClaimById = (claimId: string): Promise<ClaimData> =>
  API.get('insurance', `/insurance/v2/claims/${claimId}`, {})

export const deleteClaim = (claimId: string): Promise<string> =>
  API.del('insurance', `/insurance/v2/claims/${claimId}`, {})

// TODO: update "any" return type once CARE-2625 is completed
export const updateClaimMemo = ({
  claimId,
  memo,
}: {
  claimId: string
  memo: string | null
}): Promise<any> =>
  API.put('insurance', `/insurance/v2/claims/${claimId}/memo`, {
    body: { memo },
  })

// TODO: update "any" return type & no need to attach claimStatus once CARE-2625 is completed.
export const updateClaim = ({
  claimId,
  body,
}: {
  claimId: string
  body: Omit<ClaimData, 'claimStatusUpdate'>
}): Promise<any> =>
  API.put('insurance', `/insurance/v2/claims/${claimId}`, {
    body: { ...body, claimStatus: ClaimStatus.DRAFT },
  })
