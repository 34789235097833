import { ReactNode } from 'react'

import styles from './Row.module.scss'

type RowProps = {
  label: string
  content?: string | ReactNode
  isEditable?: boolean
  fieldName: string
  onChange?: (data: { [key: string]: any }) => void
}

export const Row = ({ label, content, fieldName }: RowProps) => {
  return (
    <>
      <div data-testid={`${fieldName}-label`} className={styles.label}>
        {label}
      </div>
      <div data-testid={`${fieldName}-content`} className={styles.content}>
        {content}
      </div>
    </>
  )
}
