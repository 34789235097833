import { JSONContent } from '@tiptap/react'
import { API } from 'aws-amplify'

import { SortParam, buildSortStringForAPI } from '../../api/pagination'
import { queryParamsToQueryStringParams } from '../../libs/utils'
import {
  CreateDynamicBlockOptions,
  DynamicNoteBlock,
  UpdateDynamicBlockOptions,
} from './Components/Blocks/Core/Block/DynamicBlockTypes'
import {
  NoteTemplate,
  NoteTemplateWithoutContent,
} from './Components/Templates/types'
import { Note, NoteDetailFromApi, SignatureRequest } from './types'

const CLINICAL_NOTES = 'clinical-notes'

export const createNote = async ({
  patientId,
  fromLast = false,
  saltNoteId,
  templateId,
}: {
  patientId: string
  fromLast?: boolean
  saltNoteId?: string
  templateId?: string
}) => {
  return await API.post(CLINICAL_NOTES, '/notes/v2', {
    body: { patientId, copyFromLast: fromLast, saltNoteId, templateId },
  })
}

export const createNoteBlock = async ({
  noteUuid,
  blockConfig,
}: CreateDynamicBlockOptions) => {
  return await API.post(
    CLINICAL_NOTES,
    `/clinical-notes/v2/${noteUuid}/blocks`,
    {
      body: blockConfig,
    }
  )
}

export const updateNoteBlock = async ({
  noteUuid,
  blockUuid,
  blockConfig,
}: UpdateDynamicBlockOptions) => {
  return await API.put(
    CLINICAL_NOTES,
    `/clinical-notes/v2/${noteUuid}/blocks/${blockUuid}`,
    {
      body: blockConfig,
    }
  )
}

export const fetchNoteBlock = async ({
  noteUuid,
  blockUuid,
}: {
  noteUuid: string
  blockUuid: string
}): Promise<DynamicNoteBlock> => {
  return await API.get(
    CLINICAL_NOTES,
    `/clinical-notes/v2/${noteUuid}/blocks/${blockUuid}`,
    {}
  )
}

export interface FetchNoteQueryParams {
  includeInsuranceClaims?: boolean
}

export const fetchNote = async (
  noteId: string | number,
  queryParams: FetchNoteQueryParams = {}
) => {
  return await API.get(CLINICAL_NOTES, `/notes/v2/${noteId}`, {
    queryStringParameters: queryParamsToQueryStringParams(queryParams),
  })
}

export const updateNote = async (
  noteId: string | number,
  body: Partial<Note>
) => {
  return await API.put(CLINICAL_NOTES, `/notes/v2/${noteId}`, { body })
}

export const getAllPatientNotes = async (patientId: string) => {
  return await API.get(
    CLINICAL_NOTES,
    `/notes/v2/getAll?patientId=${patientId}`,
    {}
  )
}

export interface GetNoteDetailsQueryParams {
  patientId: string
  hasInvoice?: boolean
}

export const getAllPatientNoteDetails = (
  queryParams: GetNoteDetailsQueryParams
): Promise<{
  data: NoteDetailFromApi[]
}> => {
  return API.get(CLINICAL_NOTES, '/clinical-notes/note-details', {
    queryStringParameters: queryParamsToQueryStringParams(queryParams),
  })
}

export const deleteNote = async (noteId: string | number) => {
  return await API.del(CLINICAL_NOTES, `/notes/v2/${noteId}`, {})
}

export const signNote = async (
  noteId: string | undefined | number,
  body: SignatureRequest
) => {
  return await API.post(CLINICAL_NOTES, `/notes/v2/${noteId}/sign`, { body })
}

export const addAddendum = async (
  noteId: string | number,
  content: string
): Promise<Note> => {
  return await API.post(
    CLINICAL_NOTES,
    `/clinical-notes/v2/${noteId}/addendum`,
    {
      body: { content },
    }
  )
}

export type CreateNoteTemplateRequest = {
  name: string
  description?: string
  content?: JSONContent
}

export const createNoteTemplate = async (
  request: CreateNoteTemplateRequest
): Promise<NoteTemplate> => {
  return await API.post(CLINICAL_NOTES, `/clinical-notes/v2/templates`, {
    body: request,
  })
}

export type UpdateNoteTemplateRequest = {
  name?: string
  description?: string
  content?: JSONContent
}

export const updateNoteTemplate = async (
  templateId: string,
  body: UpdateNoteTemplateRequest
): Promise<NoteTemplate> => {
  return await API.patch(
    CLINICAL_NOTES,
    `/clinical-notes/v2/templates/${templateId}`,
    {
      body,
    }
  )
}

export const getNoteTemplate = async (
  templateId: string
): Promise<NoteTemplate> => {
  return await API.get(
    CLINICAL_NOTES,
    `/clinical-notes/v2/templates/${templateId}`,
    {}
  )
}

export type ListNoteTemplateResponse = {
  data: NoteTemplateWithoutContent[]
  totalCount: number
}

/**
 * Supported sort field names for this endpoint: A | B | C | etc
 */
type ListNoteTemplatesSortFieldName = 'name'

export type ListNoteTemplatesSortParam =
  SortParam<ListNoteTemplatesSortFieldName>

export const listNoteTemplates = async ({
  limit,
  offset,
  sorts,
}: {
  limit: number
  offset: number
  sorts?: ListNoteTemplatesSortParam[]
}): Promise<ListNoteTemplateResponse> => {
  const query = new URLSearchParams({
    limit: limit.toString(),
    offset: offset.toString(),
    ...(sorts?.length ? { sort: buildSortStringForAPI(sorts) } : {}),
  })

  return await API.get(
    CLINICAL_NOTES,
    `/clinical-notes/v2/templates?${query}`,
    {}
  )
}

export const deleteNoteTemplate = async (
  templateId: string
): Promise<{ templateId: string }> => {
  return API.del(
    CLINICAL_NOTES,
    `/clinical-notes/v2/templates/${templateId}`,
    {}
  )
}
