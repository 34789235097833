export const patientName = (patient: {
  firstName: string
  lastName: string
  middleName: string
  firstNameToUse?: string
  lastNameToUse?: string
  middleNameToUse?: string
}) => {
  const {
    firstName,
    firstNameToUse,
    middleName,
    middleNameToUse,
    lastName,
    lastNameToUse,
  } = patient
  const first = firstNameToUse || firstName
  const middle = middleNameToUse || middleName
  const initial = middle ? `${middle[0]}.` : ''
  const last = lastNameToUse || lastName
  return [first, initial, last].filter(Boolean).join(' ')
}

export const isDateNonStandard = (date: string | Date | number) => {
  const MM_YYYY = /(\d{1,2})\/\d{2,4}/
  return date.toString().match(MM_YYYY)
}

export const getNameInitials = (name: string) => {
  return name
    .toUpperCase()
    .split(' ')
    .map((n) => n[0])
    .join('')
}
